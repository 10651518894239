/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import "../Admin.css";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import { Modal, Table } from "react-bootstrap";
import { Apiurl, GetLocalStorage } from "../../../Utils/Utils";
import axios from "axios";
import Select from "react-select";
import "../AdminGuidelines/AdminGuildlines.css";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useNavigate } from "react-router-dom";
import {
  addSubCategorySectionTabData,
  deleteApplicationSectionsTabData,
  getFontFamilyData,
  getFormTabDropDownData,
  getFormTabSubCategoryListData,
  getSectionsData,
  getSectionsDataSort,
  getSubCategoriesDropdown,
  getSubCategoryListData,
  handleEnableSectionsDisableData,
  updateSubCategoryTabSectionData,
} from "../../../services/api/admin";
import { BsChevronLeft } from "react-icons/bs";
import { HiPlus } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { Message } from "../../../components/message";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import {
  checkPatternWithSpecialCharacter,
  checkPatternFontSize,
  customStylesAcc,
  selectTheme,
  formatDateWithSlash,
} from "../../../Utils/common";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const ApplicationSections = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState()
    const token = GetLocalStorage("userInfo")
    const [nameEn, setNameEn] = useState("")
    const [nameHi, setNameHi] = useState("")
    const [searchData, setSearchData] = useState('')
    const [sortBy, setSortBy] = useState('created_at')

    const [subCategoryList, setSubCategoryList] = useState([]);
    const [editSubCategoryList, setEditSubCategoryList] = useState([])
    const [editSubCategoryId, setEditSubCategoryId] = useState();
    const [editSubCategoryIdError, setEditSubCategoryIdError] = useState();
    const [searchSubCategory, setSearchSubCategory] = useState();
    const [fullCategoryTabListData, setfullCategoryTabListData] = useState();
    const [fullCategoryTabListDropdownData, setfullCategoryTabListDropdownData] = useState();
    const [editCategoryTabId, setEditCategoryTabId] = useState();
    const [editCategoryTabIdError, setEditCategoryTabIdError] = useState();
    const [searchCategoryTab, setSearchCategoryTab] = useState();
    const [searchCategoryTabList, setSearchCategoryTabList] = useState();
    const [editCategoryTabList, setEditCategoryTabList] = useState();
    const requiredList = [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
    ];
    const [inputRequired, setInputRequired] = useState(
        requiredList.find(option => option.value === "0") || null
    );
    const [inputRequiredError, setInputRequiredError] = useState();
    const [dropdownValuesError, setDropDownValuesError] = useState();
    const [nameEnError, setNameEnError] = useState()
    const [nameHiError, setNameHiError] = useState()
    const [editData, setEditData] = useState()
    const [sectionList, setSectionList] = useState([])
    const [tableDataLoaded, setTableDataLoaded] = useState(false)
    const [fontFamily, setFontFamily] = useState({ label: "Arial", value: 1 });
    const [fontFamilyError, setFontFamilyError] = useState();

    const [backgroundColor, setBackgroundColor] = useState("#ffffff");
    const [fontColor, setFontColor] = useState("#000000");
    const [fontColorError, setFontColorError] = useState();

    const [fontSize, setFontSize] = useState('');
    const [fontSizeError, setFontSizeError] = useState();


    const [backgroundColorError, setBackgroundColorError] = useState();
    const [fontsList, setFontsList] = useState()
    const [deleteData, setDeleteData] = useState()
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [editContainer, setEditContainer] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [orderBy, setOrderBy] = useState("asc");
    const [subSection, setSubSection] = useState();
    const [subSectionErr, setSubSectionErr] = useState();
    const [selectedSubSection, setSelectedSubSection] = useState();
    const [selectedSubSectionErr, setSelectedSubSectionErr] = useState();

  const grantPermissionForAddFormSection = checkPermission(
    permissionsConfig.addFormSection.role,
    permissionsConfig.addFormSection.action,
    permissionsConfig.addFormSection.type
  );
  const grantPermissionForEditFormSection = checkPermission(
    permissionsConfig.editFormSection.role,
    permissionsConfig.editFormSection.action,
    permissionsConfig.editFormSection.type
  );

  // const subSectionList = [
  //     { label: "Manager", value: 1 },
  //     { label: "Managerial", value: 4 },
  //     { label: "CEO", value: 7 },
  // ]
  const isSubSectionList = [
    { label: "Yes", value: 1 },
    { label: "NO", value: 0 },
  ];
  
    const handleInputChange = async (e) => {
        const { name, value } = e.target;

        const cursorPosition = e.target.selectionStart;
        // Check for "nameEn" field  

        if (name === "nameEn") {

            if (await checkPatternWithSpecialCharacter(value)) {
                setNameEnError("")
                setNameEn(value);
            } else {
                e.preventDefault()
                return;
            }
        }

        // Check for "nameHi" field
        if (name === "nameHi") {
            if (await checkPatternWithSpecialCharacter(value)) {
                setNameHiError("")
                setNameHi(value);
            } else {
                e.preventDefault()
                return;
            }
        }
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);

    }

    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    };
    //validations
    const validateForm = (item) => {
        let isValid = true;
        if (item === "subCategoryId" || item === "both") {
            if (!editSubCategoryId) {
                setEditSubCategoryIdError("Please Select Sub Category");
                isValid = false;
            } else {
                setEditSubCategoryIdError("");
            }
        }
        if (item === "formTabId" || item === "both") {
            if (!editCategoryTabId) {
                setEditCategoryTabIdError("Please Select Form Tab");
                isValid = false;
            } else {
                setEditCategoryTabIdError("");
            }
        }
        if (item === "nameEn" || item === "both") {
            if (!nameEn || nameEn.trim() === "") {
                setNameEnError("Please Enter English Name");
                isValid = false;
            } else {
                setNameEnError("");
            }
        }
        if (item === "nameHi" || item === "both") {
            if (!nameHi || nameHi.trim() === "") {
                setNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setNameHiError("");
            }
        }

        // if (item === "family" || item === "both") {
        //     if (!fontFamily) {
        //         setFontFamilyError("Please Select Font Family");
        //         isValid = false;
        //     } else {
        //         setFontFamilyError("");
        //     }
        // }

        // if (item === "Sub-Section" || item === "both") {
        //     if (!subSection) {
        //         setSubSectionErr("Please Select");
        //         isValid = false;
        //     } else {
        //         setSubSectionErr("");
        //     }
        // }

        // if (item === "Sub-Section-list" || item === "both") {
        //     if (!selectedSubSection) {
        //         setSelectedSubSectionErr("Please Select");
        //         isValid = false;
        //     } else {
        //         setSelectedSubSectionErr("");
        //     }
        // }

        if (item === "color" || item === "both") {
            if (!fontColor || fontColor.trim() === "") {
                setFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setFontColorError("");
            }
        }

        if (item === "size" || item === "both") {
            if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
                setFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setFontSizeError("");
            }
        }

        if (item === "bgcolor" || item === "both") {
            // if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
            //     setFontSizeError("Please Enter Valid Font Size");
            //     isValid = false;
            // }
            if (!backgroundColor || backgroundColor.trim() === "") {
                setBackgroundColorError("Please Select Background Color");
                isValid = false;
            } else if (backgroundColor == fontColor) {
                setBackgroundColorError("Font color and background color cannot be same.");
                isValid = false;
            } else {
                setBackgroundColorError("");
            }
        }
        if (item === "required" || item === "all") {
            if (!inputRequired) {
                setInputRequiredError("Please select Status");
                isValid = false;
            } else if (inputRequired.value === "dropdown" && requiredList.length < 2) {
                setDropDownValuesError("Dropdown must contain at least two values.");
                isValid = false;
            } else {
                setInputRequiredError("");
                setDropDownValuesError(""); // Clear dropdown error if valid
            }
        }

        return isValid;
    };
    // add From Section
    const addFormSection = async () => {
        if (!validateForm("both")) return
        try {
            // console.log("formTabId", formTabId)
            // return;
            const formData = new FormData();
            formData.append("sub_category_id", editSubCategoryId.value)
            formData.append("tab_id", editCategoryTabId.value)
            formData.append("name_en", nameEn)
            formData.append("name_hi", nameHi)
            formData.append("sort", 10)
            formData.append("status", "1")
            formData.append("font_family_id", fontFamily?.value ?? "1")
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            formData.append("background_color", backgroundColor)
            formData.append("is_geo_location_section", inputRequired?.value)
            const data = await addSubCategorySectionTabData(formData)
            if (data?.status == 200) {
                handleEditBack()
                setApiMessage({ type: 'success', message: data?.message });
            }
        } catch (error) {
            setApiMessage({ type: 'error', message: error?.message });
            console.error(error);
        }
    }
    // sub category list
    const getSubCategoryList = async () => {
        try {
            const subcategoryListData = await getSubCategoriesDropdown()
            const data = subcategoryListData?.data?.map(i => ({
                label: i?.category_name, value: i?.id
            }))
            const editData = subcategoryListData?.data?.filter((s) => s.status !== "0")?.map(i => ({
                label: i?.category_name, value: i?.id
            }))
            setSubCategoryList(data)
            setEditSubCategoryList(editData)
        } catch (error) {
            console.log("error on sub category list: ".error)
        };
    };
    // tabs list
    const getCategoryFormTabList = async () => {
        try {
            const formTabDropdownData = await getFormTabDropDownData();
            const data = formTabDropdownData?.data?.map((i) => ({
                label: i?.name_en,
                value: i?.id,
            }));
            setfullCategoryTabListData(formTabDropdownData?.data)
            setfullCategoryTabListDropdownData(data)
        } catch (error) {
            console.log("fetch categoryFormTabList  list", error)
        };
    };
    //get font families
    const getFontFamily = async () => {
        try {
            const fontFamilyData = await getFontFamilyData()
            const data = fontFamilyData?.data?.map(i => ({
                label: i?.name, value: i?.id
            }))

            setFontsList(data)
        } catch (err) {
            console.log("font families error :", err)
        }
    };

    const resetFilter = () => {
        setSearchCategoryTab("");
        setSearchSubCategory("");
        getSectionData('', '', sortBy, 1, orderBy, searchData);
    }

    //get sections
    const getSectionData = async (searchedSubCategory, searchedCategoryFromTab, sortBy, pageNo, orderBy, searchBy) => {
        try {
            setSearchData(searchBy)
            setOrderBy(orderBy)
            setSortBy(sortBy)
            const sectionList = await getSectionsData(sortBy, orderBy, searchBy ?? '', pageNo, searchedSubCategory?.value ? searchedSubCategory?.value : '', searchedCategoryFromTab?.value ? searchedCategoryFromTab?.value : '')
            setTotalPages(Math.ceil(sectionList?.data?.total / sectionList?.data?.per_page));
            setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo)
            const data = sectionList?.data?.data
            setSectionList(data)
            setTableDataLoaded(true)
        } catch (err) {
            console.log("error on sectionList: ", err)
        }
    }


    const handlePageChange = (selectedPage) => {
        getSectionData(searchSubCategory, searchCategoryTab, sortBy, selectedPage.selected + 1, orderBy, searchData); // Increment by 1 if the API starts page numbers from 1
    };


    useEffect(() => {
        getSectionData('', '', 'created_at', 1, 'desc', '');
        getFontFamily()
        getSubCategoryList()
        getCategoryFormTabList()
        setInputRequired(requiredList.find(option => option.value === "0"));
    }, [])

    // delete application section
    const deleteApplicationTab = async (item) => {
        try {
            const formData = new FormData();
            formData.append("section_id", item?.id)
            await deleteApplicationSectionsTabData(formData)
            getSectionData(searchSubCategory, searchCategoryTab, sortBy, currentPage, orderBy, searchData);
            setDeleteData("")
        } catch (error) {
            console.error(error);
        }
    }
    //edit section
    const editSubCategory = async (item) => {
        setEditData(item)
        setEditSubCategoryId(subCategoryList?.find(i => i?.value == item?.category_id))
        setEditCategoryTabId(fullCategoryTabListDropdownData?.find((i) => i?.value === item?.tab_id))
        setNameEn(item?.name_en);
        setNameHi(item?.name_hi)
        setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setFontColor(item?.font_color)
        setFontSize(item?.font_size.includes(.00) ? item?.font_size.substring(-1, 2) : item?.font_size)
        setBackgroundColor(item?.background_color)
        window.scrollTo(0, 0);
        setInputRequired(
            requiredList.find(option => option.value === (item?.is_geo_location_section === "0" ? "0" : "1"))
        );
    }

    //update from Section
    const updateFormSection = async () => {
        if (!validateForm("both")) return
        try {

            const inputName = editSubCategoryId?.label?.length > 20 ? editSubCategoryId?.label?.substring(0, 20) : editSubCategoryId?.label
            const specialCharacterRemovedStr = inputName.toLowerCase()?.replaceAll(/[^a-zA-Z0-9 ]/g, "")
            const keyName = specialCharacterRemovedStr.replaceAll(" ", "_")
            const formData = new FormData();
            formData.append("section_id", editData?.id)
            formData.append("sub_category_id", editSubCategoryId.value)
            formData.append("tab_id", editCategoryTabId.value)
            formData.append("tab_key", keyName)
            formData.append("name_en", nameEn)
            formData.append("name_hi", nameHi)
            formData.append("sort", 10)
            formData.append("status", "1")
            formData.append("font_family_id", fontFamily?.value ?? "1")
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            formData.append("background_color", backgroundColor)
            formData.append("is_geo_location_section", inputRequired?.value)
            const data = await updateSubCategoryTabSectionData(formData)
            if (data?.status == 200) {
                handleEditBack()
                setApiMessage({ type: 'success', message: data?.message });
            } else {
                setApiMessage({ type: "error", message: data.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }
    const handleEmptyData = () => {
        setNameEn("")
        setNameHi("")
        setEditSubCategoryId("")
        setFontFamily("");
        setSearchTerm("")
        setFontColor("#000000")
        setEditCategoryTabId("")
        setEditCategoryTabIdError("")
        setFontSize("")
        setBackgroundColor("#ffffff")
        setNameEnError("")
        setNameHiError("")
        setEditSubCategoryIdError("")
        setFontFamilyError("");
        setFontColorError("")
        setFontSizeError("")
        setBackgroundColorError("")
        setEditData()
        setEditContainer(false)
        setInputRequired(requiredList.find(option => option.value === "0"));
    }
    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("section_id", item?.id)
            formData.append("status", e?.value)
            const data = await handleEnableSectionsDisableData(formData)
            if (data?.status == 200) {
                getSectionData(searchSubCategory, searchCategoryTab, sortBy, currentPage, orderBy, searchData);
            } else {
                setApiMessage({ type: 'error', message: data.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }

    const handleEditBack = async () => {
        setEditContainer(false)
        setSearchData('')
        setCurrentPage(1)
        setSearchSubCategory('')
        setSearchCategoryTab('')
        setSearchCategoryTabList()
        setSortBy('created_at')
        setOrderBy('desc')
        setNameEnError('')
        setNameHiError('')
        setEditSubCategoryIdError('')
        setEditCategoryTabIdError('')
        setBackgroundColor("#000000")
        setFontFamilyError("");
        setFontColorError("")
        setFontSizeError("")
        setBackgroundColorError("")
        getSectionData('', '', 'created_at', 1, 'desc', '');
    }


    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                {editContainer ? <>
                    <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                        <div>
                            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span>Section Form</span>
                        </div>
                    </div>
                    <div className="aod-inner">
                        <div className={editData?.id ? "aod-update aod-bottom" : "aod-bottom"}>
                            <div className="aod-head text-center adm-head"> <button onClick={() => { handleEditBack() }}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Section</div>
                            <div className="row py-4">
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='lf-label w-100'>
                                            Select Sub Category
                                        </label>
                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            isDisabled={editData?.id}
                                            value={editSubCategoryId}
                                            options={editSubCategoryList}
                                            onChange={(e) => {
                                                const data = fullCategoryTabListData?.filter((i) => i?.category_id === e.value && i?.status !== "0")?.map((i) => ({
                                                    label: i?.name_en,
                                                    value: i?.id,
                                                }));
                                                setEditCategoryTabList(data)
                                                setEditSubCategoryId(e);
                                                setEditSubCategoryIdError("");
                                            }}
                                            placeholder="Select Sub Category"
                                            onMenuOpen={() => handleKeyUpLoginDetails("subCategoryId")}
                                            onMenuClose={() => handleKeyUpLoginDetails("subCategoryId")}
                                        />
                                        {editSubCategoryIdError && <p className='validate-error'>{editSubCategoryIdError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='lf-label w-100'>
                                            Select Form Tab
                                        </label>
                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            isDisabled={editData?.id}
                                            value={editCategoryTabId}
                                            options={editCategoryTabList}
                                            onChange={(e) => {
                                                setEditCategoryTabId(e);
                                                setEditCategoryTabIdError("");
                                            }}
                                            placeholder="Select Form Tab"
                                            onMenuOpen={() => handleKeyUpLoginDetails("formTabId")}
                                            onMenuClose={() => handleKeyUpLoginDetails("formTabId")}
                                        />
                                        {editCategoryTabIdError && <p className='validate-error'>{editCategoryTabIdError}</p>}
                                    </div>

                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>
                                            English Name
                                        </label>
                                        <input maxlength={60} className='w-100' placeholder='Enter English Name' type="text" name="nameEn" value={nameEn} onChange={handleInputChange} />

                                        {nameEnError && <p className='validate-error'>{nameEnError}</p>}

                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            Hindi Name
                                        </label>
                                        <input maxlength={60} className='w-100' placeholder='Enter Hindi Name' type="text" name="nameHi" value={nameHi} onChange={handleInputChange} />

                                        {nameHiError && <p className='validate-error'>{nameHiError}</p>}
                                    </div>
                                </div>

                             {/*    <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Family</label>
                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={fontFamily}
                                            options={fontsList}
                                            onChange={(e) => { setFontFamily(e); setFontFamilyError("") }}
                                            placeholder="Select Font Family"
                                            onMenuOpen={() => handleKeyUpLoginDetails("family")}
                                            onMenuClose={() => handleKeyUpLoginDetails("family")}
                                        />
                                        {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                                    </div>
                                </div> */}
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Color</label>
                                        <label className='adm-label-color'>
                                            {fontColor ? fontColor : <span>Select Font Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                                        </label>
                                        {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                        <input className='w-100' placeholder='Enter Font Size' max="20" type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                                            onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={async (e) => {
                                                const value = e.target.value;
                                                if (value > 0) {
                                                    if (await checkPatternFontSize(value)) {
                                                        setFontSize(value);
                                                        setFontSizeError("")
                                                    }
                                                } else {
                                                    setFontSize("");
                                                }
                                            }} />
                                        {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Background Color</label>
                                        <label className='adm-label-color'>
                                            {backgroundColor ? backgroundColor : <span>Select Background Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("bgcolor")} onBlur={() => handleKeyUpLoginDetails("bgcolor")} value={backgroundColor} onChange={(e) => setBackgroundColor(e?.target?.value)} />
                                        </label>
                                        {backgroundColorError && <p className='validate-error'>{backgroundColorError}</p>}
                                    </div>
                                </div>
                                {/* <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Has Sub Section ?</label>
                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={subSection}
                                            options={isSubSectionList}
                                            onChange={(e) => { setSubSection(e); setSubSectionErr("") }}
                                            placeholder="Select"
                                            onMenuOpen={() => handleKeyUpLoginDetails("Sub-Section")}
                                            onMenuClose={() => handleKeyUpLoginDetails("Sub-Section")}
                                        />
                                        {subSectionErr && <p className='validate-error'>{subSectionErr}</p>}
                                    </div>
                                </div>
                                {
                                    subSection?.value == 1 ?
                                        (
                                            <div className="col-md-4 aod-resp-tab">
                                                <div className='suf-input-box aod-form-input mb-2'>
                                                    <label className='mb-1 w-100'>Sub-Section List</label>
                                                    <Select
                                                        styles={customStylesAcc}
                                                        theme={selectTheme}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        className='menu_open_select'
                                                        classNamePrefix='ad-menu_open_select_class'
                                                        aria-label="Default select example"
                                                        value={selectedSubSection}
                                                        options={subSectionList}
                                                        onChange={(e) => { setSelectedSubSection(e); setSelectedSubSectionErr("") }}
                                                        placeholder="Select Sub-Section"
                                                        onMenuOpen={() => handleKeyUpLoginDetails("Sub-Section-list")}
                                                        onMenuClose={() => handleKeyUpLoginDetails("Sub-Section-list")}
                                                    />
                                                    {selectedSubSectionErr && <p className='validate-error'>{selectedSubSectionErr}</p>}
                                                </div>
                                            </div>
                                        ) : null
                                } */}
                                <div className="col-md-4 aod-resp-tab">
                                <div className="suf-input-box aod-form-input mb-2">
                                    <label className="mb-1">Is Geo-Location Section?</label>
                                    <Select
                                    styles={customStylesAcc}
                                    theme={selectTheme}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    className="menu_open_select"
                                    classNamePrefix="ad-menu_open_select_class"
                                    aria-label="Default select example"
                                    value={inputRequired}
                                    options={requiredList}
                                    onChange={(e) => {
                                        setInputRequired(e);
                                        setInputRequiredError("");
                                    }}
                                    placeholder="Is Geo-Location Section?"
                                    onMenuOpen={() => handleKeyUpLoginDetails("required")}
                                    onMenuClose={() => handleKeyUpLoginDetails("required")}
                                    />
                                    {inputRequiredError && (
                                    <p className="validate-error">{inputRequiredError}</p>
                                    )}
                                </div>
                                </div>
                            </div>
                            <div className="row ad-cat-row">
                                <div className=" update-btns-block">
                                    {
                                        editData?.id ?
                                            <>
                                                <button onClick={() => updateFormSection()} className='mx-3'>
                                                    Update
                                                </button> </> :
                                            <button onClick={addFormSection}>
                                                Submit
                                            </button>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </> :
                    <>
                        <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                            <div>
                                <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span> Form Sections</span>
                            </div>
                            {(grantPermissionForAddFormSection) ? (
                                <button className='adm-tab-btn' onClick={() => {
                                    handleEmptyData();
                                    setEditContainer(true)
                                }}><span><HiPlus />
                                    </span> Add</button>
                            ) : ''}
                        </div>
                        <div className="aod-inner pt-0">
                            <div className="aod-bottom">
                                <div className="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                                    <h4 className='mb-0 w-100'>Form Sections</h4>

                                    {/* <input className='w-25 px-2 form-control' placeholder='search' type="text" onChange={(e) => {
                                        const trimmedValue = e.target.value.trim(); // Trim the input value
                                        getSectionData(searchSubCategory, searchCategoryTab, sortBy, 1, orderBy, trimmedValue)
                                    }} /> */}
                                    <div className="position-relative w-50">
                                        <input
                                            className="px-2 form-control"
                                            placeholder="Search"
                                            type="text"
                                            value={searchTerm} // Add a state for the input value
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value); // Update the state
                                                const trimmedValue = e.target.value.trim(); // Trim the input value
                                                getSectionData(searchSubCategory, searchCategoryTab, sortBy, 1, orderBy, trimmedValue)
                                            }}
                                        />
                                        {searchTerm && ( // Show the clear button only when there's text
                                            <button
                                                className="btn btn-clear position-absolute"
                                                onClick={(e) => {
                                                    setSearchTerm(""); // Clear the input value
                                                    const trimmedValue = e.target.value.trim(); // Trim the input value
                                                    getSectionData(searchSubCategory, searchCategoryTab, sortBy, 1, orderBy, trimmedValue)
                                                }}
                                                style={{
                                                    top: "50%",
                                                    right: "10px",
                                                    transform: "translateY(-50%)",
                                                    border: "none",
                                                    background: "transparent",
                                                    fontSize: "1rem",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                &times; {/* This is the "X" icon */}
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="aod-head"></div>
                                <div className="row py-4">
                                    <div className="col-md-4 aod-resp-tab">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <Select
                                                styles={customStylesAcc}
                                                theme={selectTheme}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                className='menu_open_select w-100'
                                                classNamePrefix='ad-menu_open_select_class'
                                                aria-label="Default select example"
                                                value={searchSubCategory}
                                                options={subCategoryList}
                                                onChange={(e) => {
                                                    setSearchSubCategory(e);
                                                    const data = fullCategoryTabListData?.filter((i) => i?.category_id === e.value)?.map((i) => ({
                                                        label: i?.name_en,
                                                        value: i?.id,
                                                    }));
                                                    setSearchCategoryTabList(data);
                                                    setSearchCategoryTab('')
                                                    getSectionData(e, '', sortBy, 1, orderBy, searchData)
                                                }}
                                                placeholder="Select Sub Category"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 aod-resp-tab">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <Select
                                                styles={customStylesAcc}
                                                theme={selectTheme}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                className='menu_open_select'
                                                classNamePrefix='ad-menu_open_select_class'
                                                aria-label="Default select example"
                                                value={searchCategoryTab}
                                                options={searchCategoryTabList}
                                                onChange={(e) => {
                                                    setSearchCategoryTab(e);
                                                    getSectionData(searchSubCategory, e, sortBy, 1, orderBy, searchData)
                                                }}
                                                placeholder="Select Form Tab"
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <button
                                            onClick={resetFilter}
                                            className="ad-reset-btn w-auto px-4"
                                        >
                                            Reset
                                        </button>
                                    </div>

                                </div>
                                <div className="ad-reg-table">
                                    <Table size="sm" className='ad-cat-table'>
                                        <thead>
                                            <tr>
                                                <th className='ad-sno'>S. No</th>
                                                <th className='ad-long cursor-pointer' onClick={(e) => getSectionData(searchSubCategory, searchCategoryTab, 'name_en', currentPage + 1, orderBy == 'asc' ? 'desc' : 'asc', searchData)}>English Name<span><i className="fa-solid fa-sort"></i></span></th>
                                                <th className='ad-long cursor-pointer' onClick={(e) => getSectionData(searchSubCategory, searchCategoryTab, 'name_en', currentPage + 1, orderBy == 'asc' ? 'desc' : 'asc', searchData)}>Hindi Name<span><i className="fa-solid fa-sort"></i></span></th>
                                                <th className="ad-long cursor-pointer" onClick={(e) => getSectionData(searchSubCategory, searchCategoryTab, 'sub_category_name_en', currentPage + 1, orderBy == 'asc' ? 'desc' : 'asc', searchData)}>
                                                    Sub Category{" "}
                                                    <span>
                                                        <i
                                                            className="fa-solid fa-sort"
                                                        ></i>
                                                    </span>
                                                </th>
                                                <th className="ad-long cursor-pointer" onClick={(e) => getSectionData(searchSubCategory, searchCategoryTab, 'tab_name_hi', currentPage + 1, orderBy == 'asc' ? 'desc' : 'asc', searchData)}>
                                                    From Tab{" "}
                                                    <span>
                                                        <i
                                                            className="fa-solid fa-sort"
                                                        ></i>
                                                    </span>
                                                </th>
                                                <th className='ad-long cursor-pointer' onClick={(e) => getSectionData(searchSubCategory, searchCategoryTab, 'created_at', currentPage + 1, orderBy == 'asc' ? 'desc' : 'asc', searchData)}>Created At <span><i className="fa-solid fa-sort"></i></span></th>
                                                {(grantPermissionForEditFormSection) ? (<>
                                                    <th className='ad-long' >Status</th>
                                                    <th className='ad-long'>Actions</th></>
                                                ) : ''}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sectionList?.length > 0 ? sectionList?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{(currentPage * 10) + (index + 1)}</td>
                                                        <td>{item?.name_en}</td>
                                                        <td>{item?.name_hi}</td>
                                                        <td>{item?.sub_category_name_en ? item?.sub_category_name_en : item?.sub_category_name_hi ? item?.sub_category_name_hi : '--'}</td>
                                                        <td>{item?.tabs_name_en ? item?.tabs_name_en : item?.tab_name_hi ? item?.tab_name_hi : '--'}</td>
                                                        <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>
                                                        {(grantPermissionForEditFormSection) ? (<>
                                                            <td>
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item?.status == 1}
                                                                        onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                                    />
                                                                    <span className="slider"></span>
                                                                </label>
                                                            </td>
                                                            {/* <td><div className='action-btn d-flex'>
                                                            <span className='ad-cat-edit' onClick={() => { editSubCategory(item); setEditContainer(true) }}><EditIcon /></span>
                                                            <span><div className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></div></span></div></td> */}
                                                            <td>
                                                                <div className="action-btn d-flex">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                                                                    >
                                                                        <span
                                                                            className="ad-cat-edit"
                                                                            onClick={() => { editSubCategory(item); setEditContainer(true) }}
                                                                        >
                                                                            <EditIcon />
                                                                        </span>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id={`delete-tooltip-${item.id}`}>Delete</Tooltip>}
                                                                    >
                                                                        <span
                                                                            className="ad-cat-delete"
                                                                            onClick={() => setDeleteData(item)}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                        </>) : ''}
                                                    </tr>
                                                )) : tableDataLoaded ? <NotFoundTable colSpan="8"></NotFoundTable> : <></>
                                            }
                                        </tbody>
                                    </Table>
                                    {totalPages > 1 && sectionList?.length > 0 &&
                                        <div className="d-flex w-100 justify-content-end">
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                pageCount={totalPages} // Total number of pages from API
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={1}
                                                onPageChange={handlePageChange} // Method to handle page click
                                                forcePage={currentPage}
                                                containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={`page-item${!sectionList?.length > 0 ? ' disabled' : ''}`}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </>

                }
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this section ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2 d-flex flex-row">
                        <button className='mx-2' onClick={() => { deleteApplicationTab(deleteData) }}>Yes</button>
                        <button className='suf-can-butt mx-2' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default ApplicationSections