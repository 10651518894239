import React , { useEffect, useRef } from 'react'
import HomebannerLogin from './HomebannerLogin'
import { useLanguage } from '../../LanguageProvider/LanguageProvider '
import { Baseurl } from '../../../Utils/Utils'
const HomeBanner = ({ bannerData, htmlRendered, banner }) => {
  const { language, setLanguage } = useLanguage()
  const loginRef = useRef(null); // Ref to check the DOM

  useEffect(() => {
    if (loginRef.current) {
      htmlRendered();
    }
  }, [loginRef.current]);

  return (
    <div className='hgbg-wrap' ref={loginRef}>
      <section className='hb-bg-main ratio custom_ratio' id="hg-bh-main-slider">
        <div className='hb-bg-inner'>
          <div className="container px-5">
            <div className='HomeBanner-main'>
              <div className='HB-first-box'>
                {banner && banner?.status === "1"  ? <>
                  <p>{language ? banner?.content_hi : banner?.content}</p>
                  <div className='btns-hb-main'>
                    {banner?.banner_link.map((bann, index) => {
                      return (
                        <div className='HB-first-box-btn'  key={index}>
                          <div className='HB-btn-icon'> <img src={`${Baseurl()}/${bann?.icon}`} alt="" /> </div>
                          <p>{language ? bann?.name_hi : bann?.name}</p>
                        </div>
                      )
                    })}
                  </div>
                  </>
                : <></> }
               </div>
              <div className='HB-second-box'>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  <HomebannerLogin bannerData={bannerData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default HomeBanner