import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {
    GoogleMap,
    Autocomplete,
    Marker,
    useJsApiLoader
} from "@react-google-maps/api";
import { Language } from '@mui/icons-material';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';

const Geolocation = ({ subCatId, initialAddress, mapLocation , sectionFormDataField , tabsWholeDataField, geoLocationSection}) => {
    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [searchAddress, setSearchAddress] = useState(mapLocation || "Clock Tower ,Dehradun, Uttarakhand");
    const [autocomplete, setAutocomplete] = useState(null);
    const { language, setLanguage } = useLanguage();
    const inputRef = useRef(null);
    const mapAddress = {};
    let sectionKey ;

    const [formData, setFormData] = useState({
        latitude: null,
        longitude: null
    });

    useEffect(() => {
        if (sectionFormDataField && tabsWholeDataField) {
            if(geoLocationSection){
                sectionFormDataField[geoLocationSection]?.forEach((section) => {
                    const dynamicKey = `${section.field_key}`;
                    if (tabsWholeDataField.section_fields[dynamicKey] !== undefined) {
                        mapAddress[section.name_en] = tabsWholeDataField.section_fields[dynamicKey];
                    }
                });
            }
            
        }

        mapLocation = (Object.values(mapAddress || "Clock Tower ,Dehradun, Uttarakhand").join(", "));
    }, [mapLocation]);


    const defaultCenter = { lat: 30.3165, lng: 78.0322 }; // Fallback coordinates for Dehradun center

    const GmapOptions = {
        disableDefaultUI: false,
        draggable: true,
        scrollwheel: true,
        streetViewControl: false,
    };

    const loaderOptions = useMemo(() => ({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCnOLiPcQ3RCoPoLG5tEd8gcqGqHVL3Nuc',
        libraries: ["places"],
    }), []);

    const { isLoaded } = useJsApiLoader(loaderOptions);

    const geocodeAddress = useCallback((address) => {
        if (window.google) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
                if (status === "OK" && results[0]) {
                    const location = results[0].geometry.location;
                    const latLng = { lat: location.lat(), lng: location.lng() };
                    setMarkerPosition(latLng);
                    setSearchAddress(address);
                    setFormData({
                        latitude: latLng.lat,
                        longitude: latLng.lng
                    });
                    map?.setCenter(latLng);
                    map?.setZoom(15);
                } else {
                    setMarkerPosition(defaultCenter); // Fallback if geocode fails
                    setFormData({
                        latitude: defaultCenter.lat,
                        longitude: defaultCenter.lng
                    });
                    map?.setCenter(defaultCenter);
                    map?.setZoom(12);
                }   
            });
        }
    }, [map]);

    const onLoad = useCallback((mapInstance) => {
        setMap(mapInstance);
        if (mapLocation) {
            geocodeAddress(mapLocation);
        } else {
            setMarkerPosition(defaultCenter);
            setFormData({
                latitude: defaultCenter.lat,
                longitude: defaultCenter.lng,
            });
            mapInstance.setCenter(defaultCenter);
            mapInstance.setZoom(12);
        }
    }, [mapLocation, geocodeAddress]);
    

    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                const location = place.geometry.location;
                const latLng = { lat: location.lat(), lng: location.lng() };
                setMarkerPosition(latLng);
                setSearchAddress(`${place.name} ${place.formatted_address}` || "");
                map?.panTo(latLng);
                map?.setZoom(15);
                setFormData({
                    latitude: location.lat(),
                    longitude: location.lng(),
                });
            }
        }
    };

    const onAutocompleteLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onMarkerDragEnd = (e) => {
        const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setMarkerPosition(latLng);
        setFormData({ latitude: latLng.lat, longitude: latLng.lng });

        if (window.google) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === "OK" && results[0]) {
                    setSearchAddress(results[0].formatted_address);
                }
            });
        }
    };

    if (!isLoaded) return <div>{language ? "लोड हो रहा है..." : "Loading..."}</div>;

    return (
        <>
            <div className="form-block">
                <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
                    <input
                        type="text"
                        ref={inputRef}
                        placeholder={language ? "कोई पता खोजें" : "Search for an address"}
                        value={searchAddress}
                        onChange={(e) => setSearchAddress(e.target.value)}
                        className="form-control"
                    />
                </Autocomplete>
                <GoogleMap
                    onLoad={onLoad}
                    center={markerPosition || defaultCenter}
                    zoom={15}
                    mapContainerStyle={{ height: "400px", width: "100%" }}
                    options={GmapOptions}
                >
                    {markerPosition && (
                        <Marker
                            position={markerPosition}
                            draggable={true}
                            onDragEnd={onMarkerDragEnd}
                        />
                    )}
                </GoogleMap>
            </div>
        </>
    );
};

export default Geolocation;
