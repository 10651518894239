import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { getSubCategories } from "../../services/api/outer";
import { checkApplicationData, checkApplicationsData } from "../../services/api/user";
import { formatDateWithSlash } from "../../Utils/common";
import {
    GetLocalStorageParsed,
    SetLocalStorageStringify
} from "../../Utils/Utils";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";


const Applications = () => {
    const { language, setLanguage } = useLanguage();
    const [applicationData, setApplicationData] = useState(null);
    const formData = new FormData();
    const userDetail = GetLocalStorageParsed("userDetail");
    const navigate = useNavigate();
    const [subCategoryData, setSubCategoryData] = useState([]);

    useEffect(() => {
        getApplicationData();
    }, []);

    const getApplicationData = async () => {
        const formData = new FormData();
        formData.append("user_id", userDetail?.id);
        const data = await checkApplicationsData(formData);
        setApplicationData(data?.data)
        try {
        } catch (err) {
            console.log(err);
        }
    };


     // Sub category click function
  const handleSubCatClick = async (item) => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", item?.category_id);
      formData.append("user_id", userDetail?.id);
      const data = await checkApplicationData(formData);
      SetLocalStorageStringify("LoginSubCatData", item?.sub_category);
      if (data?.status == 200) {
        SetLocalStorageStringify("application_id", data?.data?.id);
        if(item?.status === "8") {
            navigate("/application-draft");
        } else {
            navigate("/application/application-form");
        }
      } else {
        navigate("/guidelines");
      }
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <Dashboard
            first={language ? "होम" : "Home"}
            second={language ? "प्रमाणपत्र" : "Applications"}
            head={language ? "प्रमाणपत्र" : "Applications"}
        >
            <>
                <div className="aod-bottom">
                    <div className="page_heading">
                        <h5>{language ? "अनुप्रयोग" : "Applications"}</h5>
                    </div>
                    <div className="row">
                        <div className="reciept-table table-responsive mt-2">
                            <table
                                className="table table mb-0 dataTable transaction_table"
                                id="receipt_table"
                                role="grid"
                            >
                                <thead className="">
                                    <tr role="row">
                                        <th>{language ? "क्रम संख्या" : "S.No"}</th>
                                        <th>{language ? "आवेदन संख्या" : "Application Number"}</th>
                                        <th>{language ? "श्रेणी नाम" : "Category Name"}</th>
                                        <th>{language ? "तारीख" : "Date"}</th>
                                        <th>{language ? "स्थिति" : "Status"}</th>
                                        <th>{language ? "कार्रवाई" : "Action"}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {applicationData?.length > 0 ? (
                                        applicationData?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.application_number}</td>
                                                <td>{language ? item?.sub_category?.name_hi : item?.sub_category?.name}</td>
                                                <td>
                                                    {item?.created_at
                                                        ? formatDateWithSlash(item?.created_at)
                                                        : "--"}
                                                </td>
                                                <td>
                                                    {(() => {
                                                        switch (item?.status) {
                                                            case "0": return language ? "निष्क्रिय" : "Inactive";
                                                            case "1": return language ? "सक्रिय" : "Active";
                                                            case "2": return language ? "लंबित" : "Pending";
                                                            case "3": return language ? "मसौदा" : "Draft";
                                                            case "4": return language ? "Approved" : "Approved";
                                                            case "5": return language ? "रक्ष्" : "Rejected";
                                                            case "6": return language ? "नवीकरण" : "Renew";
                                                            case "7": return language ? "समीक्षाधीन" : "Under Review";
                                                            case "8": return language ? "प्रस्तुत" : "Submitted";
                                                            default: return language ? "अज्ञात" : "Unknown"; // in case of an undefined status
                                                        }
                                                    })()}
                                                </td>
                                                <td>
                                                    {item?.status === "4" ? (
                                                        language ? "अनुमत" :"Approved"
                                                    ) :
                                                     (
                                                        <a
                                                            className="btn btn-green-view"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            onClick={() => handleSubCatClick(item)}
                                                        >
                                                            {item?.status === "8" ? language ? "समीक्षा" : "Review" : language ? "पुनः आरंभ" : "Resume"}
                                                        </a>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        </Dashboard>
    );
};

export default Applications;
