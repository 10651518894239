import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Box, Typography } from "@mui/material"; // Import Box and Typography for layout and styling

export default function PieActiveArc() {
  const desktopOS = [
    {
      label: "Active",
      value: 52,
    },
    {
      label: "Pending",
      value: 26,
    },
    {
      label: "Approved",
      value: 13,
    },
    {
      label: "Rejected",
      value: 2,
    },
    {
      label: "Other",
      value: 5,
    },
  ];

  const valueFormatter = (item) => `${item.value}`;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* PieChart with no legend */}
      <PieChart
        series={[
          {
            data: desktopOS,
            highlightScope: { fade: "global", highlight: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            valueFormatter,
            // Explicitly not adding any legend-related properties here
          },
        ]}
        height={300}
      />

      {/* Custom Legend at the Bottom */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",

          marginTop: 2,
        }}
      >
        {desktopOS.map((item) => (
          <Box
            key={item.label}
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: 2,
              marginTop: 1,
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor:
                  item.label === "Active"
                    ? "#008080"
                    : item.label === "Pending"
                    ? "#1E90FF"
                    : item.label === "Approved"
                    ? "#9370DB"
                    : item.label === "Rejected"
                    ? "#4B0082"
                    : "#4682B4",
                marginRight: 1,
              }}
            />
            <Typography variant="body2">{item.label}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
