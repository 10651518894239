import React, { useEffect, useState } from "react";
import {
  AccomodationIcon,
  AdditionalIcon,
  EmployeeIcon,
  OrganizationIcon,
  PaymentIcon,
  UploadIcon,
  UploadIconPhoto,
} from "./ApplicationIcon";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { Apiurl, GetLocalStorage, GetLocalStorageParsed, validateonSubmitHandle } from "../../Utils/Utils";
import axios from "axios";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { BsXLg } from "react-icons/bs";
// import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import {
  changeApplicationStatus,
  createApplicationForm,
  getApplicationForm,
  getSection,
  getSectionFields,
  staticDropDownsList,
} from "../../services/api/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { customStylesAcc, customStylesAccUser, firstCharUpperCase, selectTheme, selectThemeUser } from "../../Utils/common";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import InputTypeText from "../inputType/inputTypeText";
import SelectType from "../inputType/SelectType";
import SubSection from "../inputType/subSection";
import TableTab from "../inputType/tableTab";
import DocsUpload from "../inputType/DocsUpload";
import Payment from "../inputType/Payments"
import Geolocation from "../inputType/geoloaction";
import EmpDetails from "../inputType/EmpDetails";
import { set } from "react-hook-form";
import { CgCheckO, CgPen } from "react-icons/cg";
import InputTableData from "../ApplicationDraft/InputTableData";
import DesignationTable from "../ApplicationDraft/DesignationTable";
import DocsUploadDraft from "../ApplicationDraft/DocsUploadDraft";

const OrganizationDetails = ({
  tabsData,
  tabActiveData,
  setTabActiveData,
  inputFormData,
  setInputFormData,
  getLoginSubCabData,
}) => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const [isEmpDetailsTab, setIsEmpDetailsTab] = useState(false);
  const location = useLocation();
  const [tabActive, setTabActive] = useState(null);
  const [sectionData, setSectionData] = useState([]);
  const [applicationFromData, setApplicationFromData] = useState([]);
  const [initialLoaded, setInitialLoaded] = useState([]);
  const [sectionFormData, setSectionFormData] = useState([]);
  const [empDesignationTableData, setEmpDesignationTableData] = useState();
  const [formValues, setFormValues] = useState({});
  const [isLastTab, setIslastTab] = useState(false)
  const [isFirstTab, setIsFirstTab] = useState(false)
  const [isLocationTab, setIsLocationTab] = useState(false)
  const [sectionFieldsData, setSectionFieldsData] = useState({});
  const [isDocumnetTab, setIsDocumentTab] = useState(false)
  const [isPaymentTab, setIsPaymentTab] = useState(false)
  const [subcategoryId, setSubCategoryId] = useState()
  const applicationId = GetLocalStorage("application_id");
  const userDetail = GetLocalStorageParsed("userDetail");
  const loginCatInfo = GetLocalStorageParsed("LoginSubCatData");
  const [tableData, setTableData] = useState("");
  const [checkboxError, setCheckboxError] = useState(""); // State for checkbox error
  const [addressString , setAddressString] = useState(""); 
 
  const [showEmpDetailsTab, setShowEmpDetailsTab] = useState(false)
  const [empDesignationField, setEmpDesignationField] = useState(null)
  const [shouldShowEmpDetailsTab, setShouldShowEmpDetailsTab] = useState(false)
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [empDetailIndex, setEmpDetailIndex] = useState(false);
  const [documentList, setDocumentList] = useState([]);

  const [showEditIcon, setShowEditIcon] = useState(true);
  const [isDraft, setIsDraft] = useState(false);
  const [geoLocationSection, setGeoLocationSection] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [isDraftSubmitted, setIsDraftSubmitted] = useState(false);

  const editToggle = () => {
    setShowEditIcon(showEditIcon ? false : true)
  }

  const handleSubmitClick = async () => {
    setIsDraft(false);
    if(isPaymentEnabled) {
      setIsDocumentTab(false)
      setIsPaymentTab(true)
      setTabActive(combinedTabsData.find((t) => t.id === "make-payment"))
      setIsDraftSubmitted(true)
    } else {
      const formData = new FormData();
      formData.append("status", "8");
      formData.append("application_id", applicationId);
      const changeApplicationStatusResponse = await changeApplicationStatus(formData);
      if (changeApplicationStatusResponse.status === "200" || changeApplicationStatusResponse.status === 200) {
        setIsFormSubmitted(true);
      }
    }
  }


  const combinedTabsData = [
    ...(tabsData || []), // Dynamic tabs
    { id: "doc-upload", name_en: "Document Uploads", name_hi: "दस्तावेज़ अपलोड", background_color: '#ffffff' },
    ...(tabsData?.[0]?.payment_status === "1"
      ? [{ id: "make-payment", name_en: "Make Payment", name_hi: "भुगतान करें", background_color: '#ffffff' }] 
      : []),
  ];

  combinedTabsData.splice(1, 0, { id: "geo-location", name_en: "Geo-Location", name_hi: "भू-स्थान", background_color: '#ffffff'  });

  if (shouldShowEmpDetailsTab && empDetailIndex >= 0) {
    combinedTabsData.splice(empDetailIndex + 1, 0, {
      id: "emp-details",
      name_en: "Employee Details",
      name_hi: "कर्मचारी विवरण",
      background_color: '#ffffff'
    });
  }


  const [tabsWholeData, setTabsWholeData] = useState({
    section_fields: {},
    sub_section_fields: []
  });

  const inputTypeList = [
    { label: "text", value: 1 },
    { label: "radio", value: 4 },
    { label: "password", value: 7 },
    { label: "file", value: 5 },
    { label: "dropdown", value: 2 },
    // { label: "Sub Section", value: 6 },
    { label: "checkbox", value: 3 },
    { label: "Designation Table", value: 8 },
    {label: "date", value: 9 },
  ];

  const [errors, setErrors] = useState({});
  const [docErrors, setDocErrors] = useState({});

  useEffect(() => {
    if (tabsData && tabsData?.length) {
      // setSubCategoryId(loginCatInfo?.id);
      fetchApplicationFormData(true);
      setIsPaymentEnabled(tabsData?.[0]?.payment_status === "1" ? true : false)     
    }
    // const isAdditionalTabPresent = tabsData?.filter(item => item.type.includes("Additional Details"));
    // if (isAdditionalTabPresent && isAdditionalTabPresent.length > 0) {
    //   setShowEmpDetailsTab(true)
    // } else {
    //   setShowEmpDetailsTab(false)
    // }
  }, [tabsData]);

  // Set state to show or hide the Employee Details tab
  useEffect(() => {
    setShowEmpDetailsTab(shouldShowEmpDetailsTab);
  }, [shouldShowEmpDetailsTab]);

  useEffect(() => {
    // if (tabsData?.length) {
    //   setSubCategoryId(tabsData[0]?.category_id)
    //   fetchApplicationFormData()
    // }
    // Set the initial active tab and fetch data
    if (combinedTabsData && combinedTabsData?.length > 0) {
      const initialActiveTab = combinedTabsData[0];
      setTabActive(initialActiveTab);
      setIsFirstTab(true)
      //setSubCategoryData(initialActiveTab.category_id)
      fetchSectionData(initialActiveTab, false);
    }
  }, [initialLoaded]);

  const fetchApplicationFormData = async (initialLoad) => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", tabsData[0]?.category_id);
      formData.append("application_detail_id", applicationId);
      const applicationFormResponse = await getApplicationForm(formData);
      if (applicationFormResponse.status === "200" || applicationFormResponse.status === 200) {
        setApplicationFromData(applicationFormResponse?.data);
        if(applicationFormResponse?.data?.["tracking_number"]){
          setTrackingNumber(applicationFormResponse?.data?.["tracking_number"])
        }
      } else {
        setApplicationFromData(null);
      }
      if(initialLoad) {
        setInitialLoaded(true);
      }
    } catch (err) {
      console.error("Error fetching application data:", err);
    }
  };

  const fetchSectionData = async (tab) => {
    try {
      if(parseInt(tab?.id) > 0) {
        const formData = new FormData();
        formData.append("category_id", tab.category_id);
        formData.append("tab_id", tab.id);
        const sectionResponse = await getSection(formData);
        if (sectionResponse.status === 200) {
          fetchSectionFormData(sectionResponse.data, tab.id);
          setSectionData(sectionResponse.data);
        } else {
          setSectionData(null);
        }
      } else {
        setSectionData(null);
      }
    } catch (err) {
      console.error("Error fetching section data:", err);
    }
  };

  const cretaeApplicationFormData = async (sectionFields, subSectionFields) => {
    try {
      const payload = {
        sub_category_id: tabsData[0].category_id,
        user_id: userDetail?.id,
        application_id: applicationId,
        section_fields: sectionFields, // pass as an object
        sub_section_fields: subSectionFields, // pass as an object
      };

      const sectionResponse = await createApplicationForm(payload); // Send as JSON
      if (sectionResponse.status === "200" || sectionResponse.status === 200) {
        fetchApplicationFormData(false);
      } else {
      }
    } catch (err) {
      console.error("Error fetching section data:", err);
    }
  };

  const fetchSectionFormData = async (sectionObj, tabId) => {
    try {
      for (let i = 0; i < sectionObj.length; i++) {
        const formData = new FormData();
        formData.append("category_id", sectionObj[i].category_id);
        formData.append("tab_id", tabId);
        formData.append("section_id", sectionObj[i].id);

        const sectionFormResponse = await getSectionFields(formData);
        console.log('sectionFormResponse', i, sectionFormResponse);
        if (sectionFormResponse.status === 200) {
          
          setSectionFormData((prevData) => ({
            ...prevData,
            [sectionObj[i].id]: sectionFormResponse.data,
          }));

          if(sectionObj[i]?.is_geo_location_section && sectionObj[i]?.is_geo_location_section === "1"){
            setGeoLocationSection(sectionObj[i]?.id)
          }
          
          if(showEmpDetailsTab === false){
            setShouldShowEmpDetailsTab(sectionFormResponse.data.some(item => item?.type === "8"));
            setEmpDesignationField(sectionFormResponse?.data?.find(item => item?.type === "8"))
            setEmpDetailIndex(combinedTabsData.findIndex((t) => t.id === sectionFormResponse?.data?.find(item => item?.type === "8")?.tab_id))
          }
          
          sectionFormResponse.data.forEach((item) => {
            const value = item?.type === "8" && applicationFromData?.["f_" + item.field_key] ? JSON.parse(applicationFromData?.["f_" + item.field_key]) : applicationFromData?.["f_" + item.field_key] || ""; // Get initial value from applicationFormData if exists


            if (item.sub_section_id) {
              // Populate tabsWholeData for sub-sections
              setTabsWholeData((prevData) => {
                const existingSubSection = Object.keys(prevData?.sub_section_fields)?.find(
                  (sub) => Object.keys(sub)[0] === item.field_key
                );
                if (!existingSubSection) {
                  const fieldKeyJson = item.field_key + "_json"; // Create dynamic key like `fieldKey_json`
                  const jsonValue = applicationFromData?.["f_" + item.field_key + "_json"] || null;

                  const expandedJsonValue = jsonValue
                  ? [
                      ...jsonValue,
                      ...Array(Math.max(value - jsonValue.length, 0)).fill().map(() => {
                        // Create an object with the same keys as the first item, but values set to ""
                        return Object.keys(jsonValue[0])?.reduce((acc, key) => {
                            acc[key] = ""; // Set to empty string (or use `null` if preferred)
                            return acc;
                        }, {});
                      }),
                  ]
                  : null;

                  return {
                    ...prevData,
                    sub_section_fields: {
                      ...prevData.sub_section_fields,
                      [item.field_key]: value === null || value === undefined || value === '' ? "1" : value, // Existing field key with value
                      [fieldKeyJson]: expandedJsonValue, // Dynamic key with value as an object
                    }
                  };
                }
                return prevData;
              });
            } else {
              if (!item.field_key.endsWith("_json")) {
                setTabsWholeData((prevData) => ({
                  ...prevData,
                  section_fields: {
                    ...prevData.section_fields,
                    [item.field_key]: value,
                  },
                }));
              } else {
                console.log(item.field_key);
              }
              // Populate tabsWholeData for section fields

            }
          });
        }
      }
    } catch (err) {
      console.error("Error fetching section form data:", err);
    }
  };

  const handleInputChange = (e, field, sectionId, value) => {
    const name = e.target.name;
    const isRequired = field?.required === "1";
    let err = null;

    if (isRequired && !value) {
      err = { hi: `${field?.name_hi} आवश्यक है`, en: `${field?.name_en} is required` }
    } else {
      if(JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp && JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp?.length > 0){
          const validations = validateonSubmitHandle(field, value, null);
          err = validations === "" ? null : validations;
      } else {
        err = null ;
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: err, // Include field name in the error message
    }));

    setSectionFieldsData({
      ...sectionFieldsData,
      [name]: value,
    });

    setTabsWholeData(prevData => {
      if (field.sub_section_id) {
        const fieldKeyJson = field.field_key + "_json"; // Create dynamic key

        return {
          ...prevData,
          sub_section_fields: prevData?.sub_section_id === field.sub_section_id
              ? {
                ...prevData.sub_section_fields,
                [fieldKeyJson]: {
                  ...prevData.sub_section_fields[fieldKeyJson],
                  [name]: value,
                },
              }
              : prevData.sub_section_fields
        };
      } else {
        return {
          ...prevData,
          section_fields: { ...prevData.section_fields, [name]: value }
        };
      }
    });

  };

  const handleTabChange = async (tab) => {
    setTabActive(tab);
    fetchSectionData(tab);
    // console.log(tab?.id)
    staticsTab(tab)
    setErrors({});
    setDocErrors({})
  };

  const handleFormSubmit = async (e) => {
    const isAllValid = await handleApplicationSubmit();
    if(isAllValid) {
      const newDocErrors = {};
      documentList.forEach((doc, index) => {
        if (!doc.upload_documents || doc.upload_documents.length === 0) {
          newDocErrors[doc?.id] = { hi: `${doc?.name_hi} आवश्यक है`, en: `${doc?.name_en} is required` }
        }
      });

      setDocErrors({...docErrors, ...newDocErrors})

      const hasDocErrors = Object.values(newDocErrors).some(error => error !== null);
      if (!hasDocErrors) {
        setIsDraft(true)
      } 
    }
  }

  const handleDashboardClick = () => {
    navigate("/dashboard")
  }


  const handleFormNext = (e) => {
    e.preventDefault(); // Prevent default form submission

    const newErrors = {};

    const currentTabSectionData = Object.entries(sectionFormData)
    .filter(([key, records]) => records.every(record => record?.tab_id === tabActive?.id))?.reduce((acc, [key, records]) => {
      acc[key] = records;
      return acc;
    }, {});

    Object.keys(currentTabSectionData).forEach((sectionId) => {
      sectionFormData[sectionId].forEach((field) => {
        if(tabsWholeData.section_fields[field.field_key] !== null && tabsWholeData.section_fields[field.field_key] !== undefined) {
          const value = tabsWholeData.section_fields[field.field_key] || ""; // Get the value of the field
          const isRequired = field.required === "1"; // Check if the field is required

          if (isRequired && !value) {
            newErrors[field?.field_key] = { hi: `${field?.name_hi} आवश्यक है`, en: `${field?.name_en} is required` }
            const errr = language ? newErrors?.[field?.field_key]?.hi : newErrors?.[field?.field_key]?.en
          } else {
            if(JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp && JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp?.length > 0){
                const validations = validateonSubmitHandle(field, value, null);
                newErrors[field?.field_key] = validations === "" ? null : validations;
            } else {
                newErrors[field?.field_key] = null ;
            }
          }
        }
      });
    });

    Object.keys(currentTabSectionData).forEach((sectionId) => {
      sectionFormData[sectionId].forEach((field) => {
        if(tabsWholeData?.sub_section_fields?.[field.field_key + "_json"] && Array.isArray(tabsWholeData?.sub_section_fields?.[field.field_key + "_json"])) {
          tabsWholeData?.sub_section_fields?.[field.field_key + "_json"]?.forEach((item, itemIndex) => {
            Object.keys(item).forEach((key) => {
              if (item[key] === null || item[key].trim() === "") {
                // Check if value is null or empty
                newErrors[
                  `${field.field_key}_json_${itemIndex}_${key}`
                ] =  { hi: `आवश्यक है`, en: `Required` };
              }
            });
          });
        }
      });
    });

    // Update the state with new errors
    setErrors({ ...errors, ...newErrors });

    // Find the index of the current active tab in the combined array
    const currentIndex = combinedTabsData.findIndex(
      (tab) => tab.id === tabActive.id
    );

    if (tabActive.id === "emp-details")   {
      const checkbox = document.getElementById("wp-comment-cookies-consent");
      if (!checkbox || !checkbox.checked) {
        newErrors["checkbox"] = "You must confirm that the information provided is correct.";
        setCheckboxError(newErrors["checkbox"]); // Set error state for checkbox
        return;
      } else {
        setCheckboxError(""); // Clear checkbox error if checked
      }

    }

    // Check if there are any errors before proceeding
    const hasErrors = Object.values(newErrors).some(error => error !== null);
    if (hasErrors) {
      console.log("Validation Errors:", newErrors);
      return; // Stop submission if there are validation errors
    }


    const nextTab = combinedTabsData[currentIndex + 1];
    staticsTab(nextTab)


    const application_data = {
      "category_id": tabsData[0].category_id,
      ...tabsWholeData
    };

    const prefixedSectionFields = Object.fromEntries(
      Object.entries(tabsWholeData.section_fields).map(([key, value]) => [
        `f_${key}`,
        value,
      ])
    );

    // Prefix 'f_' to keys in mergedSubSectionFields
    const prefixedSubSectionFields = Object.fromEntries(
      Object.entries(tabsWholeData.sub_section_fields).map(([key, value]) => [
        `f_${key}`,
        value,
      ])
    );

    console.log("tabsWholeData", JSON.stringify(application_data, null, 2));

    cretaeApplicationFormData(
      prefixedSectionFields,
      prefixedSubSectionFields
    );

    const mapAddress = {};

    if(geoLocationSection){
      sectionFormData[geoLocationSection]?.forEach((section) => {
        const dynamicKey = `${section.field_key}`;
        if (tabsWholeData.section_fields[dynamicKey] !== undefined) {
          mapAddress[section.name_en] = tabsWholeData.section_fields[dynamicKey];
        }
      });
    }

    setAddressString(Object.values(mapAddress || "Clock Tower ,Dehradun, Uttarakhand").join(", "));

    if (nextTab) {
      setTabActive(nextTab);
      fetchSectionData(nextTab);
    } else {
      console.log("Reached the last tab");
    }
  };
  const goBack = () => {
    const currentIndex = combinedTabsData.findIndex((tab) => tab.id === tabActive.id);
    const prevTab = combinedTabsData[currentIndex - 1];

    if (prevTab) {
      setTabActive(prevTab);
      fetchSectionData(prevTab);
    } else {
      console.log("Reached the last tab");
    }
  }

  const staticsTab = (nextTab) => {    // to show ststic active tabs content
    if (nextTab?.id == 'doc-upload') {
      setIsDocumentTab(true)
    } else {
      setIsDocumentTab(false)
    }
    if (nextTab?.id === 'make-payment') {
      setIsPaymentTab(true);
    } else {
      setIsPaymentTab(false)
    }
    if (nextTab?.name_en == combinedTabsData[combinedTabsData.length - 1]?.name_en) {
      setIslastTab(true)
    } else {
      setIslastTab(false)
    }
    if (nextTab?.name_en == combinedTabsData[0]?.name_en) {
      setIsFirstTab(true)
    } else {
      setIsFirstTab(false)
    }

    if (nextTab?.id == 'geo-location') {

      setIsLocationTab(true)
    } else {
      setIsLocationTab(false)
    }
    if (nextTab?.id == "emp-details") {
      setIsEmpDetailsTab(true);
    } else {
      setIsEmpDetailsTab(false);
    }
  }

  const handleEmpDesignationUpdate = (updatedValuesArray) => {
    setEmpDesignationTableData(updatedValuesArray);
    console.log('Received updated values array from TableTab:', updatedValuesArray);
  };

  const handleApplicationSubmit = async ()  => {
    const newErrors = {};
    let firstErrTab = null;

    Object.keys(sectionFormData).forEach((sectionId) => {
      sectionFormData[sectionId].forEach((field) => {
        if(tabsWholeData.section_fields[field.field_key] !== null && tabsWholeData.section_fields[field.field_key] !== undefined) {
          const value = tabsWholeData.section_fields[field.field_key] || ""; // Get the value of the field
          const isRequired = field.required === "1"; // Check if the field is required
          
          if (isRequired && !value) {
            newErrors[field?.field_key] = { hi: `${field?.name_hi} आवश्यक है`, en: `${field?.name_en} is required` }
          } else {
            if(JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp && JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp?.length > 0){
                const validations = validateonSubmitHandle(field, value, null);
                newErrors[field?.field_key] = validations === "" ? null : validations;
            } else {
                newErrors[field?.field_key] = null ;
            }
          }
          firstErrTab = firstErrTab ? firstErrTab : newErrors[field?.field_key] ? combinedTabsData?.find((t) => t.id === sectionFormData?.[sectionId]?.[0]?.tab_id) : null;
        }
      });
    });

    Object.keys(sectionFormData).forEach((sectionId) => {
      sectionFormData[sectionId].forEach((field) => {
        if(tabsWholeData?.sub_section_fields?.[field.field_key + "_json"] && Array.isArray(tabsWholeData?.sub_section_fields?.[field.field_key + "_json"])) {
          tabsWholeData?.sub_section_fields?.[field.field_key + "_json"]?.forEach((item, itemIndex) => {
            Object.keys(item).forEach((key) => {
              if (item[key] === null || item[key].trim() === "") {
                // Check if value is null or empty
                newErrors[
                  `${field.field_key}_json_${itemIndex}_${key}`
                ] =  { hi: `आवश्यक है`, en: `Required` };
                firstErrTab = firstErrTab ? firstErrTab : newErrors[`${field.field_key}_json_${itemIndex}_${key}`] ? combinedTabsData?.find((t) => t.id === sectionFormData?.[sectionId]?.[0]?.tab_id) : null;
              }
            });
          });
        }
      });
    });

    // Update the state with new errors
   
    if(firstErrTab) {
      setTabActive(firstErrTab)
      fetchSectionData(firstErrTab);
      staticsTab(firstErrTab)
      setErrors({ ...errors, ...newErrors });
    }
    
    return firstErrTab === null;
  }

  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={language ? "आवेदन फार्म" : loginCatInfo?.category_name ? loginCatInfo?.category_name +"/Application Form" :  loginCatInfo?.name +"/Application Form"}
      head={language ? "आवेदन फार्म" : loginCatInfo?.category_name ? loginCatInfo?.category_name +"/Application Form":  loginCatInfo?.name +"/Application Form"}
    >
      <div className="aod-outer">
        {tabsData?.length > 0 && !isFormSubmitted && !isDraft ? <div className="aod-inner">
          <div className="aod-top justify-content-start">
            {combinedTabsData?.filter((tab) => (tab.id !== 'emp-details' || showEmpDetailsTab))
              .sort((a, b) => new Date(a.id) - new Date(b.id))
              .map((tab) => (
                <nav key={tab.id} className="nav nav-pills nav-fill" style={{
                  background: tab?.background_color,                
                }}>
                  <a
                    className={tabActive?.id === tab?.id ? "green" : `${tab?.background_color} white`} style={{ fontSize: `${tab?.font_size}px`, color: tabActive?.id === tab?.id ? "white" : tab?.font_color }}
                    onClick={() => handleTabChange(tab)}
                  >
                    {language ? tab?.name_hi : firstCharUpperCase(tab?.name_en)}
                  </a>
                </nav>
              ))}
          </div>
        </div> : <></>}

        <div className="aod-bottom">
          {tabsData?.length > 0 ? sectionData
            ?.sort((a, b) => new Date(a?.id) - new Date(b?.id))
            .map((section) => (
              <div key={section.id}>
                <div
                  className="m-wrapper-heading"
                  style={{
                    backgroundColor: section?.background_color,
                    color: section?.font_color,
                  }}
                >
                  <div style={{ fontSize: `${section?.font_size}px` }}>
                    {language ? section?.name_hi : firstCharUpperCase(section?.name_en)}
                  </div>
                </div>

                <div className="m-form-wrapper">
                  <form>
                    <div className="row">
                      {sectionFormData[section?.id]?.map((field) => (
                        <div
                          className={
                            inputTypeList?.find((x) => x?.value == field?.type)
                              ?.label == "Designation Table" ||
                              field?.sub_section_id != undefined ||
                              field?.sub_section_id != null
                              ? "col-lg-12"
                              : "col-md-4"
                          }
                          key={field.id}
                        >
                          <div className="mb-3 f_fields">
                            {inputTypeList?.find(
                              (x) => x.value == field?.type
                            )?.label != "Designation Table" ? (
                              <label
                                htmlFor={field.field_key}
                                className="form-label"
                              >
                                <span
                                  className={`field-label${field?.required === "1" ? " required" : ""
                                    }`}
                                  style={{
                                    color: field?.font_color,
                                    fontSize: `${field?.font_size}px`,
                                    backgroundColor: field?.background_color,
                                  }}
                                >
                                  {language ? field?.name_hi : field?.name_en}
                                </span>
                              </label>
                            ) : null}

                            {field?.sub_section_id ? (
                              <SubSection
                                fieldData={field}
                                handleInputChange={handleInputChange}
                                inputTypeList={inputTypeList}
                                sectionId={field?.section_id}
                                language={language} // or true for Hindi
                                formValues={formValues}
                                setFormValues={setFormValues}
                                subSectionFieldKey={field.field_key}
                                setTabsWholeData={setTabsWholeData}
                                subSectionId={field?.sub_section_id}
                                errors={errors}
                                setErrors={setErrors}
                                applicationFromData={applicationFromData}
                                tabsWholeData={tabsWholeData}
                              // subSectionsData={/* Your sub-section data */}
                              />
                            ) : ["text", "checkbox", "password", "date"].includes(
                              inputTypeList?.find(
                                (x) => x.value == field?.type
                              )?.label
                            ) ? (
                              <div key={field.field_key}>
                                <InputTypeText
                                  key={field.field_key}
                                  type={
                                    inputTypeList?.find(
                                      (x) => x.value == field?.type
                                    )?.label
                                  }
                                  placeholder={
                                    language
                                      ? field?.placeholder_hi
                                      : field?.placeholder_en
                                  }
                                  id={field.field_key}
                                  name={field.field_key}
                                  value={
                                    tabsWholeData.section_fields[field.field_key] || ""
                                  } // Controlled value
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      field,
                                      section.id,
                                      e?.target?.value
                                    )
                                  } // Change handler
                                />
                                {typeof errors[field.field_key] === 'object' && errors[field.field_key] !== null && (
                                  <span className="validate-error">
                                     {language ? errors?.[field?.field_key]?.hi : errors?.[field?.field_key]?.en}
                                  </span>
                                )}
                              </div>
                            ) : inputTypeList?.find(
                              (x) => x.value == field?.type
                            )?.label === "dropdown" ? (
                              <div key={field.field_key}>
                                <SelectType
                                  key={field.field_key}
                                  dropdownId={field?.dropdown_id}
                                  fieldKey={field?.field_key}
                                  value={
                                    tabsWholeData.section_fields[field.field_key] || ""
                                  } // Pass current value
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      field,
                                      section.id,
                                      e?.target?.value?.value
                                    )
                                  } // Change handler
                                  placeholder={
                                    language
                                      ? "विकल्प चुनें"
                                      : "Select Option"
                                  }
                                  styles={customStylesAccUser}
                                  theme={selectThemeUser}
                                />
                                {typeof errors[field.field_key] === 'object' && errors[field.field_key] !== null && (
                                  <span className="validate-error">{language ? errors?.[field?.field_key]?.hi : errors?.[field?.field_key]?.en}</span>
                                )}
                              </div>
                            ) : inputTypeList?.find(
                              (x) => x.value == field?.type
                            )?.label === "Designation Table" ? (
                              <TableTab
                                fieldData={field}
                                inputTypeList={inputTypeList}
                                subCatId={field?.category_id}
                                language={language} // or true for Hindi
                                formValues={tabsWholeData.section_fields[field.field_key] || []}
                                onChange={(e) => handleInputChange(e, field, section.id, e?.target?.value)} // Change handler
                                handleEmpDesignationUpdate={handleEmpDesignationUpdate} // Pass the callback to child
                                tabsWholeData={tabsWholeData}
                                setTabsWholeData={setTabsWholeData}
                                applicationFromData={applicationFromData}
                              />
                            ) : null}
                          </div>
                        </div>
                      ))}

                    </div>

                  </form>


                </div>
              </div>
            )) :
            <><div className="text-center">{language ? "इस श्रेणी के लिए कोई आवेदन पत्र उपलब्ध नहीं है" : "No Application form is available for this category"}</div></>}
          {
            isLocationTab  && !isFormSubmitted &&
            <div className="m-form-wrapper">
              <form>
                <div className="row">
                  <div
                    className="col-lg-12">
                    <div className="mb-3 f_fields">
                      <Geolocation initialAddress={userDetail?.address}
                        subCatId={loginCatInfo?.id}  mapLocation={addressString} sectionFormDataField={sectionFormData} tabsWholeDataField={tabsWholeData} geoLocationSection={geoLocationSection} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }
          {isEmpDetailsTab  && !isFormSubmitted && !isDraft && (
            <div className="m-form-wrapper">
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 f_fields">
                      <EmpDetails
                        empDesignationTableData={empDesignationTableData}
                        subCatId={loginCatInfo?.id}
                        language={language}
                        tableData={tableData} // Pass updated tableData to EmpDetails
                        tabsWholeData={tabsWholeData}
                        setTabsWholeData={setTabsWholeData}
                        applicationFromData={applicationFromData}
                        empDesignationField={empDesignationField}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className="comment-form-cookies-consent d-flex align-items-center justify-content-start mt-4">
                <input
                  id="wp-comment-cookies-consent"
                  className="mt-1"
                  name="wp-comment-cookies-consent"
                  type="checkbox"
                  value="yes"
                />
                <label htmlFor="wp-comment-cookies-consent" className="px-1">
                  {language ? "मैं इसके द्वारा पुष्टि करता हूं कि मेरे द्वारा प्रदान की गई सभी जानकारी मेरी जानकारी के अनुसार सही है।" 
                  : "I hereby confirm that all the information provided by me is correct to my knowledge."}
                </label>
              </div>
              {checkboxError && (
                <div className="text-danger mt-2">{checkboxError}</div>
              )}
            </div>
          )}
          {isDocumnetTab && !isFormSubmitted && !isDraft && (
            <div className="m-form-wrapper">
              <form>
                <div className="row">
                  <div
                    className="col-lg-12">
                    <div className="mb-3 f_fields">
                      <DocsUpload subCatId={loginCatInfo?.id} setDocErrors={setDocErrors} docErrors={docErrors} documentList={documentList} setDocumentList={setDocumentList} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          {
            isPaymentEnabled && isPaymentTab && !isFormSubmitted && !isDraft &&
            <div className="m-form-wrapper">
              <form>
                <div className="row">
                  <div
                    className="col-lg-12">
                    <div className="mb-3 f_fields">
                      <Payment subCatId={loginCatInfo?.id} handleApplicationSubmit={handleApplicationSubmit} setIsFormSubmitted={setIsFormSubmitted} applicationId={applicationId} isDraftSubmitted={isDraftSubmitted}/>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }
          {
            isFormSubmitted &&
            <div className="payment-successfull-block hide">
              <div className="row">
                <div className="col-md-12">
                  <div className="payment-detail-blocks payment-success-inner-wrapprer">
                    <div className="paymnet-done-icon">
                      {" "}
                      <CgCheckO />
                    </div>
                    <div className="payment-done-text">
                      <p className="mb-2">{trackingNumber ? language ? `ट्रैकिंग नंबर: ${trackingNumber}` : `Tracking Number: ${trackingNumber}` : ""}</p>
                      <h5 className="">{isPaymentEnabled ? language ? "भुगतान सफल!" : "Payment Successful!" : language ? "सबमिशन सफल" : "Submission Successful"}</h5>
                      <p>
                        {isPaymentEnabled ? 
                        language ? "भुगतान हो चुका है और आवेदन सफलतापूर्वक जमा हो गया है! हमारे साथ बने रहने के लिए धन्यवाद." : "The Payment has been done and Application submitted successfully! Thanks for being their with us." :
                        language ? "आवेदन सफलतापूर्वक सबमिट कर दिया गया है! हमारे साथ बने रहने के लिए धन्यवाद." : "The Application has been submitted successfully! Thanks for being their with us."}
                      </p>
                    </div>
                    <div className="dashboard-btn">
                      <button className="user-theme-btn" onClick={handleDashboardClick}> {language ? "डैशबोर्ड" : "Dashboard"}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {!isLastTab && !isDocumnetTab && tabsData?.length > 0 && !isPaymentTab && !isFormSubmitted && !isDraft && (
            <button
              type="submit"
              className="btn btn-primary user-theme-btn mt-3 "
              onClick={handleFormNext}
            >
              {language ? "अगला" : "Next"}
            </button>
          )}
          {isDocumnetTab && !isPaymentTab && !isFormSubmitted && !isDraft && (
            <button
              type="submit"
              className="btn btn-primary user-theme-btn mt-3 "
              onClick={handleFormSubmit}
            >
              {language ? "अगला" : "Next"}
              {/* {language ? "जमा करना" : "Submit"} */}
            </button>
          )}
          {isDraft && (
            <>
            <div className="gc-apply-butt" style={{ justifyContent: 'flex-end' , 'marginBottom' : '20px' }}>
              <button onClick={handleSubmitClick}>
                {isPaymentEnabled ? language ? "सबमिट करें और जारी रखें" : "Submit & Continue" : language ? "सबमिट करें" : "Submit"}
              </button>
            </div>
            <div className="">
              <div className="preview-page-main-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    {tabsData && tabsData.length > 0 ? tabsData?.map((item) => (
                      <div key={item?.id}>
                        <InputTableData tabsData={item} isEditable={true}/>
                      </div>
                    )) : null}
                  </div>
                </div>
    
                <div className="col-lg-12 mt-4">
                  {tabsData?.some(item => item.name_en == "Additional Details") &&
                    <div>
                      <DesignationTable tabsData={tabsData} subCatId={loginCatInfo?.id} isEditable={true} />
                    </div>
                  }
    
                </div>
    
                <div className="col-md-12 mt-4">
                  <div className="data-wrapper">
                    <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                      <div className="data-wrapper-heading">
                        <h5 className="mb-0">Upload Documents</h5>
                      </div>
                      <div className="data-wrapper-edit-icon" onClick={editToggle}>
                        {showEditIcon ? <CgPen /> : (
                          <>
                            {/* <div>Save</div> */}
                            <div onClick={editToggle}>Cancel</div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mt-4">
                        <DocsUploadDraft subCatId={loginCatInfo?.id} showEditIcon={showEditIcon} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          )}
        </div>

      </div>
    </Dashboard>
  );
};

export default OrganizationDetails;