import React, { useEffect, useState } from 'react'
import '../../Admin/Admin.css'
import { Link , useNavigate} from 'react-router-dom';  
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { allActiveUsers, allInActiveUsers,getFrontendUserList, totalAdminUser, totalApplications,getCategoryListData, getSubCategory ,totalApprovedApplication, totalExpiredcertificate, totalPendingApplication, totalRejectedApplication, totalRenewApplication, totalUpDateApplications } from '../../../services/api/admin'
import { Message } from '../../../components/message'
import Chart from '../../../components/PieChart/PieActiveArc'
import PieActiveArc from '../../../components/PieChart/PieActiveArc'
import { GetLocalStorageParsed } from '../../../Utils/Utils';
const DashboardLayout = () => {
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [tApplications, setTApplications] = useState(0)
    const [activeApplications, setActiveApplications] = useState(0)
    const [inActiveapplication, setInactiveapplication] = useState(0)
    const [pendingApplication, setPendingApplication] = useState(0)
    const [approvedApplication, setApprovedApplication] = useState(0)
    const [rejectedApplication, setRejectedApplication] = useState(0)
    const [renewApplication, setRenewApplication] = useState(0)
    const [upDateApplications, setUpDateApplications] = useState(0)
    const [activeUser, setActiveUser] = useState(0)
    const [inActiveUser, setInActiveUser] = useState(0)
    const navigate = useNavigate();
    const [adminUser, setAdminUser] = useState(0)
    const [tCategories, setTCategories] = useState(0)
    const [tSubCategories, setTSubCategories] = useState(0)
    const [expiredcertificate, setExpiredcertificate] = useState(0)
    useEffect(() => {
        getTotalApplicationSubmitted()
        getApplicationPending()
        getapprovedApplication()
        getRejectedAPPlication()
        getRenewApplication()
        getAdminUser()
        getTotalCategories()
        getTotalSubCategories()
        getActiveUsers()
        // getActiveUsers()
        // getInActiveUsers()
        // getUpdatedApplication()
        // getexpiredCert()
    }, [])
    const userDetail = GetLocalStorageParsed("userDetail");
    const getTotalApplicationSubmitted = async () => {
        try {
            const data = await totalApplications()
            if (data) {
                setTApplications(data?.data?.total)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }
    const getTotalCategories = async () => {
        try {
            const data = await getCategoryListData()
            if (data) {
                setTCategories(data?.data?.total)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }
    const getTotalSubCategories = async () => {
        try {
            const data = await getSubCategory()
            if (data) {
                setTSubCategories(data?.data?.total)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const getApplicationPending = async () => {
        try {
            const data = await totalPendingApplication()
            if (data) {
                setPendingApplication(data?.data?.pending)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const getapprovedApplication = async () => {
        try {
            const data = await totalApprovedApplication()
            if (data) {
                setApprovedApplication(data?.data?.approved)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const getRejectedAPPlication = async () => {
        try {
            const data = await totalRejectedApplication()
            if (data) {
                setRejectedApplication(data?.data?.rejected)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const getRenewApplication = async () => {
        try {
            const data = await totalRenewApplication()
            if (data) {
                setRenewApplication(data?.data?.renew)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const getUpdatedApplication = async () => {
        try {
            const data = await totalUpDateApplications()
            if (data) {
                setUpDateApplications(data?.data)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const getexpiredCert = async () => {
        try {
            const data = await totalExpiredcertificate()
            if (data) {
                setExpiredcertificate(data?.data)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const getActiveUsers = async () => {
        try {
            const data = await getFrontendUserList()
            if (data?.status === 200) {
                setActiveUser(data?.data.length)
            } else {
                setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const getInActiveUsers = async () => {
        try {
            const data = await allInActiveUsers()
            if (data) {
                setInActiveUser(data?.data)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const getAdminUser = async () => {
        try {
            const data = await totalAdminUser()

            if (data) {
                setAdminUser(data?.data?.total)
            } else {
                // setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }



    return (
        <AdminDashboard>

            <main id="main" className="main PageHite mainAdmin">
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <h1 className='PtilteAdmin mb-3'>{userDetail && userDetail.permissions && userDetail.permissions.length > 0 ? 'Welcome, Admin to Dashboard' : 'Welcome, Super Admin to Dashboard'}</h1>
                <section>
                    <div className="row">
                        <div className="content-wrapper" id="admin">
                            <div className="card p-0 m-0 mb-4 dashboard_top">
                                <div className="card-body">
                                    <h5 className='mb-3'> Statistics </h5>
                                    {/* <h5 className='mb-3'> Total Users Detail</h5> */}
                                    <div className="chart_card_wrapper">
                                        <div  onClick={() => navigate("/admin/categories")} className="application_card bg_green text-white pointer-class">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-center">
                                                    <span className='text-dark fw-semibold'>Total Categories</span>
                                                    <h3 className="mb-0 text-black mt-1 fw-normal">{tCategories}</h3>
                                                </div>
                                                <i className="fa-regular fa-folder blue-bg"></i>
                                            </div>
                                        </div>
                                        <div onClick={() => navigate("/admin/sub-categories")} className="application_card bg_green text-white pointer-class">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-center">
                                                    <span className='text-dark fw-semibold'>Total Sub-Categories</span>
                                                    <h3 className="mb-0 text-black mt-1 fw-normal">{tSubCategories}</h3>
                                                </div>
                                                <i className="fa-regular fa-folder blue-bg"></i>
                                            </div>
                                        </div>
                                        {/* <div className="application_card bg_green text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-center">
                                                    <span className='text-dark fw-semibold'>Total Applications Users</span>
                                                    <h3 className="mb-0 text-black mt-1 fw-normal">{tApplications}</h3>
                                                </div>
                                                <i className="fa-regular fa-folder blue-bg"></i>
                                            </div>
                                        </div> */}
                                        <div onClick={() => navigate("/admin/admin-users")} className="application_card bg_purple text-white pointer-class">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-center">
                                                    <span className='text-dark fw-semibold'>Total Admin Users</span>
                                                    <h3 className="mb-0 text-black mt-1 fw-normal">{adminUser}</h3>
                                                </div>
                                                <i className="fa-regular fa-user purple-bg"></i>
                                            </div>
                                        </div>
                                        {/* <div className="application_card  bg_red text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-center">
                                                    <span className='text-dark fw-semibold'>In-Active Users</span>
                                                    <h3 className="mb-0 text-black mt-1 fw-normal">{inActiveUser}</h3>
                                                </div>
                                                <i className="fa-regular fa-circle-xmark  red-bg"></i>
                                            </div>
                                        </div> */}
                                        <div className="application_card bg_blue text-white pointer-class" onClick={() => navigate("/admin/user")}>
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-center">
                                                    {/* <span className='text-dark fw-semibold'>Active Users</span> */}
                                                    <span className='text-dark fw-semibold'>Travel Trade Users</span>
                                                    <h3 className="mb-0 text-black mt-1 fw-normal">{activeUser}</h3>
                                                </div>
                                                <i className="smallcard_icon fa fa-user-plus darkblue-bg"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card p-0 m-0 mb-5 dashboard_middle">
                                <div className="card-body">
                                    <h5 className='mb-3'> Total Applications Detail</h5>
                                    <div className="chart_card_wrapper dashboard_middle_cards">
                                            <div onClick={() => navigate("/admin/total-applications")} class="application_card bg_green text-white pointer-class">
                                                <div class="application_card_body h-100">
                                                    <div class="d-flex flex-column h-100 justify-content-center">
                                                        <div className='tab_icon mb-2'> <i class="fa-regular fa-folder green-text"></i></div>
                                                        <span class="text-dark fw-semibold">Total Applications</span>
                                                    </div>
                                                    <h3 class="mb-0 text-black mt-1 fw-normal">{tApplications}</h3>
                                                </div>
                                            </div>
                                        <div onClick={() => navigate("/admin/pending-applications")} class="application_card bg_orange text-white pointer-class">
                                            <div class="application_card_body h-100">
                                                <div class="d-flex flex-column h-100 justify-content-center">
                                                    <div className='tab_icon mb-2'> <i class="fa-regular fa-hourglass-half orange-text"></i></div>
                                                    <span class="text-dark fw-semibold">Pending Applications</span>
                                                </div>
                                                <h3 class="mb-0 text-black mt-1 fw-normal">{pendingApplication}</h3>
                                            </div>
                                        </div>

                                        <div onClick={() => navigate("/admin/approved-applications")} class="application_card bg_pink text-white pointer-class">
                                            <div class="application_card_body h-100">
                                                <div class="d-flex flex-column h-100 justify-content-center">
                                                    <div className='tab_icon mb-2'> <i class="fa-regular fa-circle-check pink-text"></i></div>
                                                    <span class="text-dark fw-semibold">Approved Applications</span>
                                                </div>
                                                <h3 class="mb-0 text-black mt-1 fw-normal">{approvedApplication}</h3>
                                            </div>
                                        </div>

                                        <div onClick={() => navigate("/admin/rejected-applications")} class="application_card bg_red text-white pointer-class">
                                            <div class="application_card_body h-100">
                                                <div class="d-flex flex-column h-100 justify-content-center">
                                                    <div className='tab_icon mb-2'> <i class="fa-regular fa-circle-xmark red-text"></i></div>
                                                    <span class="text-dark fw-semibold">Rejected Applications</span>
                                                </div>
                                                <h3 class="mb-0 text-black mt-1 fw-normal">{rejectedApplication}</h3>
                                            </div>
                                        </div>

                                        <div onClick={() => navigate("/admin/renewal-applications")} class="application_card bg_orange text-white pointer-class">
                                            <div class="application_card_body h-100">
                                                <div class="d-flex flex-column h-100 justify-content-center">
                                                    <div className='tab_icon mb-2'> <i class=" fa fa-user-plus orange-text"></i></div>
                                                    <span class="text-dark fw-semibold">Renewal Applications</span>
                                                </div>
                                                <h3 class="mb-0 text-black mt-1 fw-normal">{approvedApplication}</h3>
                                            </div>
                                        </div>

                                        <div onClick={() => navigate("/admin/update-app-requests")} class="application_card bg_seagreen text-white pointer-class">
                                            <div class="application_card_body h-100">
                                                <div class="d-flex flex-column h-100 justify-content-center">
                                                    <div className='tab_icon mb-2'> <i class="fa-regular fa-pen-to-square green-text"></i></div>
                                                    <span class="text-dark fw-semibold">Update Application Requests</span>
                                                </div>
                                                <h3 class="mb-0 text-black mt-1 fw-normal">1</h3>
                                            </div>
                                        </div>
                                        <div class="application_card bg_red text-white pointer-class" onClick={() => navigate("/admin/expired-certificates")}>
                                            <div class="application_card_body h-100">
                                                <div class="d-flex flex-column h-100 justify-content-center">
                                                    <div className='tab_icon mb-2'> <i class="fa-regular fa-calendar-xmark red-text"></i></div>
                                                    <span class="text-dark fw-semibold">Certificate Expired</span>
                                                </div>
                                                <h3 class="mb-0 text-black mt-1 fw-normal">1</h3>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            {/* <div className="card p-0 m-0 mb-5">
                                <div className="card-body">
                                    <div className="chart_card_wrapper">
                                        <div className="application_card bg_green text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-between">
                                                    <span>Total Applications</span>
                                                    <h3 className="mb-0">{tApplications}</h3>
                                                </div>
                                                <i className="fa-regular fa-folder"></i>
                                            </div>
                                        </div>
                                        <div className="application_card bg_blue text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-between">
                                                    <span>Pending Applications</span>
                                                    <h3 className="mb-0">{pendingApplication}</h3>
                                                </div>
                                                <i className="fa-regular fa-hourglass-half"></i>
                                            </div>
                                        </div>
                                        <div className="application_card bg_orange text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-between">
                                                    <span>Approved Applications</span>
                                                    <h3 className="mb-0">{approvedApplication}</h3>
                                                </div>
                                                <i className="fa-regular fa-circle-check"></i>
                                            </div>
                                        </div>
                                        <div className="application_card bg_maroon text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-between">
                                                    <span>Rejected Applications</span>
                                                    <h3 className="mb-0">{rejectedApplication}</h3>
                                                </div>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                            </div>
                                        </div>
                                        <div className="application_card bg_featherStar text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-between">
                                                    <span>Renewal Applications</span>
                                                    <h3 className="mb-0">{renewApplication}</h3>
                                                </div>
                                                <i className="smallcard_icon fa fa-user-plus"></i>
                                            </div>
                                        </div>
                                        <div className="application_card bg_teal text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-between">
                                                    <span>Update Application Requests</span>
                                                    <h3 className="mb-0">{upDateApplications}</h3>
                                                </div>
                                                <i className="fa-regular fa-pen-to-square"></i>
                                            </div>
                                        </div>
                                        <div className="application_card bg_purple text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-between">
                                                    <span>Certificate Expired</span>
                                                    <h3 className="mb-0">{expiredcertificate}</h3>
                                                </div>
                                                <i className="fa-regular fa-calendar-xmark"></i>
                                            </div>
                                        </div>
                                        <div className="application_card bg_green text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-between">
                                                    <span>Total Admin Users</span>
                                                    <h3 className="mb-0">{adminUser}</h3>
                                                </div>
                                                <i className="fa-regular fa-user"></i>
                                            </div>
                                        </div>
                                        <div className="application_card bg_maroon text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-between">
                                                    <span>In-Active Users</span>
                                                    <h3 className="mb-0">{inActiveUser}</h3>
                                                </div>
                                                <i className="fa-regular fa-circle-xmark"></i>
                                            </div>
                                        </div>
                                        <div className="application_card bg_featherStar text-white">
                                            <div className="application_card_body h-100">
                                                <div className="d-flex flex-column h-100 justify-content-between">
                                                    <span>Active Users</span>
                                                    <h3 className="mb-0">{activeUser}</h3>
                                                </div>
                                                <i className="smallcard_icon fa fa-user-plus"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}



                            <div className='row'>
                                <div className='col-xl-8 col-lg-6 col-md-6 mb-4'>
                                    <div className="card p-0 m-0 ">
                                        <div className="card-body">
                                            <div className="dashboard_header_c mb-4">
                                                <h5 className="mb-0">Applications districts wise Record - Uttarakhand</h5>
                                                <div className="dropdown filter-category">
                                                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                        Category
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item" href="#">Action</a>
                                                        <a className="dropdown-item" href="#">Another action</a>
                                                        <a className="dropdown-item" href="#">Something else here</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="chart_card_wrapper">
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper bg_green">
                                                                <i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Almora</span>

                                                                    <span>(17)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">

                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper  bg_blue">
                                                                <i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Bageshwar</span>

                                                                    <span>(19)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">

                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper bg_purple"><i className="fa-regular fa-map"></i></div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Chamoli</span>

                                                                    <span>(12)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">

                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper bg_teal">
                                                                <i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Champawat</span>

                                                                    <span>(4)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">

                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper ">
                                                                <i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Dehradun</span>

                                                                    <span>(2)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">
                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper bg_green">
                                                                <i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Haridwar</span>

                                                                    <span>(5)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">
                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper bg_blue">
                                                                <i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Nainital</span>

                                                                    <span>(1)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">
                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper bg_purple"><i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Pauri Garhwal</span>

                                                                    <span>(0)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">
                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper">
                                                                <i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Pithoragarh</span>

                                                                    <span>(1)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">
                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper  bg_teal">
                                                                <i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Rudra Prayag</span>

                                                                    <span>(1)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">
                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper  bg_purple">
                                                                <i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Tehri Garhwal</span>

                                                                    <span>(2)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">
                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper bg_samphireGreen">
                                                                <i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Uttar Kashi</span>

                                                                    <span>(0)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">
                                                    </div>
                                                </div>
                                                <div className="chart_card_itm">
                                                    <div className="chart_card_itm_body">
                                                        <div className="chart_card_itm_header">
                                                            <div className="square_icon_wrapper  bg_featherStar"><i className="fa-regular fa-map"></i>
                                                            </div>
                                                            <div className="chart_card_itm_header_right_data">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <span>Udam Singh Nagar</span>

                                                                    <span>(0)</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="charht_card_itm_footer">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-xl-4 col-lg-6 col-md-6 mb-4'>
                                    <div className="card p-0 m-0  chart_card">
                                        <div className="card-body ">
                                            <h5 class="mb-0">District - Almora</h5>
                                            <div className='mt-5 ms-5'>
                                                <PieActiveArc></PieActiveArc>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            </main>


        </AdminDashboard >
    )
}
export default DashboardLayout




