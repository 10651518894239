import React, { useEffect, useState } from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import { CgPen } from "react-icons/cg";
import {
  createApplicationForm,
  dropDownsList,
  getApplicationForm,
  getSection,
  getSectionFields,
  staticDropDownsList,
} from "../../services/api/user";
import InputTypeText from "../inputType/inputTypeText";
import { GetLocalStorage, GetLocalStorageParsed } from "../../Utils/Utils";
import SelectTypeDraft from "./SelectTypeDraft";
import { customStylesAccUser, selectThemeUser } from "../../Utils/common";
import SubSectionTable from "./SubSectionTable";

const InputTableData = ({ tabsData, isEditable }) => {
  const { language } = useLanguage();
  const userDetail = GetLocalStorageParsed("userDetail");
  const applicationId = GetLocalStorage("application_id");
  const [sectionFields, setSectionFields] = useState({});
  const [subSectionFields, setsubSectionFields] = useState({});
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [tabsWholeData, setTabsWholeData] = useState([]);
  const inputTypeList = [
    { label: "text", value: 1 },
    { label: "radio", value: 4 },
    { label: "password", value: 7 },
    { label: "file", value: 5 },
    { label: "dropdown", value: 2 },
    // { label: "Sub Section", value: 6 },
    { label: "checkbox", value: 3 },
    { label: "Designation Table", value: 8 },
  ];

  useEffect(() => {
    fetchSectionData(tabsData);
    fetchApplicationFormData();
  }, [tabsData, showEditIcon]);

  const fetchApplicationFormData = async () => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", tabsData?.category_id);
      formData.append("application_detail_id", applicationId);
      const applicationFormResponse = await getApplicationForm(formData);
      if (
        applicationFormResponse.status === "200" ||
        applicationFormResponse.status === 200
      ) {
        setTabsWholeData((prevdata) => ({
          ...prevdata,
          ...applicationFormResponse?.data, // Spread applicationFromData values into prevdata
        }));
      } else {
      }
    } catch (err) {
      console.error("Error fetching application data:", err);
    }
  };

  const editToggle = async () => {
    setShowEditIcon(showEditIcon ? false : true);
    await fetchApplicationFormData();
  };

  const fetchSectionData = async (tab) => {
    try {
      const formData = new FormData();
      formData.append("category_id", tab?.category_id);
      formData.append("tab_id", tab.id);

      const sectionResponse = await getSection(formData);
      if (sectionResponse.status === 200) {
        const sections = sectionResponse.data;
        const allFields = [];
        const allSubSectionFields = [];

        await Promise.all(
          sections.map(async (section) => {
            const fields = await fetchSectionFormData(section, tab.id);
            allFields.push(...fields?.section);
            allSubSectionFields.push(...fields.subSection);
          })
        );

        setsubSectionFields((prev) => ({ ...prev, [tab.id]: allSubSectionFields }))
        setSectionFields((prev) => ({ ...prev, [tab.id]: allFields }));
      }
    } catch (err) {
      console.error("Error fetching section data:", err);
    }
  };

  const fetchSectionFormData = async (sectionObj, tabId) => {
    try {
      const formData = new FormData();
      formData.append("category_id", sectionObj.category_id);
      formData.append("tab_id", tabId);
      formData.append("section_id", sectionObj.id);

      const sectionFormResponse = await getSectionFields(formData);
      if (sectionFormResponse.status === 200) {
        const sectionForm = sectionFormResponse.data.filter(
          (item) => !item.sub_section_id && item.type != 8
        );
        const subSectionForm = sectionFormResponse.data.filter(
          (item) => item.sub_section_id
        );
        return {
          section: sectionForm,
          subSection: subSectionForm
        }
      }
      return [];
    } catch (err) {
      console.error("Error fetching section form data:", err);
      return [];
    }
  };


  const handleOnChange = (e) => {
    const { name, value } = e?.target;
    setTabsWholeData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        sub_category_id: tabsData.category_id,
        user_id: userDetail?.id,
        application_id: applicationId,
        section_fields: tabsWholeData
      };

      const sectionResponse = await createApplicationForm(payload); // Send as JSON
      if (sectionResponse.status === "200" || sectionResponse.status === 200) {
      } else {
      }
    } catch (err) {
      console.error("Error fetching section data:", err);
    }
  };

  return (
    <>
      <div className="mb-4">
        <div className="col-md-12">
          <div className="data-wrapper">
            <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
              <div className="data-wrapper-heading">
                <h5 className="mb-0">
                  {language ? tabsData.name_hi : tabsData.name_en}
                </h5>
              </div>
              {isEditable ? <div className="data-wrapper-edit-icon" onClick={editToggle}>
                {showEditIcon ? (
                  <CgPen />
                ) : (
                  <>
                    <div onClick={handleSubmit}>Save</div>
                    <div onClick={editToggle}>Cancel</div>
                  </>
                )}
              </div> : <></>}
            </div>

            <div className="row">
              {(sectionFields[tabsData.id] || []).map((item) => {
                const fieldKey = `f_${item.field_key}`;
                const fieldValue = tabsWholeData[fieldKey] || "--";
                return (
                  <div className="col-md-6" key={item.id}>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">
                          {language ? item.name_hi : item.name_en}
                        </h6>
                      </div>
                      {showEditIcon ? (
                        item?.type == 2 ? (
                          <div
                            className={
                              showEditIcon
                                ? "preview-right-heading"
                                : "preview-right-input"
                            }
                          >
                            <SelectTypeDraft
                              fieldKey={fieldKey}
                              styles={customStylesAccUser}
                              theme={selectThemeUser}
                              fieldValue={fieldValue}
                              showEditIcon={showEditIcon}
                              setTabsWholeData={setTabsWholeData}
                              placeholder={
                                language
                                  ? item?.placeholder_hi
                                  : item?.placeholder_en
                              }
                              dropdownId={item?.dropdown_id}
                            />
                          </div>
                        ) : (
                          <div
                            className={
                              showEditIcon
                                ? "preview-right-heading"
                                : "preview-right-input"
                            }
                          >
                            <p className="mb-0">{fieldValue}</p>
                          </div>
                        )
                      ) : item?.type == 2 ? (
                        <div
                          className={
                            showEditIcon
                              ? "preview-right-heading"
                              : "preview-right-input"
                          }
                        >
                          <SelectTypeDraft
                            fieldKey={fieldKey}
                            styles={customStylesAccUser}
                            theme={selectThemeUser}
                            fieldValue={fieldValue}
                            setTabsWholeData={setTabsWholeData}
                            placeholder={
                              language
                                ? item?.placeholder_hi
                                : item?.placeholder_en
                            }
                            dropdownId={item?.dropdown_id}
                          />
                        </div>
                      ) : (
                        <div
                          className={
                            showEditIcon
                              ? "preview-right-heading"
                              : "preview-right-input"
                          }
                        >
                          <input
                            type={item?.type}
                            id={item.field_key}
                            name={fieldKey}
                            className="form-control"
                            value={tabsWholeData[fieldKey] || ""}
                            onChange={handleOnChange}
                            placeholder={
                              language
                                ? item?.placeholder_hi
                                : item?.placeholder_en
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

            </div>
          </div>
        </div>
      </div>

      {subSectionFields[tabsData.id] && subSectionFields[tabsData.id]?.length > 0 && (
        <div className="mb-4">

          {subSectionFields[tabsData.id]?.map((item) => {
            const fieldKey = `f_${item?.field_key}`;
            const fieldValue = tabsWholeData[fieldKey] || "--";
            return (
              <div className="col-lg-12 mt-4">
                <div className="card mb-3">
                  <div className="card-body bg-card user-tab-table ">
                    <div className="form-bar d-flex justify-content-between align-items-center p-3">
                      <div className="bar-left-detail d-flex col-md-3  justify-content-between">
                        <h5 className="p-0 text-dark mb-0"> {language ? item.name_hi : item.name_en} :</h5>
                        {showEditIcon ? (
                          <div
                            className={
                              showEditIcon
                                ? "preview-right-heading"
                                : "preview-right-input"
                            }
                          >
                            <p className="mb-0">{fieldValue}</p>
                          </div>

                        ) : (
                          <div
                            className={
                              showEditIcon
                                ? "preview-right-heading"
                                : "preview-right-input"
                            }
                          >
                            <input
                              type={item?.type}
                              id={item?.field_key}
                              name={fieldKey}
                              className="form-control"
                              value={tabsWholeData[fieldKey] || ""}
                              onChange={handleOnChange}
                              placeholder={
                                language
                                  ? item?.placeholder_hi
                                  : item?.placeholder_en
                              }
                            />
                          </div>
                        )}
                      </div>
                      {isEditable ? <div className="data-wrapper-edit-icon" onClick={editToggle}>
                        {showEditIcon ? (
                          <CgPen />
                        ) : (
                          <>
                            <div onClick={handleSubmit}>Save</div>
                            <div onClick={editToggle}>Cancel</div>
                          </>
                        )}
                      </div> : <></>}
                    </div>
                    <SubSectionTable showEditIcon={showEditIcon} setTabsWholeData={setTabsWholeData} subSectionDetails={item} fieldValue={fieldValue} tabsWholeData={tabsWholeData} fieldKey={fieldKey} />
                  </div>
                </div>
              </div>
            );
          })}


        </div>
      )}
    </>
  );
};

export default InputTableData;
