/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import SideMenu from "./SideMenu";
import { useNavigate } from "react-router-dom";
import {
  DashboardBellIcon,
  DashboardToogleIcon,
} from "../../../components/Dashboard/DashboardIcons";
import { NavDropdown } from "react-bootstrap";
import {
  Baseurl,
  ClearLocalStorage,
  GetLocalStorage,
  GetLocalStorageParsed,
  SetLocalStorage,
  SetLocalStorageStringify,
} from "../../../Utils/Utils";
import { getRefreshToken, userLogout } from "../../../services/api/user";
import { getHomepageLogo } from "../../../services/api/admin";
import { useLanguage } from "../../../components/LanguageProvider/LanguageProvider ";

const AdminDashboard = ({ children }) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [imagePath, setImagePath] = useState('https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png');
  const { language, setLanguage } = useLanguage();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const token = GetLocalStorage("userInfo");
  const userDetail = GetLocalStorageParsed("userDetail");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    WebsiteLogo();
  }, []);

  const WebsiteLogo = async () => {
    try {
      const usersListData = await getHomepageLogo();
      if (usersListData.status === 200) {
        setImagePath(Baseurl() +"/"+ usersListData?.data?.path);
      }else{
        setImagePath("https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png");
      }
    } catch (err) {
      setImagePath("https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png");
    }
  };


  const handleLogout = async (e) => {
    try {
      // if (e === "image") {
      const logout = await userLogout();
      if (logout) {
        navigate("/");
        ClearLocalStorage();
      }
      // }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const checkViewportSize = () => {
      if (window.innerWidth <= 1199) {
        setIsSidebarOpen(true);
      } else if (window.innerWidth > 1199) {
        setIsSidebarOpen(false);
      }
    };

    checkViewportSize();

    window.addEventListener("resize", checkViewportSize);

    return () => window.removeEventListener("resize", checkViewportSize);
  }, []);

  return (
    <div className="layout">
      <div className="dh-cont fixed-top" id="AdminDashRight">
        <nav className="header_cont navbar navbar-expand navbar-light sticky-top">
          <div className="logo-toggler-section">
            <img
              src={`${imagePath}`}
              onClick={() => navigate("/admin/")}
              alt="logo"
              className="logo-image"
            />
            <button className="sidebar-toggle" onClick={toggleSidebar}>
              <DashboardToogleIcon />
            </button>
          </div>

          <div className="main-section-userAvatar">
            <DashboardBellIcon />
            <NavDropdown
              title={`Hi! ${
                userDetail && userDetail.permissions && userDetail.permissions.length > 0
                  ? "Admin"
                  : "Super Admin"
              }`}
              id="basic-nav-dropdown"
              className="dh-nav-dropdown"
            >
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </div>
        </nav>
      </div>
      <div
        id="AdminDash"
        className={
          isSidebarOpen === true
            ? " dash-main-div dash-main-coll pt-4 mt-5"
            : " dash-main-div"
        }
      >
        <div
          className={`p-0 sidePanel ${
            isSidebarOpen === true
              ? "ds-cont ds-cont-coll margin-left-0"
              : "ds-cont"
          }`}
        >
          <SideMenu />
        </div>
        <div className={`col-md-12`}>{children}</div>
        <div className='ds-footer'><div style={isSidebarOpen === true ? {'marginLeft': '0px'} : {'marginLeft': '300px'}} className="footer-text">{language ? "कॉपीराइट © 2024 उत्तराखंड पर्यटन विकास बोर्ड। - सर्वाधिकार सुरक्षित।" : "Copyright © 2024 Uttarakhand Tourism Development Board. - All rights reserved."}</div></div>
      </div>
    </div>
  );
};

export default AdminDashboard;
