import React, { useEffect, useState } from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { CgPen } from "react-icons/cg";
import { GetLocalStorage, GetLocalStorageParsed } from "../../Utils/Utils";
import { checkApplicationsData, getApplicationForm, getTabs } from "../../services/api/user";
import InputTableData from "../ApplicationDraft/InputTableData";
import DocsUploadDraft from "../ApplicationDraft/DocsUploadDraft";
import DesignationTable from "../ApplicationDraft/DesignationTable";
import { useLocation } from "react-router-dom";

const ApplicationDraft = () => {
  const { language, setLanguage } = useLanguage();
  const [tabsData, setTabsData] = useState();
  const [isEditable, setisEditable] = useState(true);
  const [showEditIcon, setShowEditIcon] = useState(true);
  const loginCatInfo = GetLocalStorageParsed("LoginSubCatData");
  const location = useLocation();
  const userDetail = GetLocalStorageParsed("userDetail");
  const applicationId = GetLocalStorage("application_id");

  useEffect(() => {
    getApplicationData();
    getTabsBySubCategory()
  }, [])

  const getApplicationData = async () => {
    const formData = new FormData();
    formData.append("user_id", userDetail?.id);
    const data = await checkApplicationsData(formData);
    const appStatus = data?.data?.filter((a) => a.id == applicationId)[0]?.status;
    setisEditable(appStatus === "8" ? false : true)
    try {
    } catch (err) {
        console.log(err);
    }
  };

  const getTabsBySubCategory = async () => {
    try {
      const getLoginSubCabData = GetLocalStorageParsed("LoginSubCatData");
      if (getLoginSubCabData) {
        const payload = {
          category_id: getLoginSubCabData?.id
        };
        const response = await getTabs(payload);
        if (response?.status == 200) {
          const filteredTabs = response?.data.filter(
            (i) => i?.tableName !== null
          );
          setTabsData(filteredTabs);
        }
      }
    } catch (error) {
      console.error("Error fetching tabs:", error);
    }
  };

  const editToggle = () => {
    setShowEditIcon(showEditIcon ? false : true)
  }




  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={language ? "आवेदन फार्म" : loginCatInfo?.category_name ? loginCatInfo?.category_name +"/Application Form" :  loginCatInfo?.name +"/Application Form"}
      head={language ? "आवेदन फार्म" : loginCatInfo?.category_name ? loginCatInfo?.category_name +"/Application Form" :  loginCatInfo?.name +"/Application Form"}
    >
      <>
        <div className="">
          <div className="preview-page-main-wrapper">
            <div className="row">
              <div className="col-md-12">
                {tabsData && tabsData.length > 0 ? tabsData?.map((item) => (
                  <div key={item?.id}>
                    <InputTableData tabsData={item} isEditable={isEditable}/>
                  </div>
                )) : null}
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              {tabsData?.some(item => item.name_en == "Additional Details") &&
                <div>
                  <DesignationTable tabsData={tabsData} subCatId={loginCatInfo?.id} isEditable={isEditable} />
                </div>
              }

            </div>

            <div className="col-md-12 mt-4">
              <div className="data-wrapper">
                <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                  <div className="data-wrapper-heading">
                    <h5 className="mb-0">Upload Documents</h5>
                  </div>
                  {isEditable ? <div className="data-wrapper-edit-icon" onClick={editToggle}>
                    {showEditIcon ? <CgPen /> : (
                      <>
                        {/* <div>Save</div> */}
                        <div onClick={editToggle}>Cancel</div>
                      </>
                    )}
                  </div> : <></>}
                </div>
                <div className="row">
                  <div className="mt-4">
                    <DocsUploadDraft subCatId={loginCatInfo?.id} showEditIcon={showEditIcon} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dashboard>
  );
};

export default ApplicationDraft;
