import React, { useEffect, useState } from "react";
import {
  CgAwards,
  CgClipboard,
  CgFileDocument,
  CgMicrosoft,
  CgPen,
  CgTranscript
} from "react-icons/cg";
import "./DasboardSidebar.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../../LanguageProvider/LanguageProvider ";
import { GetLocalStorageParsed } from "../../../Utils/Utils";
import { checkApplicationsData } from "../../../services/api/user";

const DasboardSidebar = () => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const [applicationData, setApplicationData] = useState(null);
  const userDetail = GetLocalStorageParsed("userDetail");
  const getLoginSubCabData = GetLocalStorageParsed("LoginSubCatData");

  const getApplicationData = async () => {
    const formData = new FormData();
    formData.append("user_id", userDetail?.id);
    const data = await checkApplicationsData(formData);
    setApplicationData(data?.data)
    try {
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getApplicationData();
  }, []);

  return (
    <div className="hcat-second-das pb-5">
      <div className="ds-left mt-2">
        <ul className="ds-ul">
          <li onClick={() => navigate("/dashboard")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button
              className={
                pathName === "/dashboard" || pathName === "/guidelines"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              {" "}
              <CgMicrosoft /> <span>{language ? "डैशबोर्ड" : "Dashboard"}</span>
            </button>
          </li>
          <li onClick={() => navigate("/application/overview")}>
            <button
              className={
                pathName?.split("/")?.[1] === "application"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              {" "}
              <CgClipboard />{" "}
              <span>{language ? "आवेदन फार्म" : "Application Form"}</span>
            </button>
          </li>
          {getLoginSubCabData?.category_name || getLoginSubCabData?.name ?
          <li onClick={() => navigate("/application-draft")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button
              className={
                pathName === "/application-draft"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              {" "}
              <CgPen />
              <span>{language ? "आवेदन ड्राफ्ट" : "Application Draft"}</span>
            </button>
          </li>
          : ""}
          {applicationData?.length > 0 ? (
          <li onClick={() => navigate("/applications")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button
              className={
                pathName === "/applications"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              {" "}
              <CgPen />
              <span>{language ? "अनुप्रयोग" : "Applications"}</span>
            </button>
          </li>
          ) : "" }
          <li onClick={() => navigate("/certificate")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button
              className={
                pathName === "/certificate"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              <CgAwards /> <span>{language ? "प्रमाणपत्र" : "Certficate"}</span>
            </button>
          </li>
          <li onClick={() => navigate("/payment-recipt")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button
              className={
                pathName === "/payment-recipt"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              {" "}
              <CgFileDocument />{" "}
              <span>{language ? "भुगतान रसीद" : "Payment Receipt"}</span>
            </button>
          </li>
          <li onClick={() => navigate("/transactions")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button
              className={
                pathName === "/transactions"
                  ? "bg_image_green"
                  : "bg_image_white"
              }
            >
              {" "}
              <CgTranscript />{" "}
              <span>{language ? "लेनदेन" : "Transactions"}</span>
            </button>
          </li>
          {/* <li onClick={() => navigate("/categories")}>
           
            <button
              className={
                pathName === "/categories" ? "bg_image_green" : "bg_image_white"
              }
            >
              {" "}
              <CgLayoutGridSmall />{" "}
              <span>{language ? "श्रेणियाँ" : "Categories"}</span>
            </button>
          </li>
          <li onClick={() => navigate("/forms")}>
          
            <button
              className={
                pathName === "/forms" ? "bg_image_green" : "bg_image_white"
              }
            >
              <CgFileDocument /> <span>{language ? "फार्म" : "Forms"}</span>
            </button>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default DasboardSidebar;
