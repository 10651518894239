import React, { useEffect, useState } from "react";
import "./User.css";
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
import Dashboard from "../../pages/Dashboard/Dashboard";
import { city, district, states } from '../../services/api/auth';
import { useNavigate } from 'react-router-dom';
import { updateUserProfileAPI } from '../../services/api/user';
import { Message } from '../../components/message'
import { GetLocalStorageParsed } from "../../Utils/Utils";

const EditProfile = () => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate()
  // State variables
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [cityName, setCityName] = useState("");
  const [pincode, setPincode] = useState("");
  const [errors, setErrors] = useState({});
  const [statesData, setStatesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [userId, setUserId] = useState("");
  const [userDetail, setUserDetail] = useState("");
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });


  useEffect(() => {
    // Load user details from local storage
    var userDetail = GetLocalStorageParsed("userDetail") || {};
    setUserDetail(userDetail);
    setName(userDetail.name || "");
    setGender(userDetail.gender || "");
    setPhoneNumber(userDetail.mobile_number || "");
    setEmail(userDetail.email || "");
    setAddress(userDetail.address || "");
    setState(userDetail.state || "");
    setDistrictName(userDetail.district || ""); // Ensure this is set correctly
    setCityName(userDetail.city || ""); // Ensure this is set correctly
    setPincode(userDetail.pincode || "");
    setUserId(userDetail.id || "");

    // Load states data
    const loadStates = async () => {
      try {
        const response = await states(); // Fetch states
        const data = response.data.data.map((state) => ({
          label: language ? state.name_hi : state.name_en,
          value: state.id
        }));
        setStatesData(data);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    loadStates();
  }, [language]);

  useEffect(() => {
    // Fetch districts based on selected state
    const loadDistricts = async () => {
      if (state) {
        try {
          const response = await district(state); // Fetch districts for selected state
          const filteredDistricts = response.data.data.filter(district => district.state_id === parseInt(state));
          const data = filteredDistricts.map((district) => ({
            label: language ? district?.name_hi :  district.name_en,
            value: district.id
          }));
          setDistrictsData(data);
          setDistrictName(userDetail.district || ""); // Set the district name from user detail
        } catch (error) {
          console.error("Error fetching districts:", error);
        }
      } else {
        setDistrictsData([]);
        setDistrictName(""); // Clear district and city when state is cleared
        setCitiesData([]);
      }
    };

    loadDistricts();
  }, [state, language]); // Listen for changes to state

  useEffect(() => {
    // Fetch cities based on selected district
    const loadCities = async () => {
      if (districtName) {
        try {
          const response = await city(); // Fetch all cities
          const filteredCities = response.data.data.filter(city => city.district_id === parseInt(districtName));
          const data = filteredCities.map((city) => ({
            label: language ? city.name_hi : city.name_en,
            value: city.id
          }));
          setCitiesData(data);
          setCityName(userDetail.city || ""); // Set the city name from user detail
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      } else {
        setCitiesData([]);
        setCityName("");
      }
    };

    loadCities();
  }, [districtName, language]); // Listen for changes to districtName

  // Validation function
  const validateFields = () => {
    let formErrors = {};
   if (!name) formErrors.name = language ? "नाम आवश्यक है" : "Name is required";
if (!gender) formErrors.gender = language ? "लिंग आवश्यक है" : "Gender is required";
if (!phoneNumber) formErrors.phoneNumber = language ? "फोन नंबर आवश्यक है" : "Phone number is required";
if (!email) formErrors.email = language ? "ईमेल आवश्यक है" : "Email is required";
if (!address) formErrors.address = language ? "पता आवश्यक है" : "Address is required";
if (!state) formErrors.state = language ? "राज्य आवश्यक है" : "State is required";
if (!district) formErrors.district = language ? "जिला आवश्यक है" : "District is required";
if (!cityName) formErrors.city = language ? "शहर आवश्यक है" : "City is required";
if (!pincode || pincode.toString().length != 6) formErrors.pincode = language ? "मान्य 6 अंकों का पिनकोड आवश्यक है" : "Valid 6-digit pincode is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      const payload = {
        name,
        gender,
        phoneNumber,
        email,
        address,
        state,
        city: cityName, // Ensure cityName is used correctly
        district: districtName,
        pincode,
        user_id: userId
      };

      try {
        const response = await updateUserProfileAPI(payload);
        if (response.status === 200) {
          sessionStorage.setItem("userDetail", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("userDetail")), ...payload }));
        } else {
          setApiMessage({ type: 'error', message: "Unauthenticated" })
        }
        setApiMessage({ type: 'success', message: response.message })
      } catch (error) {
        setApiMessage({ type: 'error', message: error.message })
      }
    }
  };

  // Clear handler
  const handleClear = () => {
    setName("");
    setGender("");
    setPhoneNumber("");
    setEmail("");
    setAddress("");
    setState("");
    setDistrictName("");
    setCityName("");
    setPincode("");
    setErrors({});
  };

  const handlechanges = async (e) => {
    const { name, value } = e?.target
    let formErrors = {...errors};
    switch (name) {
      case 'UserName':
        if (!value?.trim()) {
          formErrors.name = language ? "नाम आवश्यक है" : "Name is required";
        } else {
          formErrors.name = ''
        }
        break;
      case 'gender':
        if (!value?.trim()) {
          formErrors.gender = language ? "लिंग आवश्यक है" : "Gender is required";
        } else {
          formErrors.gender = ''
        }
        break;
      case 'address':
        if (!value?.trim()) {
          formErrors.address = language ? "पता आवश्यक है" : "Address is required";
        } else {
          formErrors.address = ''
        }
        break;
      case 'pincode':
        if (!pincode) {
          formErrors.pincode = language ? "मान्य 6 अंकों का पिनकोड आवश्यक है" : "Valid 6-digit pincode is required";
        } else {
          formErrors.pincode = ''
        }
        break;
      case 'state':
        if (!value?.trim()) {
          formErrors.state = language ? "राज्य आवश्यक है" : "State is required";
        } else {
          formErrors.state = ''
        }
        break;
      case 'district':
        if (!value?.trim()) {
          formErrors.district = language ? "जिला आवश्यक है" : "District is required";
        } else {
          formErrors.district = ''
        }
        break;
      case 'city':
        if (!value?.trim()) {
          formErrors.city = language ? "शहर आवश्यक है" : "City is required";
        } else {
          formErrors.city = ''
        }
        break;
    }
    setErrors(formErrors);
  }

  return (
    <div>
      <Dashboard
        first={language ? "होम" : "Home"}
        second={language ? "प्रोफ़ाइल संपादित करें" : "Edit Profile"}
        head={language ? "प्रोफ़ाइल संपादित करें" : "Edit Profile"}
      >
        <div className="aod-bottom">
          {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'नाम ' : 'Name'}</span>
                  </label>
                  <input
                    name="UserName"
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value)
                      handlechanges(e)
                    } }
                  />
                  {errors.name && <span className="text-danger">{language ? "नाम आवश्यक है" : "Name is required"}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'लिंग  ' : 'Gender'}</span>
                  </label>
                  <select
                  name="gender"
                    className="form-control"
                    value={gender}
                    onChange={(e) => { setGender(e.target.value); handlechanges(e) }}
                  >
                    <option value="">{language ? 'लिंग चुनें' : 'Select Gender'}</option>
                    <option value="1">{language ? 'पुरुष' : 'Male'}</option>
                    <option value="2">{language ? 'महिला' : 'Female'}</option>
                    <option value="0">{language ? 'अन्य' : 'Others'}</option>
                  </select>
                  {errors.gender && <span className="text-danger">{language ? "लिंग आवश्यक है" : "Gender is required"}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'मोबाइल नंबर' : 'Mobile Number'}</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    disabled
                  />
                  {errors.phoneNumber && <span className="text-danger">{errors.phoneNumber}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'ईमेल' : 'Email'}</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    value={email}
                    disabled
                  />
                  {errors.email && <span className="text-danger">{errors.email}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'पता' : 'Address'}</span>
                  </label>
                  <input
                  name = "address"
                    type="text"
                    className="form-control"
                    placeholder="Enter Address"
                    value={address}
                    onChange={(e) => {setAddress(e.target.value);  handlechanges(e)}}
                  />
                  {errors.address && <span className="text-danger">{language ? "पता आवश्यक है" : "Address is required"}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'राज्य' : 'State'}</span>
                  </label>
                  <select
                  name='state'
                    className="form-control"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                      setDistrictName(""); // Reset district and city when state changes
                      setCityName("");
                      handlechanges(e)
                    }}
                  >
                    <option value="">{language ? 'राज्य चुनें' : 'Select State'}</option>
                    {statesData.map((state) => (
                      <option key={state.value} value={state.value}>{state.label}</option>
                    ))}
                  </select>
                  {errors.state && <span className="text-danger">{language ? "राज्य आवश्यक है" : "State is required"}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'ज़िला' : 'District'}</span>
                  </label>
                  <select
                    name='district'
                    className="form-control"
                    value={districtName}
                    onChange={(e) => {
                      setDistrictName(e.target.value);
                      handlechanges(e)
                      setCityName(""); // Reset city when district changes
                    }}
                  >
                    <option value="">{language ? 'ज़िला चुनें' : 'Select District'}</option>
                    {districtsData.map((district) => (
                      <option key={district.value} value={district.value}>{district.label}</option>
                    ))}
                  </select>
                  {errors.district && <span className="text-danger">{language ? "जिला आवश्यक है" : "District is required"}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'शहर' : 'City'}</span>
                  </label>
                  <select
                    name='city'
                    className="form-control"
                    value={cityName}
                    onChange={(e) => {setCityName(e.target.value); handlechanges(e)}}
                  >
                    <option value="">{language ? 'शहर चुनें' : 'Select City'}</option>
                    {citiesData.map((city) => (
                      <option key={city.value} value={city.value}>{city.label}</option>
                    ))}
                  </select>
                  {errors.city && <span className="text-danger">{language ? "शहर आवश्यक है" : "City is required"}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields">
                  <label className="form-label">
                    <span className="en-label">{language ? 'पिनकोड' : 'Pincode'}</span>
                  </label>
                  <input
                    type="text"
                    name="pincode"
                    maxLength={6}
                    className="form-control"
                    placeholder={language ? 'पिनकोड दर्ज करें' : 'Enter Pincode'}
                    value={pincode}
                    onChange={(e) => {
                      const value = e.target.value;
                      const numericRegex = /^[0-9]*$/;
                      if (numericRegex.test(value)) {
                        setPincode(value);
                        handlechanges(e);
                      } 
                      handlechanges(e)
                    }}
                  />
                  {errors.pincode && <span className="text-danger">{language ? "मान्य 6 अंकों का पिनकोड आवश्यक है" : "Valid 6-digit pincode is required" }</span>}
                </div>
              </div>
            </div>
            <div className="btn-group">
              <button type="submit" className="btn btn-primary user-theme-btn mt-3">{language ? 'जमा करना' : 'Submit'} </button>
            </div>
          </form>
        </div>
      </Dashboard>
    </div>
  );
};

export default EditProfile;