import React from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { Baseurl, GetLocalStorage } from "../../Utils/Utils";
import ComingSoon from "../ComingSoon/ComingSoon";

const Certificate = () => {
  const { language, setLanguage } = useLanguage();
  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={language ? "प्रमाणपत्र" : "Certificate"}
      head={language ? "प्रमाणपत्र" : "Certificate"}
    >
      <>
        <div className="aod-bottom">
          <div className="page_heading">
            <h5>{language ? "प्रमाणपत्र" : "Certificate"}</h5>
          </div>
          <div className="row">
            <div className="reciept-table table-responsive mt-2">
              <table
                className="table table mb-0 dataTable transaction_table"
                id="receipt_table"
                role="grid"
              >
                <thead className="">
                  <tr role="row">
                    <th>{language ? "क्रम संख्या" : "S.No"}</th>
                    <th>{language ? "सर्टिफिकेट नंबर" : "Certificate Number"}</th>
                    <th>{language ? "तारीख" : "Date"}</th>
                    <th>{language ? "देखना" : "View"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row">
                    <td>1</td>
                    <td>chamoli/09-2024/169918</td>
                    <td>16-09-2024 11:25:05 PM</td>
                    <td>
                      <a
                        href={Baseurl()+'/api/category-certificate'}
                        className="btn btn-green-view"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {language ? "डाउनलोड करना" : "Download"}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    </Dashboard>
  );
};

export default Certificate;
