import React, { useEffect, useState } from "react";
import { CgPen } from "react-icons/cg";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import { GetLocalStorage, GetLocalStorageParsed } from "../../Utils/Utils";
import {
  createApplicationForm,
  getApplicationForm,
} from "../../services/api/user";

const DesignationTable = ({ tabsData, subCatId, isEditable }) => {
  const { language } = useLanguage();
  const userDetail = GetLocalStorageParsed("userDetail");
  const applicationId = GetLocalStorage("application_id");
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [tabsWholeData, setTabsWholeData] = useState([]);
  const [fieldData, setFieldData] = useState([]);

  useEffect(() => {
    fetchApplicationFormData();
  }, [showEditIcon]);

    const fetchApplicationFormData = async () => {
        try {
            const formData = new FormData();
            formData.append("sub_category_id", subCatId?.subCatId || subCatId);
            formData.append("application_detail_id", applicationId);
            const applicationFormResponse = await getApplicationForm(formData);
            if (applicationFormResponse.status === 200) {
                const designationEntry = Object.entries(applicationFormResponse?.data || {}).find(
                    ([key]) => key.startsWith("f_designation")
                );
                setTabsWholeData((prevdata) => ({
                    ...prevdata,
                    ...applicationFormResponse?.data,
                }));
                const newRow = {
                    'Name of GM/Manager': '',
                    'Mobile Number of GM/Manager': '',
                    'Email of GM/Manager': '',
                    'State of GM/Manager': '',
                    'District of GM/Manager': ''
                };

                const designationValue = designationEntry ? JSON.parse(designationEntry[1]) : [];

                Object.keys(designationValue[0]).forEach((designation) => {
                    // If 'Employee Details' does not exist, initialize it with newRow
                    if (!designationValue[0][designation]['Employee Details']) {
                        designationValue[0][designation]['Employee Details'] = [newRow];
                    } else {
                        // If 'Employee Details' exists, check each entry for missing keys and add them
                        designationValue[0][designation]['Employee Details'] = designationValue[0][designation]['Employee Details'].map((employee) => {
                            return {
                                ...newRow,
                                ...employee // Spread the existing employee details to override newRow defaults where present
                            };
                        });
                    }
                });
                setFieldData(designationValue);
            }
        } catch (err) {
            console.error("Error fetching application data:", err);
        }
    };

  const editToggle = async () => {
    setShowEditIcon(!showEditIcon);
    await fetchApplicationFormData();
  };

  const handleSubmit = async () => {
    try {
      const updatedTabsWholeData = { ...tabsWholeData };
      const designationKey = Object.keys(updatedTabsWholeData).find((key) =>
        key.startsWith("f_designation")
      );
      if (designationKey) {
        updatedTabsWholeData[designationKey] = JSON.stringify(fieldData);
      }
      const payload = {
        sub_category_id: tabsData?.[0]?.category_id,
        user_id: userDetail?.id,
        application_id: applicationId,
        section_fields: updatedTabsWholeData,
      };

      const sectionResponse = await createApplicationForm(payload);
      if (sectionResponse.status === 200) {
        console.log("Data saved successfully");
        setTabsWholeData(updatedTabsWholeData);
      }
    } catch (err) {
      console.error("Error submitting form data:", err);
    }
  };

  const updateEmployeeDetails = (designation, index, key, value) => {
    const updatedFieldData = [...fieldData];
    updatedFieldData[0][designation]["Employee Details"][index][key] = value;
    setFieldData(updatedFieldData);
    updateNoOfPeople(
      designation,
      updatedFieldData?.[0]?.[designation]?.["Employee Details"]
    );
  };

  // This function will update 'No. of People' when the number of rows in 'Employee Details' changes.
  const updateNoOfPeople = (designation, employeeDetails) => {
    const noOfPeople = employeeDetails.length;
    setFieldData((prevFieldData) => {
      const updatedFieldData = [...prevFieldData];
      updatedFieldData[0][designation]["No. of People"] = noOfPeople;
      return updatedFieldData;
    });
  };

  const addRow = (designation) => {
    const updatedFieldData = [...fieldData];
    const newRow = {
      "Name of GM/Manager": "",
      "Mobile Number of GM/Manager": "",
      "Email of GM/Manager": "",
      "State of GM/Manager": "",
      "District of GM/Manager": "",
    };
    updatedFieldData[0][designation]["Employee Details"].push(newRow);
    setFieldData(updatedFieldData);
    updateNoOfPeople(
      designation,
      updatedFieldData?.[0]?.[designation]?.["Employee Details"]
    );
  };

  const removeRow = (designation, index) => {
    const updatedFieldData = [...fieldData];
      if(updatedFieldData[0][designation]["Employee Details"].length > 1){
          updatedFieldData[0][designation]["Employee Details"].splice(index, 1);
          setFieldData(updatedFieldData);
          updateNoOfPeople(
              designation,
              updatedFieldData?.[0]?.[designation]?.["Employee Details"]
          );
      }else{
      }  
  };

  return (
    <div className="card mb-3">
      <div className="card-body bg-card user-tab-table">
        <div className="form-bar d-flex justify-content-between align-items-center p-3">
          <div className="bar-left-detail">
            <h5 className="p-0 text-dark mb-0">Employee’s Details</h5>
          </div>
          {isEditable ? <div className="data-wrapper-edit-icon" onClick={editToggle}>
            {showEditIcon ? (
              <CgPen />
            ) : (
              <>
                <div onClick={handleSubmit}>Save</div>
                <div onClick={editToggle}>Cancel</div>
              </>
            )}
          </div> : <></>}
        </div>

        {/* Render data for each level in fieldData */}
        {fieldData &&
          fieldData.length > 0 &&
          Object.entries(fieldData?.[0])?.map(([designation, details]) => (
            <div key={designation} className="designation-section">
              <h5 className="designation-title">{designation}</h5>

              <div className="row mb-3">
                {/* Render No. of People */}
                <div className="col-md-6 d-flex align-items-center">
                  <div className="preview-left-heading me-3">
                    <h6 className=" mb-0">No. of People:</h6>
                  </div>
                  {!showEditIcon ? (
                    <input
                      type="number"
                      name="No. of People"
                      className="form-control w-auto"
                      style={{ maxWidth: "150px" }}
                      value={parseInt(details["No. of People"], 10) || ""}
                      onChange={(e) => {
                        const updatedFieldData = [...fieldData];
                        updatedFieldData[0][designation]["No. of People"] =
                          e.target.value;
                        // Add rows dynamically based on the No. of People value
                        const currentRowCount =
                          details["Employee Details"].length;
                        const targetRowCount = parseInt(e.target.value, 10);
                        if (targetRowCount > currentRowCount) {
                          // Add rows if needed
                          const rowsToAdd = targetRowCount - currentRowCount;
                          for (let i = 0; i < rowsToAdd; i++) {
                            addRow(designation);
                          }
                        } else if (targetRowCount < currentRowCount) {
                          // Remove rows if needed
                          const rowsToRemove = currentRowCount - targetRowCount;
                          for (let i = 0; i < rowsToRemove; i++) {
                            removeRow(designation, currentRowCount - 1 - i);
                          }
                        }
                        setFieldData(updatedFieldData);
                      }}
                    />
                  ) : (
                    <h5 className="no-count mb-0">
                      {parseInt(details["No. of People"], 10) || ""}
                    </h5>
                  )}
                </div>

                {/* Render Average Experience */}
                <div className="col-md-6 d-flex align-items-center">
                  <div className="preview-left-heading me-3">
                    <h6 className=" mb-0">Average Experience</h6>
                  </div>
                  {!showEditIcon ? (
                    <input
                      type="text"
                      name="Average Experience"
                      className="form-control"
                      value={parseInt(details["Average Experience"], 10) || ""}
                      onChange={(e) => {
                        const updatedFieldData = [...fieldData];
                        updatedFieldData[0][designation]["Average Experience"] =
                          e.target.value;
                        setFieldData(updatedFieldData);
                      }}
                    />
                  ) : (
                    <h5 className="no-count mb-0">
                      {parseInt(details["Average Experience"], 10) || ""}
                    </h5>
                  )}
                </div>
              </div>

              {/* Render Employee Details Table */}
              <div className="table-responsive">
                <table className="table bg-table mb-0">
                  {details?.["Employee Details"] && (
                    <>
                      <thead>
                        <tr>
                          {Object.keys(details?.["Employee Details"]?.[0]).map(
                            (header) => (
                              <th scope="col" key={header}>
                                {header}
                              </th>
                            )
                          )}
                          {!showEditIcon ? <th scope="col">Actions</th> : null}
                        </tr>
                      </thead>
                      <tbody>
                        {details["Employee Details"].map((employee, index) => (
                          <tr key={index}>
                            {Object.entries(employee).map(
                              ([key, value], idx) => (
                                <td key={idx}>
                                  {showEditIcon ? (
                                    <span>{value}</span>
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={value}
                                      onChange={(e) => {
                                        updateEmployeeDetails(
                                          designation,
                                          index,
                                          key,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  )}
                                </td>
                              )
                            )}
                            {!showEditIcon && (
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => removeRow(designation, index)}
                                            // disabled={designation["Employee Details"]?.length < 1}
                                >
                                  Remove Row
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                </table>
              </div>

              {/* Add Row Button */}
              {!showEditIcon && (
                <div className="mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => addRow(designation)}
                  >
                    Add Row
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default DesignationTable;
