import React, { useEffect, useState } from 'react'
import { dropDownsList, staticDropDownsList } from '../../services/api/user';
import Select from 'react-select'; // Ensure you have installed react-select
import { useLanguage } from '../LanguageProvider/LanguageProvider ';

const SelectTypeDraft = ({ fieldKey, styles, theme, fieldValue, placeholder, setTabsWholeData, dropdownId, showEditIcon }) => {
    const { language } = useLanguage();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const formData = new FormData();
                formData.append("dropdown_id", dropdownId);
                const response = await dropDownsList(formData);
                const ParsedDropdownValue = JSON.parse(response?.data?.dropdown_values);
                const formattedOptions = ParsedDropdownValue?.items?.map((option, index) => ({
                    value: String(option?.id ? option?.id : `${index + 1}`), // Concatenate id with index
                    label: language ? option.name_hi : option.name_en
                }));
                setOptions(formattedOptions);

                if (!response?.data?.dropdown_values || response?.data?.dropdown_values == "null") {
                    const staticFormData = new FormData();
                    staticFormData.append("title", response?.data?.name_en.toLowerCase());
                    const staticList = await staticDropDownsList(staticFormData);
                    const formattedStaticOptions = staticList?.data?.map(option => ({
                        value: String(option.id),
                        label: language ? option.name_hi : option.name_en
                    }));
                    setOptions(formattedStaticOptions);
                }
            } catch (error) {
                //console.error("Error fetching dropdown options:", error);
            }
        };

        fetchOptions();
    }, [dropdownId,language]);
    return (
        <>
            {!showEditIcon ? <Select
                components={{
                    IndicatorSeparator: () => null,
                }}
                name={fieldKey}
                styles={styles}
                theme={theme}
                options={options}
                placeholder={placeholder}
                value={options?.find(option => option?.value == fieldValue)}
                onChange={(selected) => {
                    setTabsWholeData((prevdata) => ({
                        ...prevdata,
                        [fieldKey]: selected.value
                    }))
                }
                }
            /> :
                <p> {options?.find(option => option?.value == fieldValue)?.label}</p>
            }

        </>
    )
}

export default SelectTypeDraft