import { React, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import { Message } from '../../../components/message';
import { getDisclaimerDetails , updateDisclaimerDetails } from '../../../services/api/admin';
import AdminDashboard from '../AdminDashboard/AdminDashboard';


const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ],
};

const formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

const EditDisclaimer = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [Disclaimer_en, setDisclaimer_en] = useState('');
  const [Disclaimer_hi, setDisclaimer_hi] = useState('');
  const [mandatoryDisclaimerEnErr, setMandatoryDisclaimerEnErr] = useState('')
  const [mandatoryDisclaimerHiErr, setMandatoryDisclaimerHiErr] = useState('')

  useEffect(() => {
    getDisclaimer();
  }, []);

  const getDisclaimer = async () => {
    try {
      const formData = new FormData();
      formData.append("page_id", 3);
      const usersListData = await getDisclaimerDetails(formData);
      if (usersListData.status === 200) {
        setUserList(usersListData?.data || []);
        setTableDataLoaded(true);
        setDisclaimer_en(usersListData?.data?.content_en)
        setDisclaimer_hi(usersListData?.data?.content_hi)
      }
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    getDisclaimer();
  };

  const handleEditorChange = (field, value) => {
        // Update content
        if (field === 'Disclaimer_en') setDisclaimer_en(value);
        if (field === 'Disclaimer_hi') setDisclaimer_hi(value);
    };

    const updateFormField = async (item) => {
        try {
            setMandatoryDisclaimerEnErr('');
            setMandatoryDisclaimerHiErr('');
      
            if (!Disclaimer_en.trim() || Disclaimer_en === "<p><br></p>") {
                setMandatoryDisclaimerEnErr('Disclaimer in English cannot be empty');
            }
            if (!Disclaimer_hi.trim() || Disclaimer_hi === "<p><br></p>") {
                setMandatoryDisclaimerHiErr('Disclaimer in Hindi cannot be empty');
            }
      
            if (!Disclaimer_en.trim() || !Disclaimer_hi.trim() || Disclaimer_hi === "<p><br></p>"  || Disclaimer_en === "<p><br></p>") {
                return;
            }
            const formData = new FormData();
            formData.append("term_condition_id", 3)
            formData.append("content_en", Disclaimer_en);
            formData.append("content_hi", Disclaimer_hi);
            const updateDisclaimer = await updateDisclaimerDetails(formData)
            if (updateDisclaimer && updateDisclaimer.status == 200) {
                setApiMessage({ type: 'success', message: updateDisclaimer.message });
                getDisclaimer();
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    };


  return (
    <AdminDashboard>
      <div className="aod-outer">
      {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Disclaimer</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Disclaimer</h4>
            </div>
            <div className="aod-head"></div>
            <div className='suf-input-box aod-form-input mb-5'>
              <label className='lf-label w-100'>Disclaimer English</label>
              <ReactQuill
                name="Disclaimer_en"
                value={Disclaimer_en}
                onChange={(value) => handleEditorChange('Disclaimer_en', value)}
                modules={modules}
                formats={formats}
                theme="snow"
              />
              {mandatoryDisclaimerEnErr && <p className='validate-error'>{mandatoryDisclaimerEnErr}</p>}
            </div>
            <div className='suf-input-box aod-form-input mb-5'>
              <label className='lf-label w-100'>Disclaimer Hindi</label>
              <ReactQuill
                name="Disclaimer_hi"
                value={Disclaimer_hi}
                onChange={(value) => handleEditorChange('Disclaimer_hi', value)}
                modules={modules}
                formats={formats}
                theme="snow"
              />
              {mandatoryDisclaimerHiErr && <p className='validate-error'>{mandatoryDisclaimerHiErr}</p>}
            </div>
          </div>
        </div>
        <div className="row ad-cat-row">
                <div className="col-md-12 mt-4 gap-4 d-flex">
                      <button onClick={() => updateFormField()}>
                        Update
                      </button>
                </div>
              </div>
      </div>
    </AdminDashboard>
  );
};

export default EditDisclaimer;
