import React from "react";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import datacomingsoonicon from "../../../src/assets/images/data-coming-soon.png";

const ComingSoon = () => {
  const { language, setLanguage } = useLanguage();
  return (
    <div className="aod-bottom">
      <div className="coming-soon-inner">
        <div className="coming-soon-img">
          {" "}
          <img className="" src={datacomingsoonicon} />
        </div>
        <div className="Coming-soon-text">
          <h5>Coming Soon!</h5>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
