import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
  faTags,
  faTag,
  faBookOpen,
  faQuestionCircle,
  faClipboardList,
  faTable,
  faThList,
  faList,
  faFileAlt,
  faCaretSquareDown,
  faUserTag,
  faFileSignature,
  faClipboardCheck,
  faHourglass,
  faCheck,
  faTimes,
  faSyncAlt,
  faEdit,
  faAward,
  faFileContract,
  faPlayCircle,
  faExclamationCircle,
  faArchive,
  faSignInAlt,
  faKey,
  faUnlockAlt,
  faUserPlus,
  faUsersCog,
  faIdBadge,
  faShieldAlt,
  faUserTie,
  faWrench,
  faMapMarkerAlt,
  faMapSigns,
  faCity,
  faMobileAlt,
  faEnvelopeOpenText,
  faTachometerAlt,
  faFile,
  faMapMarkedAlt,
  faMapPin,
  faBookmark,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const SideMenu = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();

  const iconsMap = {
    faLayerGroup,
    faTags,
    faTag,
    faBookOpen,
    faQuestionCircle,
    faClipboardList,
    faTable,
    faThList,
    faList,
    faFileAlt,
    faCaretSquareDown,
    faUserTag,
    faFileSignature,
    faClipboardCheck,
    faHourglass,
    faCheck,
    faTimes,
    faSyncAlt,
    faEdit,
    faAward,
    faFileContract,
    faPlayCircle,
    faExclamationCircle,
    faArchive,
    faSignInAlt,
    faKey,
    faUnlockAlt,
    faUserPlus,
    faUsersCog,
    faIdBadge,
    faShieldAlt,
    faUserTie,
    faWrench,
    faMapMarkerAlt,
    faMapSigns,
    faCity,
    faMobileAlt,
    faEnvelopeOpenText,
    faTachometerAlt,
    faFile,
    faMapMarkedAlt,
    faMapPin,
    faBookmark,
    faCreditCard,
  };

  // Simplified permission checks using the permissions config
  const grantPermissionForViewCategories = checkPermission(
    permissionsConfig.viewCategories.role,
    permissionsConfig.viewCategories.action,
    permissionsConfig.viewCategories.type
  );
  const grantPermissionForViewSubCategories = checkPermission(
    permissionsConfig.viewSubCategories.role,
    permissionsConfig.viewSubCategories.action,
    permissionsConfig.viewSubCategories.type
  );
  const grantPermissionForViewGuidelines = checkPermission(
    permissionsConfig.viewGuidelines.role,
    permissionsConfig.viewGuidelines.action,
    permissionsConfig.viewGuidelines.type
  );
  const grantPermissionForViewCategoryFAQList = checkPermission(
    permissionsConfig.viewCategoryFAQList.role,
    permissionsConfig.viewCategoryFAQList.action,
    permissionsConfig.viewCategoryFAQList.type
  );
  const grantPermissionForViewDocumentList = checkPermission(
    permissionsConfig.viewDocumentList.role,
    permissionsConfig.viewDocumentList.action,
    permissionsConfig.viewDocumentList.type
  );

  const grantPermissionForViewFormTabs = checkPermission(
    permissionsConfig.viewFormTabs.role,
    permissionsConfig.viewFormTabs.action,
    permissionsConfig.viewFormTabs.type
  );
  const grantPermissionForViewFormSection = checkPermission(
    permissionsConfig.viewFormSection.role,
    permissionsConfig.viewFormSection.action,
    permissionsConfig.viewFormSection.type
  );
  const grantPermissionForViewFormSubSection = checkPermission(
    permissionsConfig.viewFormSubSection.role,
    permissionsConfig.viewFormSubSection.action,
    permissionsConfig.viewFormSubSection.type
  );
  const grantPermissionForViewFormFields = checkPermission(
    permissionsConfig.viewFormFields.role,
    permissionsConfig.viewFormFields.action,
    permissionsConfig.viewFormFields.type
  );
  const grantPermissionForViewFormDropdowns = checkPermission(
    permissionsConfig.viewFormDropdowns.role,
    permissionsConfig.viewFormDropdowns.action,
    permissionsConfig.viewFormDropdowns.type
  );
  const grantPermissionForViewEmployeeDesignation = checkPermission(
    permissionsConfig.viewEmployeeDesignation.role,
    permissionsConfig.viewEmployeeDesignation.action,
    permissionsConfig.viewEmployeeDesignation.type
  );

  const grantPermissionForTotalApplications = checkPermission(
    permissionsConfig.totalApplications.role,
    permissionsConfig.totalApplications.action,
    permissionsConfig.totalApplications.type
  );
  const grantPermissionForViewPending = checkPermission(
    permissionsConfig.viewPending.role,
    permissionsConfig.viewPending.action,
    permissionsConfig.viewPending.type
  );
  const grantPermissionForViewApproved = checkPermission(
    permissionsConfig.viewApproved.role,
    permissionsConfig.viewApproved.action,
    permissionsConfig.viewApproved.type
  );
  const grantPermissionForViewRejected = checkPermission(
    permissionsConfig.viewRejected.role,
    permissionsConfig.viewRejected.action,
    permissionsConfig.viewRejected.type
  );

  const grantPermissionForCertificates = checkPermission(
    permissionsConfig.certificates.role,
    permissionsConfig.certificates.action,
    permissionsConfig.certificates.type
  );
  const grantPermissionForExpired = checkPermission(
    permissionsConfig.expired.role,
    permissionsConfig.expired.action,
    permissionsConfig.expired.type
  );

  const grantPermissionForViewLogin = checkPermission(
    permissionsConfig.viewLogin.role,
    permissionsConfig.viewLogin.action,
    permissionsConfig.viewLogin.type
  );
  const grantPermissionForViewResetPassword = checkPermission(
    permissionsConfig.viewResetPassword.role,
    permissionsConfig.viewResetPassword.action,
    permissionsConfig.viewResetPassword.type
  );
  const grantPermissionForViewForgotPassword = checkPermission(
    permissionsConfig.viewForgotPassword.role,
    permissionsConfig.viewForgotPassword.action,
    permissionsConfig.viewForgotPassword.type
  );
  const grantPermissionForViewRegistration = checkPermission(
    permissionsConfig.viewRegistration.role,
    permissionsConfig.viewRegistration.action,
    permissionsConfig.viewRegistration.type
  );

  const grantPermissionForRolesAndPermissions = checkPermission(
    permissionsConfig.rolesAndPermissions.role,
    permissionsConfig.rolesAndPermissions.action,
    permissionsConfig.rolesAndPermissions.type
  );
  const grantPermissionForViewAdminUser = checkPermission(
    permissionsConfig.viewAdminUser.role,
    permissionsConfig.viewAdminUser.action,
    permissionsConfig.viewAdminUser.type
  );

  const grantPermissionForViewManageStates = checkPermission(
    permissionsConfig.manageStates.role,
    permissionsConfig.manageStates.action,
    permissionsConfig.manageStates.type
  );
  const grantPermissionForViewManageDistricts = checkPermission(
    permissionsConfig.manageDistricts.role,
    permissionsConfig.manageDistricts.action,
    permissionsConfig.manageDistricts.type
  );
  const grantPermissionForViewManageCities = checkPermission(
    permissionsConfig.manageCities.role,
    permissionsConfig.manageCities.action,
    permissionsConfig.manageCities.type
  );
  const grantPermissionForViewSMSSetting = checkPermission(
    permissionsConfig.viewSMSSetting.role,
    permissionsConfig.viewSMSSetting.action,
    permissionsConfig.viewSMSSetting.type
  );
  const grantPermissionForViewEmailSetting = checkPermission(
    permissionsConfig.viewEmailSetting.role,
    permissionsConfig.viewEmailSetting.action,
    permissionsConfig.viewEmailSetting.type
  );

  const menuPages = {
    menus: [
      // Category Management
      grantPermissionForViewCategories ||
      grantPermissionForViewSubCategories ||
      grantPermissionForViewGuidelines ||
      grantPermissionForViewCategoryFAQList ||
      grantPermissionForViewDocumentList
        ? {
            name: "Travel Trade Users",
            icon: "faLayerGroup",
            submenus: [
              grantPermissionForViewCategories
                ? { name: "Users List", path: "/admin/user", icon: "faTags" }
                : undefined,
              grantPermissionForViewSubCategories
                ? {
                    name: "Update Details Requests",
                    path: "/admin/request/mobile-email",
                    icon: "faEnvelopeOpenText",
                  }
                : undefined,
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      grantPermissionForViewCategories ||
      grantPermissionForViewSubCategories ||
      grantPermissionForViewGuidelines ||
      grantPermissionForViewCategoryFAQList ||
      grantPermissionForViewDocumentList
        ? {
            name: "Category Management",
            icon: "faLayerGroup",
            submenus: [
              grantPermissionForViewCategories
                ? {
                    name: "Categories",
                    path: "/admin/categories",
                    icon: "faTags",
                  }
                : undefined,
              grantPermissionForViewSubCategories
                ? {
                    name: "Sub Categories",
                    path: "/admin/sub-categories",
                    icon: "faTag",
                  }
                : undefined,
              grantPermissionForViewGuidelines
                ? {
                    name: "Guidelines",
                    path: "/admin/guidelines",
                    icon: "faBookOpen",
                  }
                : undefined,
              grantPermissionForViewCategoryFAQList
                ? {
                    name: "FAQs",
                    path: "/admin/faqs",
                    icon: "faQuestionCircle",
                  }
                : undefined,
              grantPermissionForViewDocumentList
                ? {
                    name: "Documents",
                    path: "/admin/category/documents",
                    icon: "faBookmark",
                  }
                : undefined,
              grantPermissionForViewDocumentList
                ? {
                    name: "Fees",
                    path: "/admin/category/fees",
                    icon: "faCreditCard",
                  }
                : undefined,
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      // Form Management
      grantPermissionForViewFormTabs ||
      grantPermissionForViewFormSection ||
      grantPermissionForViewFormSubSection ||
      grantPermissionForViewFormFields ||
      grantPermissionForViewFormDropdowns ||
      grantPermissionForViewEmployeeDesignation
        ? {
            name: "Form Management",
            icon: "faClipboardList",
            submenus: [
              grantPermissionForViewFormTabs
                ? {
                    name: "Form Tabs",
                    path: "/admin/form/tabs",
                    icon: "faTable",
                  }
                : undefined,
              grantPermissionForViewFormSection
                ? {
                    name: "Form Sections",
                    path: "/admin/form/sections",
                    icon: "faThList",
                  }
                : undefined,
              grantPermissionForViewFormSubSection
                ? {
                    name: "Form Sub Sections",
                    path: "/admin/form/sub-sections",
                    icon: "faList",
                  }
                : undefined,
              grantPermissionForViewFormFields
                ? {
                    name: "Form Fields",
                    path: "/admin/form/fields",
                    icon: "faFileAlt",
                  }
                : undefined,
              grantPermissionForViewFormDropdowns
                ? {
                    name: "Form Dropdowns",
                    path: "/admin/form/dropdowns",
                    icon: "faCaretSquareDown",
                  }
                : undefined,
              grantPermissionForViewEmployeeDesignation
                ? {
                    name: "Employee Designations",
                    path: "/admin/employee-designations",
                    icon: "faUserTag",
                  }
                : undefined,
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      // Application Management
      {
        name: "Application Management",
        icon: "faFileSignature",
        submenus: [
          grantPermissionForTotalApplications
            ? {
                name: "Total applications",
                path: "/admin/total-applications",
                icon: "faClipboardCheck",
              }
            : undefined,
          grantPermissionForViewPending
            ? {
                name: "Pending",
                path: "/admin/pending-applications",
                icon: "faHourglass",
              }
            : undefined,
          grantPermissionForViewApproved
            ? {
                name: "Approved",
                path: "/admin/approved-applications",
                icon: "faCheck",
              }
            : undefined,
          grantPermissionForViewRejected
            ? {
                name: "Rejected",
                path: "/admin/rejected-applications",
                icon: "faTimes",
              }
            : undefined,
          {
            name: "Renewal",
            path: "/admin/renewal-applications",
            icon: "faSyncAlt",
          },
          {
            name: "Update Application requests",
            path: "/admin/update-app-requests",
            icon: "faEdit",
          },
        ].filter(Boolean), // This will filter out any undefined values
      },

      // Certificate Management
      grantPermissionForCertificates || grantPermissionForExpired
        ? {
            name: "Certificate Management",
            icon: "faAward",
            submenus: [
              grantPermissionForCertificates
                ? {
                    name: "Certificates",
                    path: "/admin/certificates",
                    icon: "faFileContract",
                  }
                : undefined,
              {
                name: "Active",
                path: "/admin/active-certificates",
                icon: "faPlayCircle",
              },
              grantPermissionForExpired
                ? {
                    name: "Expired",
                    path: "/admin/expired-certificates",
                    icon: "faExclamationCircle",
                  }
                : undefined,
              { name: "Logs", path: "", icon: "faArchive" },
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      // Pages
      grantPermissionForViewLogin ||
      grantPermissionForViewResetPassword ||
      grantPermissionForViewForgotPassword ||
      grantPermissionForViewRegistration
        ? {
            name: "Page Management",
            icon: "faFile",
            submenus: [
              grantPermissionForViewLogin
                ? { name: "Login", path: "/admin/login", icon: "faSignInAlt" }
                : undefined,
              grantPermissionForViewResetPassword
                ? {
                    name: "Reset Password",
                    path: "/admin/reset-password",
                    icon: "faKey",
                  }
                : undefined,
              grantPermissionForViewForgotPassword
                ? {
                    name: "Forgot Password",
                    path: "/admin/forgot-password",
                    icon: "faUnlockAlt",
                  }
                : undefined,
              grantPermissionForViewRegistration
                ? {
                    name: "Registration",
                    path: "/admin/registration",
                    icon: "faUserPlus",
                  }
                : undefined,
              { name: "Banner", path: "/admin/banner-page", icon: "faTags" },
              {
                name: "Banner Links",
                path: "/admin/banner-link",
                icon: "faTags",
              },
              //  { name: "Header Menu", path: "/admin/homepage-header", icon: "faTags" },
              {
                name: "Social Media Links",
                path: "/admin/social-media-links/update",
                icon: "faTags",
              },
              {
                name: "Website Logo",
                path: "/admin/homepage-logo",
                icon: "faTags",
              },
              {
                name: "Niyamvali",
                path: "/admin/niyamvali/update",
                icon: "faTags",
              },
              {
                name: "Privacy Policy",
                path: "/admin/privacy-policy/update",
                icon: "faTags",
              },
              {
                name: "Terms and Conditions",
                path: "/admin/terms-conditions/update",
                icon: "faTags",
              },
              {
                name: "Disclaimer",
                path: "/admin/disclaimer/update",
                icon: "faTags",
              },
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      // Admin Management
      grantPermissionForRolesAndPermissions || grantPermissionForViewAdminUser
        ? {
            name: "Admin Management",
            icon: "faUsersCog",
            submenus: [
              grantPermissionForRolesAndPermissions
                ? {
                    name: "Roles and Permissions",
                    path: "/admin/roles",
                    icon: "faIdBadge",
                  }
                : undefined,
              grantPermissionForViewAdminUser
                ? {
                    name: "Admin User",
                    path: "/admin/admin-users",
                    icon: "faUserTie",
                  }
                : undefined,
            ].filter(Boolean), // This will filter out any undefined values
          }
        : null,

      // Settings Module
      (grantPermissionForViewManageStates ||
        grantPermissionForViewManageDistricts ||
        grantPermissionForViewManageCities ||
        grantPermissionForViewSMSSetting ||
        grantPermissionForViewEmailSetting) ? {
        name: "Settings Module",
        icon: "faWrench",
        submenus: [
          grantPermissionForViewManageStates ? { name: "Manage States", path: "/admin/states", icon: "faMapMarkedAlt" } : undefined,
          grantPermissionForViewManageDistricts ? { name: "Manage Districts", path: "/admin/districts", icon: "faMapPin" } : undefined,
          grantPermissionForViewManageCities ? { name: "Manage Cities", path: "/admin/cities", icon: "faCity" } : undefined,
          grantPermissionForViewSMSSetting ? { name: "SMS - Settings", path: "/admin/sms-setting", icon: "faMobileAlt" } : undefined,
          grantPermissionForViewEmailSetting ? { name: "Email - Settings", path: "/admin/email-setting", icon: "faEnvelopeOpenText" } : undefined,
          grantPermissionForViewEmailSetting ? { name: "Payment - Settings", path: "/admin/payment/tab", icon: "faEnvelopeOpenText" } : undefined,
        ].filter(Boolean) // This will filter out any undefined values
      } : null,
{
        name: "Logs",
        icon: "faTable",
        submenus: [
          { name: "OTP Logs", path: "/admin/otp-logs", icon: "faMapMarkedAlt" },
          { name: "User Logs", path: "/admin/logs-list", icon: "faMapPin" },
        ].filter(Boolean), // This will filter out any undefined values
      },
    ].filter(Boolean), // Filter out any null sections
  };

  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  useEffect(() => {
    const activeMenuIndex = menuPages.menus.findIndex((menu) =>
      menu.submenus.some(
        (submenu) => pathName.includes(submenu.path) && submenu.path.length > 0
      )
    );
    setOpenMenuIndex(activeMenuIndex !== -1 ? activeMenuIndex : null);
  }, [pathName]);

  const toggleMenu = (index) => {
    setOpenMenuIndex((prevState) => (prevState === index ? null : index));
  };

  return (
    <div className="side-menu adm-side-menu" id="LeftMenu">
      <ul className="ds-ul">
        <li
          onClick={() => navigate("/admin")}
          style={
            pathName.endsWith("/admin")
              ? { backgroundColor: "rgb(0 174 239)" }
              : { backgroundColor: "none" }
          }
        >
          <button
            className={
              pathName.endsWith("/admin")
                ? "bg_image_green"
                : "bg_image_white first-li"
            }
          >
            {" "}
            <FontAwesomeIcon icon={faTachometerAlt} /> <span>Dashboard</span>
          </button>
        </li>
        {menuPages.menus.map((menu, index) => (
          <li
            key={menu.name}
            style={
              openMenuIndex === index &&
              menu.submenus.some(
                (submenu) =>
                  pathName.includes(submenu.path) && submenu.path.length > 0
              )
                ? { backgroundColor: "rgb(0 174 239)" }
                : { backgroundColor: "none" }
            }
          >
            <button
              className="bg_image_white"
              onClick={() => toggleMenu(index)}
            >
              <span className="icon-text">
                <FontAwesomeIcon
                  icon={iconsMap[menu.icon] || iconsMap.default}
                />
                <span style={{ width: "auto" }}>{menu.name}</span>
              </span>
              {menu.submenus ? (
                openMenuIndex === index ? (
                  <FaChevronDown
                    style={{ marginLeft: "7px", marginTop: "2px" }}
                  />
                ) : (
                  <FaChevronRight
                    style={{ marginLeft: "7px", marginTop: "2px" }}
                  />
                ) // Chevron for collapse
              ) : null}
            </button>

            {menu.submenus && openMenuIndex === index && (
              <ul className="nested-ul">
                {menu.submenus.map((submenu) => (
                  <li
                    key={submenu.name}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(submenu.path);
                    }}
                  >
                    <button
                      className={
                        pathName.includes(submenu.path) &&
                        submenu.path.length > 0
                          ? "bg_image_green"
                          : "bg_image_white"
                      }
                    >
                      <FontAwesomeIcon
                        icon={iconsMap[submenu.icon] || iconsMap.default}
                      />
                      <span>{submenu.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideMenu;
