import AdminDashboard from "../AdminDashboard/AdminDashboard";
import React, { useEffect, useState } from "react";
import "../Admin.css";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import { Modal, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSubSectionList,
  subSectionListDataACtiveInactive,
  deleteSubSectionList,
} from "../../../services/api/admin";
import "../AdminGuidelines/AdminGuildlines.css";
import { HiPlus } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { Message } from "../../../components/message";
import { GetLocalStorage, RemoveSession } from "../../../Utils/Utils";
import { formatDateWithSlash } from "../../../Utils/common";
import { FaPencilAlt } from "react-icons/fa";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const AdminSubSectionList = () => {
    const navigate = useNavigate();
    const [subSectionList, setSubSectionList] = useState()
    const [tableDataLoaded, setTableDataLoaded] = useState(false)
    const [deleteData, setDeleteData] = useState('')
    const token = GetLocalStorage("userInfo")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [orderBy, setOrderBy] = useState("desc");
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [sortBy, setSortBy] = useState('created_at')
    const [searchData, setSearchData] = useState('')
    const { state } = useLocation();
    const [searchTerm, setSearchTerm] = useState()
    useEffect(() => {
        if (!token) {
            navigate("/")
        } else {
            getSubSectionData("created_at", "1", "desc", "")
        }
    }, [])

    useEffect(() => {
        if (state && state.message) {
            setApiMessage({ type: 'success', message: state.message });
            window.scrollTo(0, 0);
            window.history.replaceState({}, document.title);
        }
    }, [state])

  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: "success", message: state.message });
      window.scrollTo(0, 0);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const handlePageChange = (selectedPage) => {
    getSubSectionData(sortBy, selectedPage.selected + 1, orderBy, searchData); // Increment by 1 if the API starts page numbers from 1
  };

  const grantPermissionForAddFormSubSection = checkPermission(
    permissionsConfig.addFormSubSection.role,
    permissionsConfig.addFormSubSection.action,
    permissionsConfig.addFormSubSection.type
  );
  const grantPermissionForEditFormSubSection = checkPermission(
    permissionsConfig.editFormSubSection.role,
    permissionsConfig.editFormSubSection.action,
    permissionsConfig.editFormSubSection.type
  );

  const getSubSectionData = async (sortBy, pageNo, orderBy, searchBy) => {
    try {
      setSearchData(searchBy);
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const subSectionList = await getSubSectionList(
        sortBy,
        orderBy,
        searchBy,
        pageNo
      );
      setTotalPages(
        Math.ceil(subSectionList?.data?.total / subSectionList?.data?.per_page)
      );
      setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
      const data = subSectionList?.data?.data;
      if (subSectionList?.status === 200) {
        console.log("data", data);
        setSubSectionList(data);
        setTotalPages(
          Math.ceil(
            subSectionList?.data?.total / subSectionList?.data?.per_page
          )
        ); // Calculate total pages
      } else {
        setSubSectionList([]);
        setApiMessage({ type: "error", message: subSectionList?.message });
      }
      setTableDataLoaded(true);
    } catch (err) {
      console.log("error on subSectionList: ", err);
    }
  };

  const deleteFormField = async (item) => {
    try {
      const payload = {
        sub_section_id: item.id,
      };
      const res = await deleteSubSectionList(payload);
      setDeleteData("");
      getSubSectionData(sortBy, currentPage, orderBy, searchData);
    } catch (e) {}
  };

  const handleEnableDisable = async (e, item) => {
    try {
        const formData = new FormData();
        formData.append("sub_section_id", item?.id)
        formData.append("status", e?.value)
        const data = await subSectionListDataACtiveInactive(formData)
        if (data.status == 200) {
            getSubSectionData(sortBy, currentPage, orderBy, searchData);
        } else {
            setApiMessage({ type: 'error', message: data?.message });
        }
    } catch (err) {
        setApiMessage({ type: 'err', message: err.message });
    }
  }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span> Form Sub Sections</span>
                    </div>
                    {(grantPermissionForAddFormSubSection) ? (
                        <button className='adm-tab-btn' onClick={() => navigate("/admin/form/sub-sections/add")} ><span><HiPlus />
                        </span> Add</button>
                    ) : ''}
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer">
                            <h4 className='mb-0 w-100'>Sub Sections</h4>
                            {/* <input className='w-25 px-2 form-control' placeholder='search' type="text" onChange={(e) => getSubSectionData(sortBy, 1, orderBy, e.target.value)} /> */}
                            <div className="position-relative w-25">
                                <input
                                    className="px-2 form-control"
                                    placeholder="Search"
                                    type="text"
                                    value={searchTerm} // Add a state for the input value
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value); // Update the state
                                        getSubSectionData(sortBy, 1, orderBy, e.target.value)
                                    }}
                                />
                                {searchTerm && ( // Show the clear button only when there's text
                                    <button
                                        className="btn btn-clear position-absolute"
                                        onClick={(e) => {
                                            setSearchTerm(""); // Clear the input value
                                            getSubSectionData(sortBy, 1, orderBy, e.target.value) // Fetch data with empty search term
                                        }}
                                        style={{
                                            top: "50%",
                                            right: "10px",
                                            transform: "translateY(-50%)",
                                            border: "none",
                                            background: "transparent",
                                            fontSize: "1rem",
                                            cursor: "pointer",
                                        }}
                                    >
                                        &times; {/* This is the "X" icon */}
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            <Table size="sm" className='ad-cat-table mt-4'>
                                <thead>
                                    <tr>
                                        <th className='ad-sno'>S. No</th>
                                        {/* <th className='ad-long'>Sub Category Id </th> */}
                                        <th className='ad-long cursor-pointer' onClick={(e) => getSubSectionData('name_en', currentPage + 1, orderBy === 'asc' ? 'desc' : 'asc', searchData)}>English Name <span><i className="fa-solid fa-sort" ></i></span></th>
                                        <th className='ad-long cursor-pointer' onClick={(e) => getSubSectionData('name_hi', currentPage + 1, orderBy === 'asc' ? 'desc' : 'asc', searchData)}>Hindi Name <span><i className="fa-solid fa-sort" ></i></span></th>
                                        <th className='ad-long cursor-pointer' onClick={(e) => getSubSectionData('created_at', currentPage + 1, orderBy === 'asc' ? 'desc' : 'asc', searchData)}>Created At <span><i className="fa-solid fa-sort" ></i></span></th>
                                        {(grantPermissionForEditFormSubSection) ? (<>
                                            <th className='ad-long'>Status</th>
                                            <th className='ad-long'>Actions</th></>
                                        ) : ''}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        subSectionList?.length > 0 ? subSectionList?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{(currentPage * 10) + (index + 1)}</td>
                                                <td>{item?.name_en ? item?.name_en : '--'}</td>
                                                <td>{item?.name_hi ? item?.name_hi : '--'}</td>
                                                <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>
                                                {(grantPermissionForEditFormSubSection) ? (<>
                                                    <td>
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={item?.status == 1}
                                                                onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                            />
                                                            <span className="slider"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <div className="action-btn d-flex">
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                                                            >
                                                                <span
                                                                    className="ad-cat-edit"
                                                                    onClick={() => { navigate("/admin/form/sub-sections/update", { state: item }) }}
                                                                >
                                                                    <EditIcon />
                                                                </span>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`delete-tooltip-${item.id}`}>Delete</Tooltip>}
                                                            >
                                                                <span
                                                                    className="ad-cat-delete"
                                                                    onClick={() => setDeleteData(item)}
                                                                >
                                                                    <DeleteIcon />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </td>
                                                </>) : ''}
                                            </tr>

                                        )) : tableDataLoaded ? <NotFoundTable colSpan="6"></NotFoundTable> : <></>
                                    }
                                </tbody>
                            </Table>
                            {totalPages > 1 && subSectionList?.length > 0 &&
                                <div className="d-flex w-100 justify-content-end">
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={totalPages} // Total number of pages from API
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={1}
                                        onPageChange={handlePageChange} // Method to handle page click
                                        forcePage={currentPage}
                                        containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={`page-item${!subSectionList?.length > 0 ? ' disabled' : ''}`}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
           
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you really want to delete this Sub Section ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2">
            <button onClick={() => deleteFormField(deleteData)}>Yes</button>
            <button
              className="suf-can-butt"
              onClick={() => setDeleteData(false)}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default AdminSubSectionList;
