import Banner from "../../components/Banner/Banner";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider ";
import MainLayout from "../../components/MainLayout/MainLayout";
import React, { useEffect, useState } from "react";
import "../Home/Home.css";
import { ClearLocalStorage } from "../../Utils/Utils";
import { getHomePageContent } from "../../services/api/outer";
import { getDisclaimerDetails } from  "../../services/api/admin";

const Disclaimer = () => {
  const { language, setLanguage } = useLanguage();
  const [homePageData, setHomePageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [Disclaimer_en, setDisclaimer_en] = useState('');
  const [Disclaimer_hi, setDisclaimer_hi] = useState('');
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  useEffect(() => {
    window.scrollTo(0, 0);
    getDisclaimer();
    const fetchData = async () => {
      try {
        const [homePageDataResponse] = await Promise.all([
          getHomePageContent(),
        ]);
        setHomePageData(homePageDataResponse);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    ClearLocalStorage();
  }, []);

  const getDisclaimer = async () => {
    try {
      const formData = new FormData();
      formData.append("page_id", 3);
      const usersListData = await getDisclaimerDetails(formData);
      if (usersListData.status === 200) {
        setDisclaimer_en(usersListData?.data?.content_en)
        setDisclaimer_hi(usersListData?.data?.content_hi)
      }
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message });
    }
  };

  if (loading || homePageData === null) {
    return <></>;
  }
  return (
    <>
      <div>
        <MainLayout homePageData={homePageData}>
          <section className="privacy-policy section-padding">
            <div className="container">
              <div className="privacy-wrapper">
                <div className="privacy-heading">
                  <div className="text-center pt-2 pb-3 hcat-main">
                    <p className="hex-p"> {language ? "अस्वीकरण" : "Disclaimer"} </p>
                    <div className="hex-explore">
                     {language ? "यात्रा व्यापार अस्वीकरण का अन्वेषण करें" : "Explore Travel Trade Disclaimer"} 
                    </div>
                  </div>
                </div>
                <p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: language
                        ? Disclaimer_hi
                        : Disclaimer_en,
                    }}
                  ></span>
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
          </section>
        </MainLayout>
      </div>
    </>
  );
};
export default Disclaimer;
