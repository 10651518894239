import React, { useState } from "react";
import checkicon from "../../assets/images/tic-png.png";
import paymentImage from "../../assets/images/payment-img.png";
import { FaRegCheckCircle } from "react-icons/fa";
import { CgCheckO, CgCloseO } from "react-icons/cg";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import { changeApplicationStatus } from "../../services/api/user";

const Payments = ({ handleApplicationSubmit, setIsFormSubmitted, applicationId, isDraftSubmitted }) => {
  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [condition3, setCondition3] = useState(false);
  const [apiMessage, setApiMessage] = useState(false);
  const [stateObject, setStateObject] = useState(false);
  const { language, setLanguage } = useLanguage();
  const handleSubmitLogin = async (event) => {
    const isAllValid = await handleApplicationSubmit();
    if(isAllValid) {
      if (!condition1 || !condition2 || !condition3) {
        setApiMessage(language ? "कृपया आगे बढ़ने से पहले सभी शर्तों से सहमत हों।" : "Please agree to all conditions before proceeding.");
        event.preventDefault();
        return;
      } else {
        event.preventDefault();
        const formData = new FormData();
        formData.append("status", "8");
        formData.append("application_id", applicationId);
        const changeApplicationStatusResponse = await changeApplicationStatus(formData);
        if (changeApplicationStatusResponse.status === "200" || changeApplicationStatusResponse.status === 200) {
          setIsFormSubmitted(true)
          setStateObject(true);
          event.preventDefault();
          return;
        } else {
          event.preventDefault();
        }
      }
    } else {
      event.preventDefault();
    }
  };

  return (
    <div>
      {!stateObject ? (
        <>
          <div className="payment-wrap">
            <div className="row">
              <div className="col-xl-8 col-md-12 col-sm-12">
                <div className="pay-main-block">
                  <div className="pay-bar">
                    <h5 className="mb-0">{language ? "आवेदक का विवरण" : "Applicant Details"}</h5>
                  </div>
                  <div className="pay-inner-block">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="pay-detail-block">
                          <div className="pay-left-icon mx-3">
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>{language ? "संगठन का नाम" : "Organization Name"}</h6>
                            <span>Tour & Travels</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="pay-detail-block">
                          <div className="pay-left-icon mx-3">
                            {" "}
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>{language ? "ईमेल" : "Email"}</h6>
                            <span>abc@drisinfo.com</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="pay-detail-block">
                          <div className="pay-left-icon mx-3">
                            {" "}
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>{language ? "ज़िला" : "District"}</h6>
                            <span>Chandigarh</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="pay-detail-block mt-4">
                          <div className="pay-left-icon mx-3">
                            {" "}
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>{language ? "मालिक का नाम" : "Owner Name"}</h6>
                            <span>Rakesh</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="pay-detail-block mt-4">
                          <div className="pay-left-icon mx-3">
                            {" "}
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>{language ? "मोबाइल नंबर" : "Mobile Number"}</h6>
                            <span>9877653452</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="pay-detail-block mt-4">
                          <div className="pay-left-icon mx-3">
                            {" "}
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>{language ? "मात्रा" : "Amount"}</h6>
                            <span>2000</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 d-lg-none d-xl-block">
                <div className="payment-img">
                  <img src={paymentImage} alt="payment-image" />
                </div>
              </div>

              <div className="conditions-block">
                <div className="form-group">
                  <input
                    type="checkbox"
                    id="condition1"
                    checked={condition1}
                    onChange={() => setCondition1(!condition1)}
                  />
                  <label htmlFor="condition1">
                    {language ? "मैं/हम रुपये की राशि का भुगतान करने के लिए सहमत हैं। उत्तराखंड पर्यटन विभाग को शुल्क के रूप में 500 रु." : "I/ We agree to pay the amount of Rs. 500 as fees to Uttarakhand Tourism Department."}
                  </label>
                </div>

                <div className="form-group">
                  <input
                    type="checkbox"
                    id="condition2"
                    checked={condition2}
                    onChange={() => setCondition2(!condition2)}
                  />
                  <label htmlFor="condition2">
                  {language ? "मैं इसके द्वारा पुष्टि करता हूं कि ऊपर बताई गई सभी जानकारी मेरी सर्वोत्तम समझ के अनुसार सही और प्रामाणिक है..." 
                  : "I hereby confirm that all the information stated above is correct and authentic to the best of my understanding..."}
                  </label>
                </div>

                <div className="form-group">
                  <input
                    type="checkbox"
                    id="condition3"
                    checked={condition3}
                    onChange={() => setCondition3(!condition3)}
                  />
                  <label htmlFor="condition3">
                    {language ? "मैं स्वीकार करता हूं और आवश्यक दस्तावेजों को सही और सच्चाई से अपलोड करने के लिए सहमत हूं..." : "I acknowledge and agree to upload the required documents accurately and truthfully..."}
                  </label>
                </div>
              </div>
            </div>
            <div className="red-text">{apiMessage}</div>
            {isDraftSubmitted ? <button
              className="btn btn-primary user-theme-btn mt-3"
              onClick={handleSubmitLogin}
            >
              {language ? "जमा करना" : "Submit"} 
            </button> : <></>}
          </div>
        </>
      ) : (
        <>
          <div className="payment-successfull-block hide">
            <div className="row">
              <div className="col-md-12">
                <div className="payment-detail-blocks payment-success-inner-wrapprer">
                  <div className="paymnet-done-icon">
                    {" "}
                    <CgCheckO />
                  </div>
                  <div className="payment-done-text">
                    <p className="mb-2">Payment ID : 8900956845896</p>
                    <h5 className="">Payment Successful!</h5>
                    <p>
                      The Payment has been done successfully! Thanks for being
                      their with us.
                    </p>
                  </div>
                  <div className="dashboard-btn">
                    <button className="user-theme-btn"> Dashboard</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="payment-failed-block hide">
            <div className="row">
              <div className="col-md-12 hide">
                <div className=" payment-detail-blocks payment-failed-inner-wrapprer">
                  <div className="paymnet-done-icon">
                    {" "}
                    <CgCloseO />
                  </div>
                  <div className="payment-done-text">
                    <p className="mb-2">Payment ID : 8900956845896</p>
                    <h5 className="">Payment Failed!</h5>
                    <p>
                      The Payment has been done successfully! Thanks for being
                      their with us.
                    </p>
                  </div>
                  <div className="dashboard-btn">
                    <button className="user-theme-btn theme-btn-danger">
                      {" "}
                      Try Again
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default Payments;
