/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../Admin.css";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import { Modal, Table } from "react-bootstrap";
import { Imageurl } from "../../../Utils/Utils";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useNavigate } from "react-router-dom";
import { HiPlus } from "react-icons/hi";
import {
  deleteCategoryData,
  getCategoryListDataSort,
  handleEnablecategoryDisableData,
} from "../../../services/api/admin";
import ReactPaginate from "react-paginate";
import { Message } from "../../../components/message";
import { useLocation } from "react-router-dom";
import { formatDateWithSlash } from "../../../Utils/common";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const Category = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { state } = useLocation();
  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: "success", message: state.message });
      window.scrollTo(0, 0);
      // Clear the message once it is rendered
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const [searchTerm, setSearchTerm] = useState()
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderBy, setOrderBy] = useState("desc");
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [sortBy, setSortBy] = useState("created_at");
  const [searchData, setSearchData] = useState("");

  const handlePageChange = (selectedPage) => {
    getCategoryData(sortBy, selectedPage.selected + 1, orderBy, searchData); // Increment by 1 if the API starts page numbers from 1
  };

  const grantPermissionForAddCategories = checkPermission(
    permissionsConfig.addCategories.role,
    permissionsConfig.addCategories.action,
    permissionsConfig.addCategories.type
  );
  const grantPermissionForEditCategories = checkPermission(
    permissionsConfig.editCategories.role,
    permissionsConfig.editCategories.action,
    permissionsConfig.editCategories.type
  );

  //category list
  const getCategoryData = async (sortBy, pageNo, orderBy, searchBy) => {
    try {
      setSearchData(searchBy);
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const categoryList = await getCategoryListDataSort(
        sortBy,
        orderBy,
        searchBy,
        pageNo
      );
      setTotalPages(
        Math.ceil(categoryList?.data?.total / categoryList?.data?.per_page)
      );
      setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
      const data = categoryList?.data?.data;
      if (categoryList?.status === 200) {
        setCategoryList(data);
        setTotalPages(
          Math.ceil(categoryList?.data?.total / categoryList?.data?.per_page)
        ); // Calculate total pages
      } else {
        setCategoryList([]);
        setApiMessage({ type: "error", message: categoryList?.message });
      }
      setTableDataLoaded(true);
    } catch (err) {
      console.log("error on categoryList: ", err);
    }
  };

  useEffect(() => {
    getCategoryData("created_at", "1", "desc", "");
  }, []);

  // delete Category
  const deleteCategory = async (obj) => {
    try {
      const formData = new FormData();
      formData.append("category_id", obj.id);
      await deleteCategoryData(formData);
      getCategoryData(sortBy, currentPage, orderBy, searchData);
      setDeleteData("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("category_id", item?.id);
      formData.append("status", e?.value);
      const data = await handleEnablecategoryDisableData(formData);
      if (data?.status === 200) {
        getCategoryData(sortBy, currentPage, orderBy, searchData);
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Categories</span>
          </div>
          {grantPermissionForAddCategories ? (
            <button
              className="adm-tab-btn"
              onClick={() => navigate("/admin/categories/add")}
            >
              <span>
                <HiPlus />
              </span>{" "}
              Add
            </button>
          ) : null}
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer d-flex justify-content-between">
              <h4 className="mb-0">Categories </h4>{" "}

              <div className="position-relative w-25">
                <input
                  className="px-2 form-control"
                  placeholder="Search"
                  type="text"
                  value={searchTerm} // Add a state for the input value
                  onChange={(e) => {
                    setSearchTerm(e.target.value); // Update the state
                    getCategoryData(sortBy, 1, orderBy, e.target.value)
                  }}
                />
                {searchTerm && ( // Show the clear button only when there's text
                  <button
                    className="btn btn-clear position-absolute"
                    onClick={(e) => {
                      setSearchTerm(""); // Clear the input value
                      getCategoryData(sortBy, 1, orderBy, e.target.value); // Fetch data with empty search term
                    }}
                    style={{
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      border: "none",
                      background: "transparent",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    &times; {/* This is the "X" icon */}
                  </button>
                )}
              </div>
            </div>
            {/* <div className="col-md-12 aod-resp-tab">
                            <div className='suf-input-box aod-form-input mb-2'>
                                <input className='w-25' placeholder='search' type="text" onChange={(e) => getCategoryData(e, 'category_name')} />
                            </div>
                        </div> */}
            <div className="aod-head"></div>

            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table  mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-sno"> Image</th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getCategoryData(
                          "category_name",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      {" "}
                      English Name{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getCategoryData(
                          "category_name_hi",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      {" "}
                      Hindi Name{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getCategoryData(
                          "category_description",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      {" "}
                      Description{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getCategoryData(
                          "created_at",
                          currentPage + 1,
                          orderBy === "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Created At{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    {grantPermissionForEditCategories ? (
                      <>
                        <th className="ad-long">Status</th>
                        <th className="ad-long">Actions</th>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {categoryList?.length > 0 ? (
                    categoryList?.map((item, index) => (
                      <tr
                        key={index}
                        className={index % 2 !== 0 ? "adm-even" : ""}
                      >
                        <td>{currentPage * 10 + (index + 1)}</td>
                        <td>
                          {item?.category_image ? (
                            <img
                              className="ad-cat-td-img"
                              src={`${Imageurl()}/${item?.category_image}`}
                              alt=""
                            />
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          {item?.category_name ? item?.category_name : "--"}
                        </td>
                        <td>
                          {item?.category_name_hi
                            ? item?.category_name_hi
                            : "--"}
                        </td>
                        <td>
                          {item?.category_description
                            ? item.category_description.length > 5
                              ? item.category_description.substring(0, 18) +
                              "..."
                              : item.category_description
                            : "--"}
                        </td>
                        <td>
                          {item?.created_at
                            ? formatDateWithSlash(item?.created_at)
                            : "--"}
                        </td>
                        {/* <td>
                                                    <div className='action-btn d-flex'><span className='ad-cat-edit' onClick={() => { navigate("/admin/categories/update", { state: item }) }}><EditIcon /></span><span className='d-none ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></div></td> */}
                        {grantPermissionForEditCategories ? (
                          <>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item?.status == 1}
                                  onChange={(e) =>
                                    handleEnableDisable(
                                      e.target.checked
                                        ? { value: "1" }
                                        : { value: "0" },
                                      item
                                    )
                                  }
                                />
                                <span className="slider"></span>
                              </label>
                            </td>
                            <td>
                              <div className="action-btn d-flex">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`edit-tooltip-${item.id}`}>
                                      Edit
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="ad-cat-edit"
                                    onClick={() => {
                                      navigate("/admin/categories/update", {
                                        state: item,
                                      });
                                    }}
                                  >
                                    <EditIcon />
                                  </span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`delete-tooltip-${item.id}`}>
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="ad-cat-delete d-none"
                                    onClick={() => setDeleteData(item)}
                                  >
                                    <DeleteIcon />
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))
                  ) : tableDataLoaded ? (
                    <NotFoundTable colSpan="8"></NotFoundTable>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
              {totalPages > 1 && categoryList?.length &&
                <div className="d-flex w-100 justify-content-end">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={totalPages} // Total number of pages from API
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange} // Method to handle page click
                    forcePage={currentPage}
                    containerClassName={"pagination justify-content-center"} // Custom CSS classes
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={`page-item${!categoryList?.length > 0 ? " disabled" : ""
                      }`}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you really want to delete this Category ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2 d-flex flex-row">
            <button
              className="mx-2"
              onClick={() => {
                deleteCategory(deleteData);
              }}
            >
              Yes
            </button>
            <button
              className="suf-can-butt no-btn mx-2"
              onClick={() => setDeleteData("")}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default Category;
