import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import NotFoundTable from '../../../components/notFound/NotFoundTable';
import { getPaymentTabList, activeInactivePaymentTab } from '../../../services/api/admin';
import { formatDateWithSlash } from '../../../Utils/common';
import { Baseurl } from '../../../Utils/Utils';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import { EditIcon } from "../AdminIcon";
import { Message } from '../../../components/message'
import { HiPlus } from "react-icons/hi";

const PaymentUpdatePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);

  useEffect(() => {
    paymentStatus();
  }, []);

  const paymentStatus = async () => {
    try {
      const usersListData = await getPaymentTabList();

      console.log(usersListData);

      if (usersListData.status === 200) {
        setUserList(usersListData?.data || []);
        setTableDataLoaded(true);
      }
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    paymentStatus();
  };

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("payment_tab_id", item?.id);
      formData.append("status", e?.value);
      const data = await activeInactivePaymentTab(formData);
      if (data?.status == 200) {
        paymentStatus();
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
      {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
          <div>
            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span> Payment Status</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Payment Status</h4>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-long">Sub Category</th>
                    <th className="ad-long">Created At</th>
                    <th className="ad-long">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {userList.length > 0 ? (
                    userList.map((item, index) => (
                      <tr key={item.id}>
                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                        <td>{item?.subcategory_details.category_name}</td>
                        <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>
                        <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={item?.status == 1}
                                onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                              />
                              <span className="slider"></span>
                            </label>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NotFoundTable colSpan="9"></NotFoundTable>
                  )}
                </tbody>
              </Table>
              {/* <div className="d-flex w-100 justify-content-end">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default PaymentUpdatePage;
