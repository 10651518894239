import { React, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import { Message } from '../../../components/message';
import { getNiamvaliDetails , updateNiamvaliDetails } from '../../../services/api/admin';
import AdminDashboard from '../AdminDashboard/AdminDashboard';


const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ],
};

const formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

const EditNiyamvali = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [Niyamvali_en, setNiyamvali_en] = useState('');
  const [Niyamvali_hi, setNiyamvali_hi] = useState('');
  const [mandatoryNiyamvaliEnErr, setMandatoryNiyamvaliEnErr] = useState('')
  const [mandatoryNiyamvaliHiErr, setMandatoryNiyamvaliHiErr] = useState('')

  useEffect(() => {
    getNiyamvali();
  }, []);

  const getNiyamvali = async () => {
    try {
      const formData = new FormData();
      formData.append("page_id", 4);
      const usersListData = await getNiamvaliDetails(formData);
      if (usersListData.status === 200) {
        setUserList(usersListData?.data || []);
        setTableDataLoaded(true);
        setNiyamvali_en(usersListData?.data?.content_en)
        setNiyamvali_hi(usersListData?.data?.content_hi)
      }
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    getNiyamvali();
  };

  const handleEditorChange = (field, value) => {
        // Update content
        if (field === 'Niyamvali_en') setNiyamvali_en(value);
        if (field === 'Niyamvali_hi') setNiyamvali_hi(value);
    };

    const updateFormField = async (item) => {
        try {
            setMandatoryNiyamvaliHiErr('');
            setMandatoryNiyamvaliEnErr('');
      
            if (!Niyamvali_en.trim() || Niyamvali_en === "<p><br></p>") {
                setMandatoryNiyamvaliEnErr('Niyamvali in English cannot be empty');
            }
            if (!Niyamvali_hi.trim() || Niyamvali_hi === "<p><br></p>") {
                setMandatoryNiyamvaliHiErr('Niyamvali in Hindi cannot be empty');
            }
      
            if (!Niyamvali_en.trim() || !Niyamvali_hi.trim() || Niyamvali_hi === "<p><br></p>"  || Niyamvali_en === "<p><br></p>") {
                return;
            }
            const formData = new FormData();
            formData.append("term_condition_id", 3)
            formData.append("content_en", Niyamvali_en);
            formData.append("content_hi", Niyamvali_hi);
            const updateNiyamvali = await updateNiamvaliDetails(formData)
            if (updateNiyamvali && updateNiyamvali.status == 200) {
                setApiMessage({ type: 'success', message: updateNiyamvali.message });
                getNiyamvali();
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    };


  return (
    <AdminDashboard>
      <div className="aod-outer">
      {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Niyamvali</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Niyamvali</h4>
            </div>
            <div className="aod-head"></div>
            <div className='suf-input-box aod-form-input mb-5'>
              <label className='lf-label w-100'>Niyamvali English</label>
              <ReactQuill
                name="Niyamvali_en"
                value={Niyamvali_en}
                onChange={(value) => handleEditorChange('Niyamvali_en', value)}
                modules={modules}
                formats={formats}
                theme="snow"
              />
              {mandatoryNiyamvaliEnErr && <p className='validate-error'>{mandatoryNiyamvaliEnErr}</p>}
            </div>
            <div className='suf-input-box aod-form-input mb-5'>
              <label className='lf-label w-100'>Niyamvali Hindi</label>
              <ReactQuill
                name="Niyamvali_hi"
                value={Niyamvali_hi}
                onChange={(value) => handleEditorChange('Niyamvali_hi', value)}
                modules={modules}
                formats={formats}
                theme="snow"
              />
              {mandatoryNiyamvaliHiErr && <p className='validate-error'>{mandatoryNiyamvaliHiErr}</p>}
            </div>
          </div>
        </div>
        <div className="row ad-cat-row">
            <div className="col-md-12 mt-4 gap-4 d-flex">
                <button onClick={() => updateFormField()}>
                    Update
                </button>
            </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default EditNiyamvali;
