import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import "../../../../src/pages/Admin/ApplicationView/ApplicationView.css";
import Applicationicon from "../../../../src/assets/images/application-icon.png";
import RoomIcon from "../../../../src/assets/images/room-details.png";
import PartnerIcon from "../../../../src/assets/images/partners.png";
import LocationIcon from "../../../../src/assets/images/loaction.png";
import AccommodationIcon from "../../../../src/assets/images/accommodation-details.png";
import AdditionalIcon from "../../../../src/assets/images/additional-details.png";
import UploadIcon from "../../../../src/assets/images/upload-documents.png";
import { useLocation, useNavigate } from "react-router-dom";
import { GetLocalStorage } from "../../../Utils/Utils";
import { BsChevronLeft } from "react-icons/bs";
import Select from 'react-select';
import { customStylesAcc, formatDateTime, selectTheme } from "../../../Utils/common";
import { stateDistrict } from "../../../services/api/auth";
import { getApplicationForm, uploadDocumentlistData } from "../../../services/api/user";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { applicationCertificatelistData, uploadedDocumentlistData } from "../../../services/api/admin";
import Geolocation from "../../../components/inputType/geoloaction";
const ApplicationView = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const token = GetLocalStorage("userInfo")
  const [mainPageApplicationData, setMainPageApplicationData] = useState()
  const [pageFrom, setPageFrom] = useState("")
  const [districtList, setDistrictList] = useState([]);
  const [tabsWholeData, setTabsWholeData] = useState([]);
  const [partnerTableData, setPartnerTableData] = useState([]);
  const [accomadationUnitName, setAccomadationUnitName] = useState()
  const [accomadationUnitOwnerName, setAccomadationUnitOwnerName] = useState()
  const [accomadationUnit, setAccomadationUnit] = useState({})
  const [distanceFrom, setDistanceFrom] = useState({})
  const [roomTableData, setRoomTableData] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [certificateList, setCertificateList] = useState({});
  const [applicationLog, setApplicationLog] = useState([])
  const [statusId, setStatusId] = useState();
  const [remark, setRemark] = useState();
  const [error, setError] = useState({});
  const [accomadationUnitFacility, setAccomadationUnitFacility] = useState({})
  const applicationStatusList = [
    { value: '0', label: 'Inactive.' },
    { value: '1', label: 'Active.' },
    { value: '2', label: 'Pending' },
    { value: '3', label: 'Draft' },
    { value: '4', label: 'Approved' },
    { value: '5', label: 'Rejected' },
    { value: '6', label: 'Renew' },
    { value: '7', label: 'Under Review' },
    { value: '8', label: 'submitted' },
  ]
  useEffect(() => {
    if (!token) {
      navigate("/")
    } else {
      if (location?.state) {
        setMainPageApplicationData(location?.state?.data)
        getApplicationData(location?.state?.data)
        docsList(location?.state?.data)
        certList(location?.state?.data)
        getDistrictList();
        switch (location?.state?.pageFrom) {
          case "totalApplication": setPageFrom('total-applications'); break;
          case "pendingApplication": setPageFrom('pending-applications'); break;
          case "rejectedApplication": setPageFrom('rejected-applications'); break;
          case "approvedApplication": setPageFrom('approved-applications'); break;
          case "renewApplication": setPageFrom('renewal-applications'); break;
        }
        console.log(location?.state?.data)
      }
    }
  }, [location?.state])

  const getDistrictList = async () => {
    try {
      const districtData = await stateDistrict()
      const data = districtData?.data?.data?.map((district) => ({
        label: district?.name_en,
        value: district?.id,
      }));
      setDistrictList(data)
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message });
    }
  }

  const docsList = async (data) => {
    try {
      const formData = new FormData();
      formData.append("application_id", data?.id);
      formData.append("user_id", data?.user_id);
      const getList = await uploadedDocumentlistData(formData);
      if (getList?.status === 200) {
        const filteredData = getList?.data?.filter(
          item => item?.upload_documents && Object.keys(item?.upload_documents)?.length > 0
        );
        console.log(filteredData)
        setDocumentList(filteredData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const certList = async(data)=>{
    try{
      const formData = new FormData()
      formData.append("application_id", data?.id);
      formData.append("user_id", data?.user_id);
      const list = await applicationCertificatelistData(formData)      // console.log(list.data)
      setCertificateList(list?.data)
    }catch(err){
      console.log(err)
    }
  }


  const getApplicationData = async (data) => {
    try {
      let accomadationUnitData = { ...accomadationUnit }
      let distanceFromData = { ...distanceFrom }
      let accomadationUnitFacilityData = {...accomadationUnitFacility}
      const formData = new FormData();
      formData.append("sub_category_id", data?.category_id);
      formData.append("application_detail_id", data?.id);
      const applicationFormResponse = await getApplicationForm(formData);
      if (
        applicationFormResponse.status === "200" ||
        applicationFormResponse.status === 200
      ) {
        setTabsWholeData((prevdata) => ({
          ...prevdata,
          ...applicationFormResponse?.data, // Spread applicationFromData values into prevdata
        }));

        const partnerData = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.includes("total_no_of_owner_partners") && key.endsWith("json")
        );
        const accomadationUnitTitle = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_title_of_accommodation_unit")
        );
        const accomadationUnitownerTitle = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_name_of_the_ownerpartner")
        );
        const yearOfConstruction = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_year_of_construction")
        );
        const dateOfstartingBusiness = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_date_of_starting_business_for_old_units")
        );
        const totalArea = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_total_area_in_sq_meters")
        );
        const totalRoom = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.includes("total_no_of_room") && !key.endsWith("json")
        );
        const roomTableData = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.includes("total_no_of_room") && key.endsWith("json")
        );
        const city = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_city")
        );
        const railway = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_railway_station")
        );
        const bus = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_bus_stand")
        );
        const airport = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_airport")
        );
        const shopping = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_shopping_mall")
        );
        const touristPoint = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.includes("f_distance_from_tourist_point")
        );
        const Hospital = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.includes("f_hospital_dispensary") 
        );
        const carParking = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_car_parking")
        );
        const resturant = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_restaurant")
        );
        const bar = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_bar")
        );
        const swimmingPool = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_swimming_pool")
        );
        const healthSpa = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_health_spa")
        );
        const cofferenceHall = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.includes("f_conference_hall") && !key.endsWith("json")
        );

        accomadationUnitData.totalRoom = totalRoom[1] ? totalRoom[1] : "--"
        accomadationUnitData.yearOfConstruction = yearOfConstruction[1] ? yearOfConstruction[1] : '--'
        accomadationUnitData.dateOfstartingBusiness = dateOfstartingBusiness[1] ? dateOfstartingBusiness[1] : "--"
        accomadationUnitData.totalArea = totalArea[1] ? totalArea[1] : "--"

        distanceFromData.city = city[1] ? city[1] : '--'
        distanceFromData.railway = railway[1] ? railway[1] : '--'
        distanceFromData.bus = bus[1] ? bus[1] : '--'
        distanceFromData.airport = airport[1] ? airport[1] : '--'
        distanceFromData.shopping = shopping[1] ? shopping[1] : '--'
        distanceFromData.touristPoint = touristPoint[1] ? touristPoint[1] : '--'
        distanceFromData.Hospital = Hospital[1] ? Hospital[1] : '--'

        accomadationUnitFacilityData.carParking = carParking[1] ? carParking[1] : '--'
        accomadationUnitFacilityData.resturant = resturant[1] ? resturant[1] : '--'
        accomadationUnitFacilityData.bar = bar[1] ? bar[1] : '--'
        accomadationUnitFacilityData.swimmingPool = swimmingPool[1] ? swimmingPool[1] : '--'
        accomadationUnitFacilityData.healthSpa = healthSpa[1] ? healthSpa[1] : '--'
        accomadationUnitFacilityData.cofferenceHall = cofferenceHall[1] ? cofferenceHall[1] : '--'

        setAccomadationUnitFacility(accomadationUnitFacilityData ?? {})
        setRoomTableData(roomTableData[1] ?? [])
        setAccomadationUnit(accomadationUnitData ?? {})
        setDistanceFrom(distanceFromData ?? {})
        setAccomadationUnitName(accomadationUnitTitle[1] ?? "--")
        setAccomadationUnitOwnerName(accomadationUnitownerTitle[1] ?? "--")
        setPartnerTableData(partnerData[1]?? [])
      } else {
      }
    } catch (err) {
      console.error("Error fetching application data:", err);
    }
  }

 

  const submitStatus = async (e) =>{
    e.preventDefault();
    let isValid = true;
    if (!statusId) {
      setError((prev) => ({
        ...prev,
        status: "Please select the status",
      }));
      isValid = false;
    }

    if (!remark?.trim()) {
      setError((prev) => ({
        ...prev,
        remark: "Please enter the remark",
      }));
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try{

    }catch(err){

    }
  }


  return (
    <AdminDashboard>
      <div className="aod-outer">
        <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
          <div>
            <b onClick={() => navigate("/admin/")}>Home  /</b><span> Application</span>
          </div>
        </div>
        <div className=" text-center adm-head mb-5"><button onClick={() => navigate(`/admin/${pageFrom}`)}><BsChevronLeft /> Back</button></div>

        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3 border-0 custom_tabs_wrapper">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    <div className="custom_card_header d-flex p-0 m-0 border-0">
                      <img src={Applicationicon} alt="" srcSet="" />{" "}
                      <h6 className="fw-bolder mb-0">All</h6>
                    </div>
                  </button>
                  <button
                    className="nav-link"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    <div className="custom_card_header d-flex p-0 m-0 border-0">
                      <img src={RoomIcon} alt="" srcSet="" />{" "}
                      <h6 className="fw-bolder mb-0">Rooms</h6>
                    </div>
                  </button>
                  <button
                    className="nav-link"
                    id="nav-applicationStatus-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-applicationStatus"
                    type="button"
                    role="tab"
                    aria-controls="nav-applicationStatus"
                    aria-selected="false"
                  >
                    <div className="custom_card_header d-flex p-0 m-0 border-0">
                      <img src={PartnerIcon} alt="" srcSet="" />{" "}
                      <h6 className="fw-bolder mb-0">Application log</h6>
                    </div>
                  </button>
                  <button
                    className="nav-link"
                    id="nav-certificateList-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-certificateList"
                    type="button"
                    role="tab"
                    aria-controls="nav-certificateList"
                    aria-selected="false"
                  >
                    <div className="custom_card_header d-flex p-0 m-0 border-0">
                      <img src={PartnerIcon} alt="" srcSet="" />{" "}
                      <h6 className="fw-bolder mb-0">Certificates</h6>
                    </div>
                  </button>
                </div>
              </nav>
              <div className="tab-content p-4" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="row row gx-3 row-gap-3">
                    <div className="col-md-6">
                      <div className="card h-100  custom_card ">
                        <div className="custom_card_header d-flex">
                          <img
                            src="https://utdbhomestayapi.dilonline.in/assets/backend/img/Applicant_Detail.png"
                            alt=""
                            srcSet=""
                          />
                          <h6 className="fw-bolder">Applicant Details</h6>
                        </div>
                        <div className="card-body">
                          <div className="row gx-2 row-gap-2">
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Tracking Number:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{mainPageApplicationData?.tracking_number ?? "--"}</span>
                              </div>
                            </div>

                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Customer ID:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{mainPageApplicationData?.customer_id ?? "--"}</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Application number:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{mainPageApplicationData?.application_number ?? "--"}</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Applied date: </strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{formatDateTime(mainPageApplicationData?.created_at) ?? "--"}</span>
                              </div>
                            </div>

                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Applied For District:</strong>
                              </div>

                              <div className="card_feild_main_itm">
                                <td>{mainPageApplicationData?.district_id ? districtList?.find(option => option.value == mainPageApplicationData?.district_id)?.label : "--"}</td>
                              </div>
                            </div>

                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Address.</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{mainPageApplicationData?.users_list?.address ?? "--"}</span>

                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Name of Accommodation Unit:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnitName ?? "--"}</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>owner’s name: </strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnitOwnerName??"--"}</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Phone Number: </strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{mainPageApplicationData?.users_list?.mobile_number ?? "--"}</span>
                              </div>
                            </div>

                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Email: </strong>
                              </div>
                              <div className="card_feild_main_itm lowerCaseEmail">
                                <span>{mainPageApplicationData?.users_list?.email ?? "--"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card h-100  custom_card ">
                        <div className="custom_card_header d-flex">
                          <img src={LocationIcon} alt="" srcSet="" />{" "}
                          <h6 className="fw-bolder mb-0">Geo-Location</h6>
                        </div>
                        <div className="card-body">
                          <div className="row gx-2 row-gap-2"></div>
                          <Geolocation />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card custom_card">
                        <div className="custom_card_header d-flex">
                          <img src={PartnerIcon} alt="" srcSet="" />{" "}
                          <h6 className="fw-bolder">
                            Partners Details
                            {/* <span className="detailcount">2</span> */}
                          </h6>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table  bg-table mb-0 custom_table mb-0">
                              <thead>
                                <tr>
                                  <th scope="col">Sr. No </th>
                                  <th scope="col"> Owner/Partner Name </th>
                                  <th scope="col"> Email</th>
                                  <th scope="col">State </th>
                                  <th scope="col">District</th>
                                  <th scope="col">City/Village</th>
                                  <th scope="col">House No</th>
                                  <th scope="col">Landmark</th>
                                  {/* <th scope="col">Upload Photo</th> */}
                                </tr>
                              </thead>
                              <tbody>
                               { partnerTableData && partnerTableData?.length > 0 ? partnerTableData?.map((item, index)=>(
                                 <tr key={index}>
                                   <td>{index+1}</td>
                                   <td>{item["Owner/Partner 's name"] ?? "--"}</td>
                                   <td>{item?.Email ?? "--"}</td>
                                   <td>{item?.State ?? "--"}</td>
                                   <td>{item?.District ?? "--"}</td>
                                   <td>{item["City/Village"] ?? "--"}</td>
                                   <td>{item['House No'] ?? "--"}</td>
                                   <td>{item?.Landmark ?? "--"}</td>
                                   
                                 </tr>
                               )) : (
                                    <NotFoundTable colSpan={8} />
                               )
                                  
                               }
                              </tbody>
                            </table>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card h-100  custom_card ">
                        <div className="custom_card_header d-flex">
                          <img src={AccommodationIcon} alt="" srcSet="" />{" "}
                          <h6 className="fw-bolder">Accommodation Details</h6>
                        </div>
                        <div className="card-body">
                          <div className="row gx-2 row-gap-2">
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Year of Construction:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnit?.yearOfConstruction}</span>
                              </div>
                            </div>

                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Total Area( sq meters):</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnit?.totalArea}</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Total Rooms:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnit?.totalRoom} </span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Applied date: </strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{formatDateTime(mainPageApplicationData?.created_at) ?? "--"}</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Date of Starting Business (For old Units):</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnit?.dateOfstartingBusiness}</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Car Parking:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnitFacility?.carParking == 1 ? "Yes" : "No" }</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Restaurant:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnitFacility?.resturant == 1 ? "Yes" : "No"} </span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Bar: </strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnitFacility?.bar == 1 ? "Yes" : "No"}</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Swimming Pool:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnitFacility?.swimmingPool == 1 ? "Yes" : "No"}</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Health SPA:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnitFacility?.healthSpa == 1 ? "Yes" : "No"}</span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Conference Hall:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{accomadationUnitFacility?.cofferenceHall == 1 ? "Yes" : "No"} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card h-100  custom_card ">
                        <div className="custom_card_header d-flex">
                          <img src={AdditionalIcon} alt="" srcSet="" />{" "}
                          <h6 className="fw-bolder">Additional Details</h6>
                        </div>
                        <div className="card-body">
                          <div className="row gx-2 row-gap-2">
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>City:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{distanceFrom?.city} km </span>
                              </div>
                            </div>

                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Railway Station:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{distanceFrom?.railway} km </span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Bus Stand:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{distanceFrom?.bus} km </span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Airport: </strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>{distanceFrom?.airport} km </span>
                              </div>
                            </div>

                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Shopping Mall:</strong>
                              </div>

                              <div className="card_feild_main_itm">
                                <span>{distanceFrom?.shopping} km </span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Distance from Tourist Point:</strong>
                              </div>

                              <div className="card_feild_main_itm">
                                <span>{distanceFrom?.touristPoint} km </span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Hospital/ Dispensary:</strong>
                              </div>

                              <div className="card_feild_main_itm">
                                <span>{distanceFrom?.Hospital} km </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card h-100  custom_card ">
                        <div className="custom_card_header d-flex">
                          <img src={UploadIcon} alt="" srcSet="" />{" "}
                          <h6 className="fw-bolder mb-0">Upload Documents</h6>
                        </div>
                        <div className="card-body">
                          <div className="row gx-2 row-gap-2">
                            <div className="col-md-12 ">
                              <div className=" document_list">
                                <div className="col-md-12">
                                  {documentList?.map((doc, index) => (
                                  <div className="document_list_itm" key={index}>
                                    <div className="documentname_wrapper">
                                      <p className="mb-0 text-black">
                                          {doc?.name_en}
                                      </p>
                                    </div>
                                    <a
                                      className="badge upload-view-btn small"
                                      target="_blank"
                                      href=""
                                    >
                                      View
                                    </a>
                                  </div>
                                   ))}
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card h-100 custom_card">
                        <div className="custom_card_header d-flex">
                          <h6 className="fw-bolder mb-0">Update Status</h6>
                        </div>
                        <div className="card-body">
                          <div className="gx-2 row-gap-2">
                            <div className="col-md-12">
                              <div className="w-100">
                                <div className="col-md-12 mb-4">
                                  <Select
                                    styles={customStylesAcc}
                                    theme={selectTheme}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    className="my-select selectpicker"
                                    classNamePrefix="ad-menu_open_select_class"
                                    aria-label="Default select example"
                                    name="title"
                                    value={
                                      applicationStatusList?.find(
                                        (option) => option.value === statusId
                                      ) || null
                                    }
                                    options={applicationStatusList}
                                    onBlur={() => {
                                      if (!statusId) {
                                        setError((prev) => ({
                                          ...prev,
                                          status: "Please select the status",
                                        }));
                                      }
                                    }}
                                    onChange={(selectedOption) => {
                                      if (!selectedOption) {
                                        setError((prev) => ({
                                          ...prev,
                                          status: "Please select the status",
                                        }));
                                      } else {
                                        setStatusId(selectedOption.value);
                                        setError((prev) => ({ ...prev, status: "" }));
                                      }
                                    }}
                                    placeholder="Select Title"
                                  />
                                  {error.status && (
                                    <p className="validate-error">{error.status}</p>
                                  )}
                                </div>
                                <div className="col-md-12">
                                  <input
                                    className="w-100"
                                    maxLength={100}
                                    name="remark"
                                    placeholder="Enter Remark"
                                    type="text"
                                    onBlur={(e) => {
                                      if (!e.target?.value?.trim()) {
                                        setError((prev) => ({
                                          ...prev,
                                          remark: "Please enter the remark",
                                        }));
                                      }
                                    }}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setRemark(value);
                                      setError((prev) => ({
                                        ...prev,
                                        remark: value?.trim()
                                          ? ""
                                          : "Please enter the remark",
                                      }));
                                    }}
                                    value={remark}
                                  />
                                  {error.remark && (
                                    <p className="validate-error">{error.remark}</p>
                                  )}
                                </div>
                                <div className="row ad-cat-row">
                                  <div className="update-btns-block mt-3">
                                    <button
                                      onClick={(e) => submitStatus(e)}
                                     
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <div className="col-md-12">
                    <div className="card custom_card">
                      <div className="custom_card_header d-flex">
                        <img src={PartnerIcon} alt="" srcSet="" />{" "}
                        <h6 className="fw-bolder">
                          Room Details
                          {/* <span className="detailcount">2</span> */}
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table  bg-table mb-0 custom_table mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Sr. No </th>
                                <th scope="col"> Total Area </th>
                                <th scope="col"> Total No. of Beds</th>
                                <th scope="col">Type of Room </th>
                                <th scope="col">Bed Type</th>
                                <th scope="col">Floor Type</th>
                                {/* <th scope="col">Upload Photo</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {roomTableData && roomTableData?.length > 0 ? roomTableData?.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item["Total Area"] ?? "--"}</td>
                                  <td>{item["Total No. of Beds"] ?? "--"}</td>
                                  <td>{item["Type of Room"] ?? "--"}</td>
                                  <td>{item["Bed Type"] ?? "--"}</td>
                                  <td>{item["Floor Type"] ?? "--"}</td>
                                </tr>
                              )) : (
                                <NotFoundTable colSpan={6} />
                              )

                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-applicationStatus"
                  role="tabpanel"
                  aria-labelledby="nav-applicationStatus-tab"
                >
                  
                    <div className="col-md-12">
                      <div className="card custom_card">
                        <div className="custom_card_header d-flex">
                          <img src={PartnerIcon} alt="" srcSet="" />{" "}
                          <h6 className="fw-bolder">
                           Application log
                            {/* <span className="detailcount">2</span> */}
                          </h6>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table  bg-table mb-0 custom_table mb-0">
                              <thead>
                                <tr>
                                  <th scope="col">Sr. No </th>
                                  <th scope="col"> Date and Time </th>
                                  <th scope="col">Remarks</th>
                                 
                                  {/* <th scope="col">Upload Photo</th> */}
                                </tr>
                              </thead>
                              <tbody>
                              {applicationLog && applicationLog?.length > 0 ? applicationLog?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item["Total Area"] ?? "--"}</td>
                                    <td>{item["Total No. of Beds"] ?? "--"}</td>
                                   
                                  </tr>
                                )) : (
                                  <NotFoundTable colSpan={3} />
                                )

                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-certificateList"
                  role="tabpanel"
                  aria-labelledby="nav-certificateList-tab"
                >

                  <div className="col-md-12">
                    <div className="card custom_card">
                      <div className="custom_card_header d-flex">
                        <img src={PartnerIcon} alt="" srcSet="" />{" "}
                        <h6 className="fw-bolder">
                         Certificates
                          {/* <span className="detailcount">2</span> */}
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table  bg-table mb-0 custom_table mb-0">
                            <thead>
                              <tr>
                               
                                <th scope="col"> Certificate Number  </th>
                                <th scope="col">Download</th>
                                <th scope="col">Generate Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {certificateList ? 
                                <tr>
                                  <td>{certificateList?.certificate_number ?? "--"}</td>
                                 <td>
                                    {certificateList.path ? (
                                      <a href={certificateList.path} target="_blank" >
                                        Download
                                      </a>
                                    ) : (
                                      "--"
                                    )}
                                 </td>
                                  <td>{certificateList?.created_at ? formatDateTime(certificateList?.created_at) : "--"}</td>
                                </tr>
                              : (
                                <NotFoundTable colSpan={3} />
                              )
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default ApplicationView;
