import React, { useEffect, useState } from 'react';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
import { subSectionData } from '../../services/api/user';

const SubSectionTable = ({ showEditIcon, setTabsWholeData, subSectionDetails, fieldValue, tabsWholeData, fieldKey }) => {
    const { language } = useLanguage();
    const [subSectionFields, setSubSectionFields] = useState([]);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const fetchFields = async () => {
           try{
               const formData = new FormData();
               formData.append("sub_section_id", subSectionDetails?.sub_section_id);

               const response = await subSectionData(formData);
               const jsonValue = response?.data?.json_value;

                if (jsonValue) {
                    const parsedJsonValue = JSON.parse(jsonValue);
                    setSubSectionFields(parsedJsonValue?.items || []);
                } else {
                    setSubSectionFields([]); // Set to an empty array or handle accordingly
                }
               const tableDataKey = `${fieldKey}_json`;
               const tableDataValue = Array.isArray(tabsWholeData[tableDataKey]) ? tabsWholeData[tableDataKey] : [];
               console.log(tableDataValue)
               setTableData(tableDataValue);
           }catch(err){
            console.log(err)
           }
        };

        fetchFields();
    }, [subSectionDetails]);

    useEffect(() => {
        if (!subSectionFields || subSectionFields.length === 0) return;

        const newRowCount = Math.min(Math.max(parseInt(fieldValue, 10) || 1, 1), 9);
        const tableDataKey = `${fieldKey}_json`;

        if (newRowCount !== tableData.length) {
            const updatedTableData = [...tableData];

            if (newRowCount > tableData.length) {
                // Add rows
                for (let i = tableData.length; i < newRowCount; i++) {
                    // Create an empty row with fields initialized to empty strings
                    const newRow = subSectionFields.reduce(
                        (acc, field) => ({ ...acc, [field.name_en]: "" }),
                        {}
                    );
                    updatedTableData.push(newRow);
                }
            } else if (newRowCount < tableData.length) {
                // Remove rows
                updatedTableData.splice(newRowCount);
            }

            console.log("Adjusted Table Data:", updatedTableData);
            setTableData(updatedTableData);
            setTabsWholeData({
                ...tabsWholeData,
                [tableDataKey]: updatedTableData,
                [fieldKey]: newRowCount.toString(),
            });
        }
    }, [fieldValue, fieldKey, subSectionFields, tableData, tabsWholeData, setTabsWholeData]);



    // Function to handle changes in the input fields
    const handleInputChange = (e, rowIndex, fieldName) => {
        const { value } = e.target;

        // Clone tableData to avoid direct state mutation
        const updatedTableData = [...tableData];

        // Update specific field value in the row
        updatedTableData[rowIndex] = {
            ...updatedTableData[rowIndex],
            [fieldName]: value,
        };

        console.log("Updated Table Data:", updatedTableData);
        setTableData(updatedTableData);

        // Update `tabsWholeData` for persistence
        const tableDataKey = `${fieldKey}_json`;
        const updatedTabsWholeData = { ...tabsWholeData, [tableDataKey]: updatedTableData };
        setTabsWholeData(updatedTabsWholeData);
    };


    // Function to add a new row
    const addRow = () => {
        if (tableData.length < 9) {
            const newRow = subSectionFields.reduce((acc, field) => ({ ...acc, [field.name_en]: "" }), {});
            const updatedTableData = [...tableData, newRow];

            setTableData(updatedTableData);
            const tableDataKey = `${fieldKey}_json`;
            setTabsWholeData({ ...tabsWholeData, [tableDataKey]: updatedTableData, [fieldKey]: updatedTableData.length.toString() });
        }
    };

    const removeRow = (rowIndex) => {
        if (tableData.length > 1) {
            const updatedTableData = tableData.filter((_, index) => index !== rowIndex);
            setTableData(updatedTableData);

            const tableDataKey = `${fieldKey}_json`;
            setTabsWholeData({ ...tabsWholeData, [tableDataKey]: updatedTableData, [fieldKey]: updatedTableData.length.toString() });
        }
    };


    return (
        <div className="table-responsive">
            <table className="table bg-table mb-0">
                <thead>
                    <tr>
                        {subSectionFields?.map((item, index) => (
                            <th key={index} scope="col">{language ? item?.name_hi : item?.name_en}</th>
                        ))}
                        {!showEditIcon && <th scope="col">Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {tableData.length > 0 ? (
                        tableData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {subSectionFields.map((field, colIndex) => (
                                    <td key={colIndex}>
                                        {showEditIcon ? (
                                            <span>{row[field?.name_en] || "--"}</span>
                                        ) : (
                                            <input
                                                type="text"
                                                name={field?.name_en}
                                                className="form-control"
                                                value={row[field?.name_en] || ""}
                                                onChange={(e) => handleInputChange(e, rowIndex, field?.name_en)}
                                                placeholder={language ? field?.placeholder_hi : field?.placeholder_en}
                                            />
                                        )}
                                    </td>
                                ))}
                                {!showEditIcon && (
                                    <td>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => removeRow(rowIndex)}
                                            disabled={tableData.length <= 1} // Prevent removing the last row
                                        >
                                            Remove
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={subSectionFields?.length}>No data available</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {!showEditIcon && (
                <button
                    type="button"
                    className="btn btn-primary mt-2"
                    onClick={addRow}
                    disabled={tableData.length >= 9} // Limit rows to 9
                >
                    Add Row
                </button>
            )}
        </div>
    );
};

export default SubSectionTable;
