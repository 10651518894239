import React, { useEffect, useState } from "react";
import { empDesignationData } from "../../services/api/user";
import { GetLocalStorage } from "../../Utils/Utils";

const EmpDetails = ({
  empDesignationTableData,
  subCatId,
  language,
  tabsWholeData,
  empDesignationField,
  setTabsWholeData,
  applicationFromData
}) => {
  const [empList, setEmpList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [filteredTabs, setFilteredTabs] = useState([]);
  const [tabData, setTabData] = useState({});
  const [formData, setFormData] = useState({}); // Stores data for each employee section

  const applicationId = GetLocalStorage("application_id");

  // Fetch dropdown options and application form data on mount
  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    // Automatically select the first tab and populate the form if data is available
    if (filteredTabs.length > 0) {
      setSelectedTab(filteredTabs[0]);
      populateFormData(filteredTabs[0]);
    }
  }, [filteredTabs]);

  const fetchOptions = async () => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", subCatId);
      const response = await empDesignationData(formData);
      setEmpList(response?.data || []);

      // Check for data structure and set tabs and form data accordingly
      if (
        tabsWholeData &&
        tabsWholeData.section_fields &&
        empDesignationField &&
        empDesignationField.field_key &&
        tabsWholeData.section_fields[empDesignationField.field_key]
      ) {
        const jsonDataFromAppData = applicationFromData && 
                                    applicationFromData?.["f_" + empDesignationField?.field_key] && Array.isArray(applicationFromData?.["f_" + empDesignationField?.field_key])
                                    ? JSON.parse( applicationFromData?.["f_" + empDesignationField?.field_key])
                                     : tabsWholeData.section_fields[empDesignationField.field_key];
        setTabData(jsonDataFromAppData[0] || {});

        // Filter tabs to include only those with "No. of People" greater than 0
        const tabs = Object.keys(jsonDataFromAppData[0] || {}).filter(
          (key) => parseInt(jsonDataFromAppData[0][key]["No. of People"]) > 0
        );
        setFilteredTabs(tabs);
      }
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const populateFormData = (tab) => {
    // Avoid resetting if formData for the selected tab already exists
    if (formData[tab] && formData[tab].length > 0) return;
  
    const peopleCount = parseInt(tabData[tab]?.["No. of People"]) || 0;
  
    // Check for pre-existing data in tabsWholeData, using empDesignationField's key
    const existingData = 
      tabsWholeData.section_fields?.[empDesignationField.field_key]?.[0]?.[tab]?.["Employee Details"] || [];
  
    // Initialize form data based on existingData or default to empty fields
    const initialData = Array.from({ length: peopleCount }, (_, index) => ({
      "Name of GM/Manager": existingData[index]?.["Name of GM/Manager"] || "",
      "Mobile Number of GM/Manager": existingData[index]?.["Mobile Number of GM/Manager"] || "",
      "Email of GM/Manager": existingData[index]?.["Email of GM/Manager"] || "",
      "State of GM/Manager": existingData[index]?.["State of GM/Manager"] || "",
      "District of GM/Manager": existingData[index]?.["District of GM/Manager"] || "",
    }));
  
    setFormData((prevData) => ({
      ...prevData,
      [tab]: initialData,
    }));
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    populateFormData(tab);
  };

  const handleInputChange = (tab, index, event, employeeField) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      const updatedTabData = [...(prevData[tab] || [])];
      updatedTabData[index] = {
        ...updatedTabData[index],
        [name]: value,
      };

      return {
        ...prevData,
        [tab]: updatedTabData,
      };
    });

    setTabsWholeData((prevData) => {
      const currentJsonArray =
        prevData?.section_fields?.[`${empDesignationField?.field_key}`] || [];
    
      // Clone the array to avoid modifying the original reference
      const updatedJsonArray = [...currentJsonArray];
    
      // Check that the first element exists, and initialize if it doesn't
      if (!updatedJsonArray[0]) {
        updatedJsonArray[0] = {};
      }
    
      // Ensure that the tab exists within the first item
      if (!updatedJsonArray[0][tab]) {
        updatedJsonArray[0][tab] = { "Employee Details": [] };
      }
    
      // Ensure "Employee Details" is initialized as an array
      if (!Array.isArray(updatedJsonArray[0][tab]["Employee Details"])) {
        updatedJsonArray[0][tab]["Employee Details"] = [];
      }
    
      // Ensure that the index within "Employee Details" exists
      if (!updatedJsonArray[0][tab]["Employee Details"][index]) {
        updatedJsonArray[0][tab]["Employee Details"][index] = {};
      }
    
      // Now, safely update the value for the specified employee field
      updatedJsonArray[0][tab]["Employee Details"][index][employeeField] = value;
    
      return {
        ...prevData,
        section_fields: {
          ...prevData?.section_fields,
          [empDesignationField?.field_key]: updatedJsonArray,
        },
      };
    });

  };

  return (
    <div className="employe-details-tabs">
      <div className="emp-main-wrapper d-flex align-items-start justify-content-between">
        {/* Left Tabs */}
        <div className="nav emp-tab-wrapper flex-column emp-left-tabs nav-pills me-5">
          {filteredTabs.map((tab) => (
            <div key={tab}>
              <button
                type="button"
                className={`nav-link ${selectedTab === tab ? "active" : ""}`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </button>
            </div>
          ))}
        </div>

        {/* Right Panel - Display Separate Employee Sections */}
        {selectedTab && (
          <form className="emp-right-tab w-100">
             {(formData[selectedTab] || []).map((data, index) => (
            <div className="emp-right-tab-wrapper">
              <div className="emp-right-header">
                <h6 className="mb-0">
                  {language ? "कर्मचारी विवरण" : "Employee Details"}
                </h6>
              </div>
              <div className="tab-pane fade show active">
                <div className="row p-3 col-md-12">
                  {/* Render employee sections based on "No. of People" */}
                    <React.Fragment key={index}>
                      <div className="col-md-4 mb-3">
                        <div className="f_fields">
                          <label className="form-label">
                            <span className="field-label">
                              {language
                                ? "जीएम/प्रबंधक का नाम"
                                : "Name of GM/Manager"}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              language
                                ? "जीएम/प्रबंधक का नाम"
                                : "Enter GM/Manager Name"
                            }
                            value={formData[selectedTab]?.[index]?.["Name of GM/Manager"] || ""}
                            onChange={(e) =>
                              handleInputChange(selectedTab, index, e, "Name of GM/Manager")
                            }
                            name="Name of GM/Manager"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="f_fields">
                          <label className="form-label">
                            <span className="field-label">
                              {language
                                ? "जीएम/प्रबंधक का मोबाइल नंबर"
                                : "Mobile Number of GM/Manager"}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              language ? "मोबाइल नंबर दर्ज करें" : "Enter Mobile Number"
                            }
                            value={formData[selectedTab]?.[index]?.["Mobile Number of GM/Manager"] || ""}
                            onChange={(e) =>
                              handleInputChange(selectedTab, index, e, "Mobile Number of GM/Manager")
                            }
                            name="Mobile Number of GM/Manager"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="f_fields">
                          <label className="form-label">
                            <span className="field-label">
                              {language
                                ? "जीएम/प्रबंधक का ईमेल"
                                : "Email of GM/Manager"}
                            </span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={
                              language ? "ईमेल दर्ज करें" : "Enter Email"
                            }
                            value={formData[selectedTab]?.[index]?.["Email of GM/Manager"] || ""}
                            onChange={(e) =>
                              handleInputChange(selectedTab, index, e, "Email of GM/Manager")
                            }
                            name="Email of GM/Manager"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="f_fields">
                          <label className="form-label">
                            <span className="field-label">
                              {language
                                ? "जीएम/प्रबंधक का राज्य"
                                : "State of GM/Manager"}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              language ? "राज्य दर्ज करें" : "Enter State"
                            }
                            value={formData[selectedTab]?.[index]?.["State of GM/Manager"] || ""}
                            onChange={(e) =>
                              handleInputChange(selectedTab, index, e, "State of GM/Manager")
                            }
                            name="State of GM/Manager"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="f_fields">
                          <label className="form-label">
                            <span className="field-label">
                              {language
                                ? "जीएम/प्रबंधक का जिला"
                                : "District of GM/Manager"}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              language ? "जिला दर्ज करें" : "Enter District"
                            }
                            value={formData[selectedTab]?.[index]?.["District of GM/Manager"] || ""}
                            onChange={(e) =>
                              handleInputChange(selectedTab, index, e, "District of GM/Manager")
                            }
                            name="District of GM/Manager"
                            required
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  
                </div>
              </div>
            </div>
            ))}
          </form>
        )}
      </div>
    </div>
  );
};

export default EmpDetails;
