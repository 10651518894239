import React, { useEffect, useRef, useState } from 'react'
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import "./LoginForm.css";
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineUser } from "react-icons/ai";
import { BiLock } from "react-icons/bi";
import leftImg from "../../../assets/images/bg-down.png"
import rightImg from "../../../assets/images/bg-up.png"
import { GetLocalStorageParsed, ImageurlIcons, SetLocalStorage, SetLocalStorageStringify, validateonChangeHandle, validateonKeyUpHandle } from '../../../Utils/Utils';
import { useLanguage } from '../../LanguageProvider/LanguageProvider ';
import { login, getMobileExistence, clearCache } from '../../../services/api/auth';
const LoginForm = ({ loginFormData, buttData, formData, setLoginFormData }) => {
    const { language, setLanguage } = useLanguage();
    const [errorMessage, setErrorMessage] = useState();
    const [loginFailedAttempt, setLoginFailedAttempt] = useState();
    const [emptyClickMessage, setEmptyClickMessage] = useState();
    const getWithoutLoginSubCatClick = GetLocalStorageParsed("subCategoryClick")

    //states
    const navigate = useNavigate()
    const [loginDetail, setLoginDetail] = useState({
        mobile_number: "",
        password: ""
    })
    const [loginError, setLoginError] = useState({
        mobile_number: "",
        password: ""
    })
    const [passwordVisible, setPasswordVisile] = useState(false);
    const handleClearCache = () => {

        clearCache().then(res => {

        }).catch((error) => {
        });

        // const option = {
        //     method: "GET",
        //     headers: {
        //         "access-control-allow-origin": "*",
        //         "content-type": "application/json",
        //     },
        //     url: `${Apiurl()}/clear-cache`,
        // };
        // axios(option)
        //     .then((e) => {

        //     })
        //     .catch((error) => {
        //     });
    }
    //login function

    const handleSubmitLogin = async () => {
        handleClearCache();
        const data = await loginFormData?.find(item => item?.name_en.toLowerCase().includes("mobile"));
        if (data) {
            const exists = await fetchMobileExistence(data?.value);
            if (exists.status == 400) {
                setLoginFormData(prevData =>
                    prevData.map(i =>
                        i.id === data.id ? { ...i, error: { hi: "मोबाइल नंबर/उपयोगकर्ता नाम हमारे रिकॉर्ड में नहीं मिला।", en: "Mobile Number/UserName not found in our records." } } : i
                    )
                );
            } else {
                setLoginFormData(prevData =>
                    prevData.map(i =>
                        i.id === data.id ? { ...i, error: "" } : i
                    )
                );
                try {
                    const formData = new FormData();
                    loginFormData?.forEach(i => {
                        if (i?.value) {
                            formData.append([i.input_field_name], i?.value);
                        }
                    });
                    // formData.append("password", loginDetail.password);
                    //  const response = login(formData);  //axios.post(`${Apiurl()}/login`, formData);
                    login(formData).then(response => {
                        if (response?.status === 200 && response?.data?.failed_login_attempts || response?.status === 400) {
                            setErrorMessage(response?.messages)
                            setLoginFailedAttempt(response?.data)
                            return;
                        } else {
                            SetLocalStorage("userInfo", response?.data?.token)
                            SetLocalStorageStringify("userDetail", response?.data?.user)
                            SetLocalStorage("user_type", response?.data?.user?.roles);
                            SetLocalStorageStringify("tokenExpiry", response?.data?.expire_at)
                            if (response?.data?.user?.roles === 'superadmin' || response?.data?.user?.user_type === '1') {
                                navigate("/admin")
                            } else {
                                    navigate("/dashboard")
                            }
                        }
                    }).catch((error) => {
                    });

                } catch (error) {
                }
            }
        }
    }
    const fetchMobileExistence = async (i) => {
        try {
            const formData = new FormData();
            formData.append("mobile_number", i);

            return getMobileExistence(formData).then(res => {
                if (res) {
                    return res
                }
            }).catch(err => {

            });

            // const reponse = await axios.post(`${Apiurl()}/is-email-mobileno-exists`, formData);
            // return reponse?.data
        } catch (error) {
        }
    }
    useEffect(() => {
        if (errorMessage || language) {
            setEmptyClickMessage({ hi: errorMessage?.hindi, en: errorMessage?.english })
        }
    }, [errorMessage, language])
    const allFieldsValid = () => {
        return loginFormData?.every(item => item.value && item.error === "");
    };
    const hadleShowMessgae = () => {
        if (loginFormData?.every(item => item.value)) {

            setEmptyClickMessage({ hi: "कृपया सभी फ़ील्ड सत्यापित करें", en: "Please validate all fields" })
        } else {
            setEmptyClickMessage({ hi: "कृपया सभी विवरण भरें", en: "Please fill all details" })

        }

    }
    return (
        <section className=' main-outer-container'>
            <img src={leftImg} alt="background-image" className='main-bg-imgleft' />
            <img src={rightImg} alt="background-image" className='main-bg-imgright' />
            <div className='container'>
                <div className='row lf-row d-flex justify-content-center align-items-center'>
                    <div className="col-md-8 lf-col p-0">
                        <div className="lf-outer-con">
                            {formData?.map((i, index) => (<h2 key={index + 1} className='lf-heading'>{language ? i?.name_hi : i?.name_en}</h2>))}
                            <div className="lf-inner">
                                <div className="lf-outer">
                                    {/* mobile_number */}
                                    {loginFormData?.map((item, index) => {
                                        return (
                                            <div className='lf-input-box mb-4' key={index + 1} onClick={() => { setEmptyClickMessage(""); setLoginFailedAttempt(); }}>
                                                <div className="used-icon-password-visible" key={item.id}>
                                                    <img className='lg-input-icon' src={`${ImageurlIcons()}/${item?.icon}`} alt="" />
                                                    <input
                                                        className='w-100'
                                                        placeholder={language ? item?.placeholder_hi : item?.placeholder_en}
                                                        type={item?.type == "password" ? `${passwordVisible ? 'text' : "password"}` : item?.type}
                                                        name={item?.input_field_name}
                                                        value={item?.value}
                                                        onChange={(e) => {
                                                            const updatedFields = loginFormData.map(field => {
                                                                if (field.id === item?.id) {
                                                                    return {
                                                                        ...field,
                                                                        value: e?.target?.value
                                                                    };
                                                                }
                                                                return field;
                                                            });
                                                            setLoginFormData(updatedFields);
                                                            validateonChangeHandle(item, e, loginFormData, setLoginFormData); setLoginFailedAttempt("")
                                                        }}
                                                        onInput={(e) => {
                                                            const inputValue = e.target.value;
                                                            if (/^\d+$/.test(inputValue) && inputValue.length > 10) {
                                                                e.target.value = inputValue.slice(0, 10);
                                                            }
                                                        }}
                                                        onKeyUp={(e) => { validateonKeyUpHandle(item, e.target.value, loginFormData, setLoginFormData) }}
                                                    />
                                                    {
                                                        item?.type == "password" ? <>
                                                            {
                                                                passwordVisible ?
                                                                    <BsEye className="icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} /> :
                                                                    <BsEyeSlash className="icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} />
                                                            }
                                                        </> : null
                                                    }
                                                    {item?.error?.en && <p className='validate-error'>{language ? item?.error?.hi : item?.error?.en}</p>}
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <p className='validate-error text-center mb-2'>
                                        {language ? emptyClickMessage?.hi : emptyClickMessage?.en}
                                    </p>
                                    {loginFailedAttempt?.block_time && <p className='validate-error'>{language ? `आप ${loginFailedAttempt?.block_time} के बाद दोबारा लॉगइन कर सकते हैं` : `You can again login after ${loginFailedAttempt?.block_time}`}</p>}                                    {/* Remember me */}
                                    {buttData?.map((butt, index) => {
                                        const text = language ? butt?.name_hi : butt?.name_en;
                                        // Regex for matching "Register" or "Signup" for both Hindi and English
                                        const regex = language
                                            ? /पंजीकरण|साइन\s*अप/i
                                            : /register|sign\s*up|signup/i;

                                        const match = text.match(regex);

                                        const beforeText = match ? text.substring(0, match.index) : text;
                                        const matchedText = match ? match[0] : '';
                                        const afterText = match ? text.substring(match.index + match[0].length) : '';

                                        return (
                                            <>
                                                {butt?.id === 4 ?
                                                    <>
                                                        <div key={index + 1} className='lf-remember-box'>
                                                            <Link to="/forgot-password">{language ? butt?.name_hi : butt?.name_en}</Link>
                                                        </div>
                                                    </>
                                                    :
                                                    butt?.id === 3 ?
                                                        <>
                                                            {
                                                                !allFieldsValid() ? <div key={index + 1} className="lf-btn">
                                                                    <button onClick={hadleShowMessgae}>{language ? butt?.name_hi : butt?.name_en}</button>
                                                                </div> : <div key={index + 1} className="lf-btn">
                                                                    <button onClick={handleSubmitLogin}>{language ? butt?.name_hi : butt?.name_en}</button>
                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        butt?.id === 5 ?
                                                            <div key={index + 1} className='lf-last'>
                                                                {match ? (
                                                                    <>
                                                                        <p>{beforeText}
                                                                            <Link to="/signup">{matchedText}</Link>
                                                                            {afterText}</p>
                                                                    </>
                                                                ) : (
                                                                    <p><Link to="/signup">{language ? butt?.name_hi : butt?.name_en}</Link></p>
                                                                )}
                                                            </div>
                                                            : null
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
export default LoginForm 
